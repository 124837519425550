import React, { useState, useEffect } from "react"

export default function Roster({ placement, label, TID })
{
  const [roster, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rosterLevel, setRosterLevel] = useState(0);


  const ChangeRosterLevel = (val) => {
    setRosterLevel(val)
  }

  useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {
      try {
        const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_ROSTER&TID="+TID
        console.log (url)
        const response = await fetch(url);
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        {
          setData(result);
        }
      } 
      catch (error) {
        setError(error.message);
      } 
      finally {
        setLoading(false);
      }
    };

    if (TID > 0) fetchData();

}, [TID]);  

  if (loading) 
    return (<h2>Loading...</h2>);
  else if (error) 
    return (<h2>Error: {error}</h2>);
  else 
  {
      console.log ("RosterView")

      const offense = ["QB","RB","FB","WR","TE","OL"];
      const defense = ["DE","DT","DL","LB","DB","CB","S"];
      const special = ["ATH","K","P","LS","KR","PR","PK"];
  
      let RosterOff = FilterRoster (roster, offense);
      let RosterDef = FilterRoster (roster, defense);
      let RosterST  = FilterRoster (roster, special);
    
    const offplayers = RosterOff.map((data,id)=>{
        return <RosterSpot key={id} player={data} />
      })
    const defplayers = RosterDef.map((data,id)=>{
        return <RosterSpot key={id} player={data} />
      })
    const stplayers = RosterST.map((data,id)=>{
        return <RosterSpot key={id} player={data} />
      })

    return (
        <>
            <h2>{label}</h2>
            <div className="box btn-container">
                <button className={"whiteButtonInv"} ></button>
                <button className={`${rosterLevel===0 ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  ChangeRosterLevel(0)} >Offense</button>
                <button className={`${rosterLevel===1 ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  ChangeRosterLevel(1)} >Defense</button>
                <button className={`${rosterLevel===2 ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  ChangeRosterLevel(2)} >Special Teams</button>
                <button className={"whiteButtonInv"} ></button>
            </div>
        <div className={placement}>
            <br></br>
            <table className="table table-dark table-striped tight-table three-quarter-width scroll" >
            <colgroup>
                <col style={{ width: '20%', textAlign: 'center'}} />
                <col style={{ width: '50%', textAlign: 'left'}} />
                <col style={{ width: '30%', textAlign: 'left'}} />
                </colgroup>
                <tbody>
                    <tr><th style={{textAlign: 'center', color: 'khaki'}}>#</th><th style={{textAlign: 'left', color: 'khaki'}}>PLAYER</th><th style={{textAlign: 'left', color: 'khaki'}}>POS</th></tr> 
                    {rosterLevel==0?offplayers:<></>}
                    {rosterLevel==1?defplayers:<></>}
                    {rosterLevel==2?stplayers:<></>}
            </tbody>
            </table>
        </div>
        </>
        )
    }
}

function RosterSpot({ player })
{
    const labels=["--","FR","SO","JR","SR","--"]
    return (
        <tr>
        <td>{player.Jersey}</td> 
        <td style={{textAlign: 'left', color: 'white'}}>{player.FullName}</td> 
        <td style={{textAlign: 'left', color: 'white'}}>{player.Position}</td>
        <td></td>
        </tr>
        )
}

export function FilterRoster(data, positions)
{
          var list=[]
          for (let player of data)
              if (positions.includes(player.Position))
                  list.push(player)
          return list;
}      

