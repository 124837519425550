import * as Images from '../images';
import { MenuChoices } from '../Menu';

export default function GameInfo({GID, wk, day, awayPath, awayRank, awayTeam, homePath, homeRank, homeTeam, gameInfo, network, getPreview, getLive, getPlays, getGameStats })
{
// console.log ("Day="+day+" GameDay="+gameInfo.Day)
const rightDay = (day === gameInfo.Day || day === -1)
let time = FormatTime(gameInfo.Time)

// console.log (network)
  
// console.log ("GI: d="+day + " a:" + awayPath + " at: " + awayTeam + " h: " + homePath + " ht: " + homeTeam);
return (
  <>
  {rightDay?
      <table className="blacktbl">
      <colgroup>
      <col style={{ width: '20%', textAlign: 'left'}} />
      <col style={{ width: '3%',  textAlign: 'right'}} />
      <col style={{ width: '30%', textAlign: 'left' }} />
      <col style={{ width: '10%', textAlign: 'right' }} />
      <col style={{ width: '20%', textAlign: 'right' }} />
      <col style={{ width: '20%', textAlign: 'right' }} />
      </colgroup>
      <tbody>
      <tr>
      <td><img className='icon-xs' src={awayPath} alt={""}></img></td>
      {awayRank <= 25?<td className='gray px10'>{awayRank}</td>:<td></td>}
      <td>{awayTeam}</td>
      <td>{(gameInfo.Active || gameInfo.Complete)?gameInfo.AwayScore:"0"}</td> 
      <td style={{color: 'khaki'}}>{(gameInfo.Active || gameInfo.Complete)?gameInfo.Status:time}</td> 
      <td style={{textAlign: 'center'}}>{(gameInfo.Complete)?
          <button className='whiteButtonInv' onClick={() => getPlays(MenuChoices.PLAYS, GID)}>PLAYS</button>:<img className='icon-xs'  src={network} alt=""></img>}</td> 
      </tr>
      <tr>
      <td><img className='icon-xs' src={homePath} alt={""}></img></td> 
      {homeRank <= 25?<td className='gray px10'>{homeRank}</td>:<td></td>}
      <td>{homeTeam}</td>
      <td>{(gameInfo.Active || gameInfo.Complete)?gameInfo.HomeScore:"0"}</td> 
      <td>{(gameInfo.Active ? <button className='redButtonInv' onClick={() => getLive(MenuChoices.LIVE, GID)}>LIVE</button>:(gameInfo.Complete?'':gameInfo.Spread))}</td> 
      <td>{(gameInfo.Active || gameInfo.Complete)?<button className='ltblueButtonInv' onClick={() => getGameStats(MenuChoices.GAME_STATS, GID, gameInfo)}>STATS</button>:<button className="ltblueButtonInv" onClick={() =>  getPreview(MenuChoices.PREVIEW, GID)} >PREVIEW</button>}</td> 
      </tr>
      </tbody>
      </table> 
          :<></>}
  </>
  )
}

function FormatTime(t)
{
if      (t === '11:00') return '11:00 am';
else if (t === '11:30') return '11:30 am';
else if (t === '12:00') return '12:00 pm';
else if (t === '12:30') return '12:30 pm';
else if (t === '13:00') return '1:00 pm';
else if (t === '13:30') return '1:30 pm';
else if (t === '14:00') return '2:00 pm';
else if (t === '14:30') return '2:30 pm';
else if (t === '15:00') return '3:00 pm';
else if (t === '15:30') return '3:30 pm';
else if (t === '16:00') return '4:00 pm';
else if (t === '16:30') return '4:30 pm';
else if (t === '17:00') return '5:00 pm';
else if (t === '17:30') return '5:30 pm';
else if (t === '18:00') return '6:00 pm';
else if (t === '18:30') return '6:30 pm';
else if (t === '19:00') return '7:00 pm';
else if (t === '19:30') return '7:30 pm';
else if (t === '20:00') return '8:00 pm';
else if (t === '20:30') return '8:30 pm';
else if (t === '21:00') return '9:00 pm';
else if (t === '21:30') return '9:30 pm';
else if (t === '22:00') return '10:00 pm';
else if (t === '22:30') return '10:30 pm';
else if (t === '23:00') return '11:00 pm';
else if (t === '23:30') return '11:30 pm';
else return (t)
}

// export default function GameInfo({day, awayPath, awayRank, awayTeam, homePath, homeRank, homeTeam, gameInfo, network })
// {
//     function FormatTime(t)
//     {
//         if      (t === '11:00') return '11:00 am';
//         else if (t === '11:30') return '11:30 am';
//         else if (t === '12:00') return '12:00 pm';
//         else if (t === '12:30') return '12:30 pm';
//         else if (t === '13:00') return '1:00 pm';
//         else if (t === '13:30') return '1:30 pm';
//         else if (t === '14:00') return '2:00 pm';
//         else if (t === '14:30') return '2:30 pm';
//         else if (t === '15:00') return '3:00 pm';
//         else if (t === '15:30') return '3:30 pm';
//         else if (t === '16:00') return '4:00 pm';
//         else if (t === '16:30') return '4:30 pm';
//         else if (t === '17:00') return '5:00 pm';
//         else if (t === '17:30') return '5:30 pm';
//         else if (t === '18:00') return '6:00 pm';
//         else if (t === '18:30') return '6:30 pm';
//         else if (t === '19:00') return '7:00 pm';
//         else if (t === '19:30') return '7:30 pm';
//         else if (t === '20:00') return '8:00 pm';
//         else if (t === '20:30') return '8:30 pm';
//         else if (t === '21:00') return '9:00 pm';
//         else if (t === '21:30') return '9:30 pm';
//         else if (t === '22:00') return '10:00 pm';
//         else if (t === '22:30') return '10:30 pm';
//         else if (t === '23:00') return '11:00 pm';
//         else if (t === '23:30') return '11:30 pm';
//         else return (t)
//     }
//     // console.log ("Day="+day+" GameDay="+gameInfo.Day)
//     const rightDay = (day === gameInfo.Day || day === -1)
//     let time = FormatTime(gameInfo.Time)

//     // console.log ("GI: d="+day + " a:" + awayPath + " at: " + awayTeam + " h: " + homePath + " ht: " + homeTeam);
//     return (
//         <>
//         {rightDay?
//             <table className="blacktbl">
//             <colgroup>
//             <col style={{ width: '20%', textAlign: 'left'}} />
//             <col style={{ width: '3%',  textAlign: 'right'}} />
//             <col style={{ width: '30%', textAlign: 'left' }} />
//             <col style={{ width: '10%', textAlign: 'right' }} />
//             <col style={{ width: '20%', textAlign: 'right' }} />
//             <col style={{ width: '20%', textAlign: 'right' }} />
//             </colgroup>
//             <tbody>
//             <tr>
//             <td><img className='icon-xs' src={awayPath} alt={""}></img></td>
//             {awayRank <= 25?<td className='gray px10'>{awayRank}</td>:<td></td>}
//             <td>{awayTeam}</td>
//             <td>{(gameInfo.Active || gameInfo.Complete)?gameInfo.AwayScore:"0"}</td> 
//             <td style={{color: 'khaki'}}>{(gameInfo.Active || gameInfo.Complete)?gameInfo.Status:time}</td> 
//             <td><img className='icon-xs' align='center' src={network} alt=""></img></td> 
//             </tr>
//             <tr>
//             <td><img className='icon-xs' src={homePath} alt={""}></img></td> 
//             {homeRank <= 25?<td className='gray px10'>{homeRank}</td>:<td></td>}
//             <td>{homeTeam}</td>
//             <td>{(gameInfo.Active || gameInfo.Complete)?gameInfo.HomeScore:"0"}</td> 
//             <td>{(gameInfo.Active || gameInfo.Complete)?" ":gameInfo.Spread}</td> 
//             </tr>
//             </tbody>
//             </table> 
//                 :<></>}
//         </>
//         )
// }
