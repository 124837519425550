import React, { useState, useEffect } from "react"
import {GetTime} from '../data'

export default function Updater({week})
{
    const [activeGames, setActiveGames] = useState(0);
    const [refreshCnt, setRefreshCnt] = useState(0)
    const [loading1, setLoading1] = useState(true);
    const [liveScores, setLiveScores] = useState(0);
    const [loading2, setLoading2] = useState(true);
    const [error, setError] = useState(null);
  
      useEffect(() => {
      // Function to fetch data

      const fetchActive = async () => {
        try {
          const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=COUNT_ACTIVE_GAMES&Week="+week;
          const response = await fetch(url);
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          {
            setActiveGames(result);
            console.log (refreshCnt + " Updater found active games = " + activeGames)
          }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading1(false);
        }
      };
  
      const fetchScores = async () => {
        try {
          const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_SCOREBOARD&Week="+week;
          const response = await fetch(url);
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          {
            setLiveScores(result);
          }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading2(false);
        }
      };

      fetchActive();
      if (activeGames > 0) fetchScores();
  
  }, [refreshCnt]);  


  useEffect(() => {

        // Set up an interval that runs every second (1000ms)
    const interval = setInterval(() => {
      setRefreshCnt(prevCount => prevCount + 1); // Update state using the previous value
    }, 10000);

    // Cleanup the interval when the component unmounts or dependencies change
    return () => clearInterval(interval);
  }, []); // Empty dependency array to run only on mount and unmount


  let tm = GetTime();

  return (<h2 className="mark">{tm}</h2>)

}