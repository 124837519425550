
import MenuCommon from "./MenuCommon";
import HandleCommon from './HandleCommon';

import MenuTeam from "./MenuTeam";
import HomeTeam from "./HomeTeam";
import TeamBar from "./TeamBar"




import {
        RetrieveConfTeams,
        RetrieveSchedule,
        RetrieveNextOpponent,
        RetrievePassingStats,
        RetrieveRushingStats,
        RetrieveReceivingStats,
        RetrieveDefensiveStats,
        RetrieveConfPassingStats,
        RetrieveConfRushingStats,
        RetrieveConfReceivingStats,
        RetrieveConfDefensiveStats,
        RetrieveTeamPassingStats,
        RetrieveTeamRushingStats,
        RetrieveTeamReceivingStats,
        RetrieveTeamDefensiveStats,
        RetrieveCurrentScoreboard} from "../dungeonapi"

import {LoadConfStandings,
        LoadConfTeams,
        LoadNextOpponent,
        LoadPassingStats,
        LoadRushingStats,
        LoadReceivingStats,
        LoadDefensiveStats,
        LoadTeamHooks,
        LoadThisWeeksGames,
        // LoadLastWeeksGames,
        FilterGamesByTID} from "../data"

import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import * as GlobalData from '../data';
import * as Images from '../images';
import { MenuChoices } from '../Menu';

export default function MainTeam({activeMenu, setActive, GID, setGID, confName, TID, teamName})
{
    const [scheduleRetrieved, setScheduleRetrieved] = useState(false);
    const [standingsRetrieved, setStandingsRetrieved] = useState(false);

    const [gamesRetrieved, setGamesRetrieved] = useState(false);

    const [resultsRetrieved, setResultsRetrieved] = useState(false);
    const [driveRetrieved, setDriveRetrieved] = useState(false);
    
    const [nextOpponentRetrieved, setNextOpponentRetrieved] = useState(false);
    const [teamsRetrieved, setTeamsRetrieved] = useState(false);
    const [scoresLevel, setScoresLevel] = useState(1);
    const [gameInfo, setGameInfo] = useState(null)


    const [tName, setTeamName] = useState(false);


    const navigate = useNavigate();

    const justSet = (val) => {
        setActive(val)
      }
        
    const justMove = (nm) => {
        navigate("/"+nm)
      }

    const setAndMoveUp = (nm, val) => {
        navigate("/"+nm)
        setActive(val)
      }

    var LastWeeksGame = FilterGamesByTID (GlobalData.LastWeeksGames, TID);
    var LastWeeksGID = LastWeeksGame.GID;
          
        useEffect(() => { 
        
            RetrieveConfTeams (confID, LoadConfTeams, setTeamsRetrieved);	
            // RetrieveNextOpponent (TID, GlobalData.currWeek, LoadNextOpponent, setNextOpponentRetrieved)
        
            RetrieveCurrentScoreboard (GlobalData.currYear, GlobalData.currWeek, LoadThisWeeksGames, setGamesRetrieved)
            // if (GlobalData.currWeek > 0) 
            //     RetrieveCurrentScoreboard (GlobalData.currYear, GlobalData.currWeek-1, LoadLastWeeksGames, setResultsRetrieved)
            
            if (activeMenu === MenuChoices.SCHEDULE || activeMenu === MenuChoices.ROSTER || activeMenu === MenuChoices.DEPTH )
                setActive(MenuChoices.HOME)
            else
                console.log ("MainTeam: Team changed but Menu left unchanged")
            
        }, [TID]);    
        
    
    let confID = Images.ConfNames.indexOf(confName)

    // console.log ("MainTeam...")
    // console.log (": confID="+confID+" confName="+confName+ " Last GID="+LastWeeksGID)
          
    return (
        <div className="App grid-container-10-90">

            <TeamBar placement ={"left-column"}
                    layout={"box"} 
                    confID={confID}
                    TID={TID} />

            <div className="right-column">

                <div className="box btn-container">
                    <MenuCommon justSet={justSet}  setAndMoveUp={setAndMoveUp} />
                </div>

                <MenuTeam 
                    placement={""} 
                    layout={"box"}
                    wk={GlobalData.currWeek} 
                    confID={confID}
                    confName={confName}
                    setActive={setActive}
                    justSet={justSet}
                    TID={TID}
                />            

                <HandleCommon 
                    parent         = {"MainTeam"}
                    yr             = {GlobalData.currYear}
                    wk             = {GlobalData.currWeek}
                    activeMenu     = {activeMenu}
                    setActive      = {setActive}
                    GID            = {GID}
                    setGID         = {setGID}
                    initStatLevel  = {2} 
                    preview        = {GlobalData.Preview}
                    confID         = {confID}
                    TID            = {TID}
                    confLabel      = {confName}
                    teamLabel      = {teamName}
                />

                {activeMenu===MenuChoices.HOME ? 
                    <HomeTeam 
                        placement={"three-quarter-width scroll"} 
                        confName={confName}
                        teamName={teamName}
                        TID={TID}
                        setActive={setActive}
                        setGID={setGID}
                        setGameInfo={setGameInfo}
                        setTeamName={setTeamName}
                        gamesRetrieved={gamesRetrieved}
                        scheduleRetrieved={scheduleRetrieved}
                        setDriveRetrieved={setDriveRetrieved}                            
                        />
                            : <></> }

    

            </div>
        </div>
   )
}