import React, { useState, useEffect } from "react"
import * as GlobalData from '../data';
import * as Images from '../images';
import { LoadPlays } from '../data';

export default function PlayGameView2({placement, setPopup, GID})
{
    const [playLevel, setPlayLevel] = useState(0);
    const [playData, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(true);

    const closePopup = () => {
        setIsPopupOpen(false);
        setPopup(0)
    }
    
    const ChangePlayLevel = (val) => {
        setPlayLevel(val)
      }

      useEffect(() => {
      // Function to fetch data
      const fetchData = async () => {
        try {
            const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_PLAYS_BY_GID&GID="+GID;
            console.log (url)
            const response = await fetch(url);
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          {
            setData(result);
            LoadPlays(result)
          }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading(false);
        }
      };
  
      if (GID > 0) fetchData();
  
  }, [GID]);  
  
    if (loading) 
      return (<h2>Loading...</h2>);
    else if (error) 
      return (<h2>Error: {error}</h2>);
    else 
    {
        var quarters = GlobalData.Quarters.map((data,id)=>{
            return <PlayList key={id} playlist={data} onlyScores={false} playLevel={playLevel} setPlayLevel={setPlayLevel}/>
        })

        var scoresOnly = GlobalData.Quarters.map((data,id)=>{
            return <PlayList key={id} playlist={data} onlyScores={true} playLevel={playLevel} setPlayLevel={setPlayLevel}/>
        })

        return (
            <div className="popup-noscroll">
                <button className="close-icon" onClick={closePopup}>&times;</button>
                <br></br>
                <h2 className="popup-item">PLAYS</h2>
                <br></br>
                <div className="popup-container">
                    <button className={`${playLevel===0 ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  ChangePlayLevel(0)} >ALL PLAYS</button>
                    <button className={`${playLevel===1 ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  ChangePlayLevel(1)} >SCORES ONLY</button>
                    <div className="popup-item"></div>
                </div>
                <div className="popup-item-scroll">
                {playLevel===1?scoresOnly:quarters}
                </div>
            </div>    )
    }
}

function PlayList({playlist, onlyScores, playLevel, setPlayLevel})
{

    var quarter=0;
    var plays = playlist.map((data,id)=>{
        let img = Images.TeamImagePaths[data.offTID];
        let side = (data.yard_line < 0 ? "on opp " : "on own ");
        let ydln = (data.yard_line < 0 ? -1*data.yard_line : data.yard_line);
        let downdist = data.down + " & " + data.distance;
        var score= data.offense + " " + data.offense_score + " " + data.defense+ " " + data.defense_score;
        var score2 = data.offense_score + "-" + data.defense_score;
        if (data.defense_score > data.offense_score)
        {
            score = data.defense+ " " + data.defense_score + " " + data.offense + " " + data.offense_score 
            score2 = data.defense_score + "-" + data.offense_score 
        }
        quarter=data.period;
        
        if (onlyScores===true && data.scoring === true)
            return <PlayViewSummary key={id} time={data.time} qtr={data.period} img={img}  ptxt={data.play_text} score={score2}/>

        else if (onlyScores===true  && data.scoring === false)
            return (<></>) 

        else if (data.play_type === 'Kickoff' && data.scoring)
            return (<>
                    <PlayViewKO key={id} time={data.time} img={img} ptyp={data.play_type} ptxt={data.play_text}  ydln={ydln} side={side}/>
                    <tr style={{ fontColor: 'khaki'}}><td></td><td></td><td></td><td></td><td style={{ textAlign: 'left', padding: '2px 0', fontSize: '0.7em', color: 'khaki'}}>{score}</td></tr>
                    </>
                    )
        else if (data.play_type === 'Kickoff' && data.scoring === false)
            return (<PlayViewKO key={id} time={data.time} img={img} ptyp={data.play_type} ptxt={data.play_text}  ydln={ydln} side={side}/>)

        else if (data.play_type != 'Kickoff' && data.scoring)                
            return (<>
                    <PlayViewLOS key={id} time={data.time} img={img} downdist={downdist} ptxt={data.play_text}  ydln={ydln} side={side}/> 
                    <tr style={{ fontColor: 'khaki'}}><td></td><td></td><td></td><td></td><td style={{ textAlign: 'left', padding: '2px 0', fontSize: '0.7em' , color: 'khaki'}}>{score}</td></tr>
                    </>)
        else
            return (<PlayViewLOS key={id} time={data.time} img={img} downdist={downdist} ptxt={data.play_text}  ydln={ydln} side={side}/> )
    })

    return (
        <>
        {onlyScores==true?
        <>
        <h3>QUARTER {quarter}</h3>        
        <table>
        <colgroup>
                <col style={{ width: '10%', textAlign: 'left'}} />
                <col style={{ width: '10%', textAlign: 'center'}} />
                <col style={{ width: '10%', textAlign: 'center' }} />
                <col style={{ width: '70%', textAlign: 'left' }} />
        </colgroup>        
        <tbody>
            {plays}
        </tbody>
        </table>
        </>
        :
        <>
        <h3>QUARTER {quarter}</h3>        
        <div>
        <table>
        <colgroup>
                <col style={{ width: '5%', textAlign: 'center'}} />
                <col style={{ width: '5%', textAlign: 'center'}} />
                <col style={{ width: '10%', textAlign: 'center' }} />
                <col style={{ width: '20%', textAlign: 'center' }} />
                <col style={{ width: '60%', textAlign: 'left' }} />
        </colgroup>        
        <tbody>
            {plays}
        </tbody>
        </table>
        </div>
        </>
        }
        </>
        )
}

function PlayViewSummary ({qtr, time, img, score, ptxt})
{
    return (
        <tr>
        <td style={{  textAlign: 'left', padding: '2px 0', fontSize: '0.7em' }}>Q{qtr} {time}</td>
        <td><img className='icon-tiny' src={img} alt={""}></img></td>
        <td style={{  padding: '2px 0', fontSize: '0.7em' }}>{score}</td>
        <td style={{ textAlign: 'left', padding: '2px 0', fontSize: '0.7em' }}>{ptxt}</td></tr>
    )
}

function PlayViewLOS({time, img, downdist, ptxt, ydln, side, scoring})
{
    return (
        <tr>
            <td style={{  padding: '2px 0', fontSize: '0.7em' }}>{time}</td>
            <td><img className='icon-tiny' src={img} alt={""}></img></td>
            <td style={{  padding: '2px 0', fontSize: '0.7em' }}>{downdist}</td>
            <td style={{  padding: '2px 0', fontSize: '0.7em' }}>{side}{ydln}</td>
            <td style={{ textAlign: 'left', padding: '2px 0', fontSize: '0.7em' }}>{ptxt}</td>
        </tr>
    )
}

function PlayViewKO({time, img,  ptyp, ptxt, ydln, side, scoring})
{
    return (
        <tr>
            <td style={{  padding: '2px 0', fontSize: '0.7em' }}>{time}</td>
            <td><img className='icon-tiny' src={img} alt={""}></img></td>
            <td style={{  padding: '2px 0', fontSize: '0.7em' }}>{ptyp}</td>
            <td style={{  padding: '2px 0', fontSize: '0.7em' }}>{side}{ydln}</td>
            <td style={{  textAlign: 'left', padding: '2px 0', fontSize: '0.7em' }}>{ptxt}</td>
        </tr>
    )
}
