import DepthChartTable from "./DepthChartTable";
import React, { useState, useEffect } from "react"

export default function DepthChartView({placement, TID, setPID, setPopup})
{
    const [depthChart, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      // Function to fetch data
      const fetchData = async () => {
        try {
          const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_DEPTHCHART&TID="+TID;
          console.log (url)
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          {
            setData(result);
          }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading(false);
        }
      };
  
      if (TID > 0) fetchData();
  
  }, [TID]);  
  
    if (loading) 
      return (<h2>Loading...</h2>);
    else if (error) 
      return (<h2>Error: {error}</h2>);
    else 
    {
        console.log ("DepthChartView")
        let QB=depthChart.QB;
        let RB=depthChart.RB;
        let WR=depthChart.WR;
        let TE=depthChart.TE;
        let DL=depthChart.DL;
        let LB=depthChart.LB;
        let DB=depthChart.DB;

        return (
            <div className={placement}>
                <DepthChartTable label="QB" obj={QB} setPID={setPID} setPopup={setPopup}/> 
                <DepthChartTable label="RB" obj={RB} setPID={setPID} setPopup={setPopup}/> 
                <DepthChartTable label="WR" obj={WR} setPID={setPID} setPopup={setPopup}/> 
                <DepthChartTable label="TE" obj={TE} setPID={setPID} setPopup={setPopup}/> 
                <DepthChartTable label="DL" obj={DL} setPID={setPID} setPopup={setPopup}/> 
                <DepthChartTable label="LB" obj={LB} setPID={setPID} setPopup={setPopup}/> 
                <DepthChartTable label="DB" obj={DB} setPID={setPID} setPopup={setPopup}/> 
            </div>
    )
    }
}