import * as GlobalData from '../data';
import * as Images from '../images';

import React from "react"

export default function TvSchedule({placement})
{
  var TuesdayGames ;
  var WednesdayGames ;
  var ThursdayGames ;  
  var FridayGames ;
  var SaturdayGames ;
  var EarlyGames ;
  var AfternoonGames;
  var EveningGames;
  var LateGames;
  var TuesdayTVGames;
  var WednesdayTVGames ;
  var ThursdayTVGames;  
  var FridayTVGames;
  var EarlyTVGames ;
  var AfternoonTVGames;
  var EveningTVGames;
  var LateTVGames;

  const earlyTimes = ["11:00", "11:30","12:00", "12:30","13:00", "13:30"]; 
  const afternoonTimes = ["14:00", "14:30","15:00", "15:30","16:00", "16:30","17:00", "17:30"]; 
  const eveningTimes = ["18:00", "18:30", "19:00", "19:30","20:00", "20:30","21:00"]; 
  const lateTimes = ["21:30","22:00", "22:15","22:30","23:00", "23:30"]; 
  const majorNetworks=["ABC","CBS","FOX","NBC","ESPN","ESPN2","FS1"];

  // console.log ("ThisWeeksGames count="+GlobalData.ThisWeeksGames.length)
  TuesdayGames   = FilterGamesByDay (GlobalData.ThisWeeksGames, 2);
  WednesdayGames = FilterGamesByDay (GlobalData.ThisWeeksGames, 3);
  ThursdayGames  = FilterGamesByDay (GlobalData.ThisWeeksGames, 4);
  FridayGames    = FilterGamesByDay (GlobalData.ThisWeeksGames, 5);
  SaturdayGames  = FilterGamesByDay (GlobalData.ThisWeeksGames, 6);
  EarlyGames     = FilterGamesByTime (SaturdayGames, earlyTimes);
  AfternoonGames = FilterGamesByTime (SaturdayGames, afternoonTimes);
  EveningGames   = FilterGamesByTime (SaturdayGames, eveningTimes);
  LateGames      = FilterGamesByTime (SaturdayGames, lateTimes);

  TuesdayGames   = SortMajor (TuesdayGames, majorNetworks);
  WednesdayGames = SortMajor (WednesdayGames, majorNetworks);
  ThursdayGames  = SortMajor (ThursdayGames, majorNetworks);
  FridayGames    = SortMajor (FridayGames, majorNetworks);
  EarlyGames     = SortMajor (EarlyGames, majorNetworks);
  AfternoonGames = SortMajor (AfternoonGames, majorNetworks);
  EveningGames   = SortMajor (EveningGames, majorNetworks);
  LateGames      = SortMajor (LateGames, majorNetworks);

  // console.log ("E="+EarlyGames.length + " AF="+AfternoonGames.length + " EV="+EveningGames.length + " L="+LateGames.length);

  TuesdayTVGames = TuesdayGames.map((data,id)=>{
        return <TvSlot key={id} game={data} />})
  
  WednesdayTVGames = WednesdayGames.map((data,id)=>{
    return <TvSlot key={id} game={data} />})
    
  ThursdayTVGames =ThursdayGames.map((data,id)=>{
    return <TvSlot key={id} game={data} />})
      
  FridayTVGames = FridayGames.map((data,id)=>{
    return <TvSlot key={id} game={data} />})
        
  EarlyTVGames = EarlyGames.map((data,id)=>{
    return <TvSlot key={id} game={data} />})

  AfternoonTVGames = AfternoonGames.map((data,id)=>{
    return <TvSlot key={id} game={data} />})

  EveningTVGames = EveningGames.map((data,id)=>{
    return <TvSlot key={id} game={data} />})

  LateTVGames = LateGames.map((data,id)=>{
    return <TvSlot key={id} game={data} />})      


  {
    const tueLen = TuesdayGames.length;
    const wedLen = WednesdayGames.length;
    const thuLen = ThursdayGames.length;
    const friLen = FridayGames.length;
    const earlyLen = EarlyGames.length;
    const afternoonLen = AfternoonGames.length;
    const eveningLen = EveningGames.length;
    const lateLen = LateGames.length;

    return (
        <div className={placement} >

            <h2 className="khaki">TV SCHEDULE</h2> 

            {tueLen > 0 && GlobalData.currDay <= 2 ?
            <>
            <h3 className="khaki">TUESDAY NIGHT GAMES</h3> 
            <table className="table table-dark table-striped tight-table three-quarter-width">
            <tbody style={{'height': '15vh', 'overflowY':'scroll', 'display': 'block'}}>
            {TuesdayTVGames}
            </tbody>
            </table></>:<></>}

            {wedLen > 0 && GlobalData.currDay <= 3 ?
            <>
            <h3 className="khaki">WEDNESDAY NIGHT GAMES</h3> 
            <table className="table table-dark table-striped tight-table three-quarter-width">
            <tbody style={{'height': '15vh', 'overflowY':'scroll', 'display': 'block'}}>
            {WednesdayTVGames}
            </tbody>
            </table></>:<></>}

            {thuLen > 0  && GlobalData.currDay <= 4 ?
            <>
            <h3 className="khaki">THURSDAY NIGHT GAMES</h3> 
            <table className="table table-dark table-striped tight-table three-quarter-width">
            <tbody style={{'height': '15vh', 'overflowY':'scroll', 'display': 'block'}}>
            {ThursdayTVGames}
            </tbody>
            </table></>:<></>}

            {friLen > 0  && GlobalData.currDay <= 5 ?
                <>
            <h3 className="khaki">FRIDAY NIGHT GAMES</h3> 
            <table className="table table-dark table-striped tight-table three-quarter-width">
            <tbody style={{'height': '15vh', 'overflowY':'scroll', 'display': 'block'}}>
            {FridayTVGames}
            </tbody>
            </table></>:<></>}
            
            {earlyLen > 0  && GlobalData.currDay <= 6  ? // before 4pm
            <>
            <h3 className="khaki">SATURDAY - EARLY GAMES</h3> 
            <table className="table table-dark table-striped tight-table three-quarter-width">
            <tbody style={{'height': '15vh', 'overflowY':'scroll', 'display': 'block'}}>
            {EarlyTVGames}
            </tbody>
            </table></>:<></>}

            {afternoonLen > 0  && GlobalData.currDay <= 6  ? // before 7pm
            <>
            <h3 className="khaki">SATURDAY - AFTERNOON GAMES</h3> 
            <table className="table table-dark table-striped tight-table three-quarter-width">
            <tbody style={{'height': '15vh', 'overflowY':'scroll', 'display': 'block'}}>
            {AfternoonTVGames}
            </tbody>
            </table></>:<></>}

            {eveningLen > 0  && GlobalData.currDay <= 6  ? // before 10pm
            <>
            <h3 className="khaki">SATURDAY - EVENING GAMES</h3> 
            <table className="table table-dark table-striped tight-table three-quarter-width">
            <tbody style={{'height': '15vh', 'overflowY':'scroll', 'display': 'block'}}>
                {EveningTVGames}
            </tbody>
            </table></>:<></>}

            {lateLen > 0  && GlobalData.currDay <= 6  ? // before 10pm
            <>
            <h3 className="khaki">SATURDAY - LATE GAMES</h3> 
            <table className="table table-dark table-striped tight-table three-quarter-width">
            <tbody style={{'height': '15vh', 'overflowY':'scroll', 'display': 'block'}}>
                {LateTVGames}
            </tbody>
            </table></>:<></>}
        </div>
    )
  }
}

export function TvSlot ({game} )
{
  // export const majorNetworks=["ABC","CBS","FOX","NBC","ESPN","ESPN2","FS1"];
  const networkNames=["ABC","CBS","FOX","NBC","ESPN","ESPN2","ESPNU","FS1","FS2","SECN","ACC NETWOR","PACN","CBSSN","BIG10","LHN","ESPN+","ESPN3","Peacock","Stadium","None"];  
  let awayPath=Images.TeamImagePaths[game.AwayTID];
  let homePath=Images.TeamImagePaths[game.HomeTID];
  var networkIdx=networkNames.indexOf(game.TV);
  if (networkIdx === -1) networkIdx=networkNames.indexOf("None");
  let network=Images.networkImagePaths[networkIdx];

return (<>
            <tr>
            <td><img className='icon-xs' align='center' src={network} alt=""></img></td> 
            <td className='cell150px'><img className='icon-xs' align='center' src={awayPath} alt=""></img></td> 
            <td className='cell150px'><img className='icon-xs' align='center' src={homePath} alt=""></img></td> 
            </tr>
            </>)
}

export function TvSlot2 ({game} )
{
  if (game === undefined)

    return (<>
      <tr>
      <td>game.AwayTID</td> 
      <td>game.HomeTID</td> 
      <td>game.TV</td> 
      </tr>
      </>)
  else
    return (<>
            <tr>
            <td>{game.AwayTID}</td> 
            <td>{game.HomeTID}</td> 
            <td>{game.TV}</td> 
            </tr>
            </>)
}


export function FilterGamesByDay (games, day)
{
  var list=[]
  for (let game of games)
    if (game.Day === day)
      list.push(game)
  return list;
}

export function FilterGamesByTime (games, times)
{
  var list=[];
  for (let game of games)
    if (times.includes(game.Time))
      list.push(game);
  
  return list;
}

export function SortMajor (games, networks)
{
  var major=[];
  var minor=[];
  var last = games.length;
  for(let idx=0; idx<last; idx++) 
      if (networks.includes(games[idx].TV) )
          major.push(games[idx]);
      else
          minor.push(games[idx]);
  
  var list=[];
  list = major.concat(minor);
  return list;
}
