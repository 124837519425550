import * as GlobalData from '../data';
import * as Images from '../images';
import React, { useState, useEffect } from "react"

export default function Rankings({ placement, label, year, week })
{
    const [rankingData, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        // Function to fetch data
        const fetchData = async () => {
            try {
                const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_RANKINGS&Year=" + year + "&Week=" + week;
                console.log (url)
                const response = await fetch(url);

                if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            {
              setData(result);
            }
            } 
            catch (error) {
                setError(error.message);
            } 
            finally {
                setLoading(false);
            }
        };
        
        fetchData();
        
    }, []);  
      
    if (loading) 
        return (<h2>Loading...</h2>);
    else if (error) 
        return (<h2>Error: {error}</h2>);
    else if (rankingData.length < 25)
        return (<h2>Week {week} poll is not yet available</h2>);
    else
    {
        const rankings = rankingData.map((data,id)=>{
            let imgpath=Images.TeamImagePaths[data.TID];
            let trendpath = (data.Trend===0) ? Images.flat_path : ((data.Trend===1) ? Images.down_path : Images.up_path);

            return <RankInfo key={id} imgpath={imgpath} trendpath={trendpath} rankInfo={data} />
        })

        return (
            <div className={placement}>
                <h2>{label} {week}</h2>
                    <table className="table table-dark table-striped tight-table three-quarter-width">
                    <colgroup>
                    <col style={{ width: '2%', textAlign: 'right'}} />
                    <col style={{ width: '10%', textAlign: 'left'}} />
                    <col style={{ width: '5%', textAlign: 'center' }} />
                    <col style={{ width: '30%', textAlign: 'center' }} />
                    <col style={{ width: '30%', textAlign: 'center' }} />
                    </colgroup>
                    <tbody>
                    <tr><th>Rank</th><th>Team</th><th>Trend</th><th>Recent</th><th>Next week</th></tr>
                        {rankings}
                    </tbody></table>
                </div>
            )
    }
}


function RankInfo({ imgpath, trendpath, rankInfo })
{
    // const path = "./img/"+rankInfo.SafeName+".png";

    return (
    <tr>
    <td style={{ textAlign: 'center', padding: '2px 0' }}># {rankInfo.Rank}</td> 
    <td><img className='icon-xsq' src={imgpath} alt=""></img></td> 
    <td ><img className='icon-xsq' src={trendpath}  alt=""></img></td> 
    <td style={{ textAlign: 'center', padding: '2px 0', fontSize: '0.7em' }}>{rankInfo.LastGame}</td> 
    <td style={{ textAlign: 'center', padding: '2px 0', fontSize: '0.7em' }}>{rankInfo.NextOpp}</td> 
    </tr>
     )
}