import { MenuChoices } from "../Menu"
export default function DepthChartRow({pos, jersey, name, pid, getGameLog})
{
    return (
        <tr><td>{pos}</td>
        <td>{jersey}</td>
        <td style={{textAlign: 'left', color: 'white'}}>{name}</td>
        <td><button className="ltblueButtonInv" onClick={() =>  getGameLog(MenuChoices.GAMELOG, pid)} >GAME LOG</button></td>
        </tr>
    )
}

  