import { MenuChoices } from "../Menu";

export default function ScoresWeekSelector({activeMenu, setActive, scoresLevel, ChangeScoresLevel, confLabel})
{
    // console.log ("...SWS:  ScoresLevel = " + scoresLevel + "activeMenu=" + activeMenu)
    return (
        <div className="box btn-container">
            <button className={`${activeMenu===MenuChoices.LAST_WEEK? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  setActive(MenuChoices.LAST_WEEK)} >Last Week</button>
            <button className={`${activeMenu===MenuChoices.SCORES ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  setActive(MenuChoices.SCORES)} >Upcoming</button>
            <button className={"noButton"} ></button>
            <button className={`${scoresLevel===0 ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  ChangeScoresLevel(0)} >FBS</button>
            <button className={`${scoresLevel===1 ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  ChangeScoresLevel(1)} >{confLabel}</button>
        </div>
    )
}