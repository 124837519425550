import { useNavigate } from "react-router-dom"
import * as GlobalData from '../data';
import * as Images from '../images';

export default function TeamBar({placement, layout, confID, teamsRetrieved, TID})
{
    const navigate = useNavigate();

    const goBack = () => {
        navigate("/FBS")
    }    

    const setAndMoveUp = (nm) => {
        navigate("/"+nm)
      }

    console.log ("TeamBar: ConfID="+confID+" TID="+TID)

    var ConfTeams1 = [];
    var ConfTeams2 = [];
    
    if (GlobalData.ConfTeams.length === 0)
    {
        ConfTeams1.push ({"TID": 1, "Name": "Air Force"})
        ConfTeams2.push ({"TID": 1, "Name": "Air Force"})
    }
    else
    {
        ConfTeams1 = []
        ConfTeams2 = []
        for (let i=0; i<GlobalData.ConfTeams.length; i++)
        if (i < 8)
            ConfTeams1.push (GlobalData.ConfTeams[i]);
        else
            ConfTeams2.push (GlobalData.ConfTeams[i]);        
    }

    let confImg = Images.ConfImagePaths[confID]

    var teamButtons = GlobalData.ConfTeams.map((data,id)=>{
        let teamImg = Images.TeamImagePaths[data.TID]

        if (TID === data.TID)
            return <TeamButton key={id} layout={"box graybk"} teamImg={teamImg} teamName={data.Name} setAndMoveUp={setAndMoveUp}/>
        else
            return <TeamButton key={id} layout={"box"} teamImg={teamImg} teamName={data.Name} setAndMoveUp={setAndMoveUp}/>
    })

      return (
        <div className={placement}>
            <div className="box graybk">
                <img className='btn-xsq2' onClick={() =>  goBack()} src={confImg}></img>
            </div>
                {teamButtons}
        </div>
    )
}

function TeamButton({layout, teamImg, teamName, setAndMoveUp})
{
    return (
        <div className={layout}>
            <img className='btn-xsq2' onClick={() =>  setAndMoveUp(teamName)} src={teamImg}></img>
        </div>    
    )
}