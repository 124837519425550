import GameType from "./games"
import Cookies from 'universal-cookie';

import {
    // RetrieveActiveDrive,
    RetrieveCurrentScoreboard
        } from "./dungeonapi"

import {LoadConfMember} from "./images";


//********************* 
//   MAJOR CONSTANTS
//********************* 

const cookies = new Cookies()

export var defaultConfSelected=1;
export var defaultTeamSelected=4;
export var OffSeason=false;

export const ConfLabels=["TBD", "SEC","B1G","ACC","PAC","XII","AAC","CUSA","MWC","SBC","MAC","INDEPENDENTS"];
export const ConfNames=["TBD", "SEC","Big Ten","ACC","PAC-12","Big XII","American","Conference USA","Mountain West","Sun Belt","MAC","INDEPENDENTS"];
export const SEC=1, B1G=2, ACC=3, PAC=4, XII=5, AAC=6, CUSA=7, MWC=8, SBC=9, MAC=10, INDY=11; 
export var Div1Label = "West";
export var Div2Label = "East";

export var currYear=2024;
export var currDay;
export var currWeek=0;
export var lastWeek;
export var currHour;
export var currMin;


export var GamesByChannel =  
	[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]];

// export var TeamRankByWeek = []
export var Power = []
// var prevRankings = [];               

// export var TwitterLists = [];
// export const majorNetworks=["ABC","CBS","FOX","NBC","ESPN","ESPN2","FS1"];
export const networkNames=["ABC","CBS","FOX","NBC","ESPN","ESPN2","ESPNU","FS1","FS2","SECN","ACC NETWOR","PACN","CBSSN","BIG10","LHN","ESPN+","ESPN3","Stadium","None"]

//********************* 
//   DOWNLOADED DATA
//********************* 

export var Games=[]
export var Teams=[]
export var ConfTeams=[]

export var LastWeeksGames = []
export var ThisWeeksGames = []

export const emptyTeam = { TID: 0, 
                    Name: '',
                    Logo: '',
                    Record: '',
                    ConfName: '',
                    Spread: 0,
                    Date: '',
                    Time: '',
                    Loc: ' ',
                    Coach: ' ',
                    PassingStats: [{Name: '', Yds: 0, TDs: 0}],
                    RushingStats: [{Name: '', Yds: 0, TDs: 0}],
                    ReceivingStats: [{Name: '', Yds: 0, TDs: 0}],
                    DefensiveStats: [{Name: '', Yds: 0, TDs: 0}]
}
export const emptyInfo = { GID: 0, 
                    AwayTID: 0,
                    HomeTID: 0,
                    Date: '',
                    Time: '',
                    TV: '',
                    Spread: 0,
                    Neutral: false,
                    Complete: false
                }

export var Preview = {Info: emptyInfo, 
                      Away: emptyTeam,
                      Home: emptyTeam}

export var NextOpponent = {TID: 0, 
                           Name: '',
                           Logo: '',
                           Record: '',
                           ConfName: '',
                           Spread: 0,
                           Date: '',
                           Time: '',
                           Loc: ' ',
                           Coach: ' ',
                           PassingStats: [{Name: '', Yds: 0, TDs: 0}],
                           RushingStats: [{Name: '', Yds: 0, TDs: 0}],
                           ReceivingStats: [{Name: '', Yds: 0, TDs: 0}],
                           DefensiveStats: [{Name: '', Yds: 0, TDs: 0}]
}

export var OurRecord = 0;
export var LastGame = 0;
export var NextGame = 0;

export var TempPlays = [{"Offense" :"-", 
                         "Down"     : null, 
                         "Distance" : null,
                         "YardLine" : null,
                         "PlayText" : null}];

export var DriveTime = "00:00:00";
export var ScoreboardTime = "00:00:00";

export var DriveData = {"Status"   : "Upcoming",
                        "Period"   : "TBD",
                        "Clock"    : " ", 
                        "Poss"     : null,
                        "Down"     : null,
                        "Dist"     : null,
                        "YardLine" : null,
                        "HomeID"   : null,
                        "HomeTeam" : null,
                        "HomeScore": 0,
                        "AwayID"   : null,
                        "AwayTeam" : null,
                        "AwayScore": 0, 
                        "Plays"    : TempPlays}
export var ActiveGame = 0;
export function SetActiveGame(x){ActiveGame=x;}

export var AllStandings = []
export var ConfStandings = []
export var CCGames = []
export var BowlGames = []
export var Headlines=[];  
export var Rankings = [];
export var Quarters = [];
export var Plays = [];

export var Recruits = [];
export var Coaches = [];
export var PollHistory;
    
export var PassingStats = []
export var RushingStats = []
export var ReceivingStats = []
export var DefensiveStats = []

export var GamePassingStats = []
export var GameRushingStats = []
export var GameReceivingStats = []
export var GameDefensiveStats = []

export var Roster=[]
export var FutureOpps = []

export var Portal_In  = [];
export var Portal_Out = [];

export var DepthChart;
// export var Highlight;


//********************* 
//   DERIVED DATA
//********************* 

export var Schedule = [];

export var TuesdayGames = []
export var WednesdayGames = []
export var ThursdayGames = []
export var FridayGames = []

export var EarlyGames = []
export var AfternoonGames = []
export var EveningGames = []
export var LateGames = []

export var SortedEarlyGames = []
export var SortedAfternoonGames = []
export var SortedEveningGames = []
export var SortedLateGames = []

export var FbsPassingStats = []
export var FbsRushingStats = []
export var FbsReceivingStats = []

export var ConfPassingStats = []
export var ConfRushingStats = []
export var ConfReceivingStats = []

export var TeamPassingStats = []
export var TeamRushingStats = []
export var TeamReceivingStats = []

export var FbsHeadlines=[];  
export var ConfHeadlines=[];  
export var TeamHeadlines=[];  

export var RosterOff=[]
export var RosterDef=[]
export var RosterST=[]

export var allLen = 0;
export var tueLen = 0;
export var wedLen = 0;
export var thuLen = 0;
export var friLen = 0;
export var satLen = 0;
export var sunLen = 0;
export var monLen = 0;

export var DivRecords1 = []
export var DivRecords2 = []

export var RefreshCnt=0;

export const ConfMemberIDs=[[0],[],[],[],[],[],[],[],[],[],[],[],[]];
    
export function Refresh()
{
    RefreshCnt++;
    console.log ("Data refresh="+RefreshCnt)
    // if (ActiveGame > 0) RetrieveActiveDrive (ActiveGame, LoadDriveData, SetDriveRetrieved)
    // RetrieveCurrentScoreboard (currYear, currWeek,  LoadThisWeeksGames, SetGamesRetrieved)
}


export function InitDates()
{ 
    var d = new Date();
    var mon = d.getMonth() + 1;
    var day = d.getDate();
    currHour = d.getHours();
    
    currMin = d.getMinutes();

    currYear = d.getFullYear();
 
    currDay = d.getDay() ;
   
    currWeek = CalculateCurrWeek (parseInt(mon), parseInt(day));
    
    if (currWeek === -1) OffSeason = true;

    // *********************************  
        // currYear = 2021
        // currWeek = 13
    // *********************************  

    if (currWeek === 0)
      lastWeek = 0;
    else
      lastWeek = currWeek - 1;     

    // console.log ("..."+currYear+"-"+mon+"-"+day+" Week="+currWeek+" Day="+currDay+" Time="+currHour+":"+currMin)
}
 


export function GetTime()
{
    var d = new Date();
    let hh = d.getHours();
    let mm = d.getMinutes();
    let ss = d.getSeconds();

    if (hh < 10) hh = "0"+hh;
    if (mm < 10) mm = "0"+mm;
    if (ss < 10) ss = "0"+ss;
    
    return (hh + ":" + mm+ ":" + ss);
}

/*****************************************
 * THIS IS SPECIFIC TO 2023 SEASON
 * DETERMINES WEEK OF SEASON 
 * WEEKS END ON SUNDAY (EXCEPT LABOR DAY)
**************************************** */
function CalculateCurrWeek (month, day)
{
    var wk=0;

    if (month === 8)
    {
        if (day < 26)
            wk = 0;
        else
            wk = 1;
    }
    else if (month === 9)
    {
        if      (day <= 1)  wk = 1;  // LABOR DAY IS IN WEEK 1
        else if (day <= 7) wk = 2;
        else if (day <= 14) wk = 3;
        else if (day <= 21) wk = 4;
        else if (day <= 28) wk = 6;
        else                wk = 6;
    }
    else if (month === 10)
    {
        if (day <= 5)  wk = 6;
        else if (day <= 12) wk = 7;
        else if (day <= 19) wk = 8;
        else if (day <= 26) wk = 9;
        else                wk = 10;
    }
    else if (month === 11)
    {
        if (day <= 2)  wk = 10;
        else if (day <= 9) wk = 11;
        else if (day <= 16) wk = 12;
        else if (day <= 23) wk = 13;
        else                wk = 14;
    }
    else if (month === 12)
    {
        if      (day <= 7)  wk = 15;
        else if (day <= 14) wk = 16;
        else wk = 17;  // POST-SEASON
    }
    else if (month === 1)
    {    
        wk = 16; // POST-SEASON
        currYear = currYear - 1;
    }
    else
    {
        wk = 0;
    }

    return wk;
}


export function LoadNextOpponent(obj)
{
    NextOpponent = obj;
    console.log ("...Next opponent received")
}

export function ClearPreview(obj)
{
    Preview = {Info: emptyInfo, 
        Away: emptyTeam,
        Home: emptyTeam}
    console.log ("...Preview cleared");
}

export function LoadPreview(obj)
{
    Preview = obj;
    console.log ("...Preview received")
}




export function SetCookie(cname, cvalue, exdays) 
{
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	// var expires = "expires="+d.toUTCString();
	//document.cookie = cname + "=" + cvalue + ";" + "SameSite=Lax;" + expires + ";path=/";
  }
  
// function GetCookie(cname) {
// 	var name = cname + "=";
// 	var ca = document.cookie.split(';');
// 	for(var i = 0; i < ca.length; i++) {
// 	  var c = ca[i];
// 	  while (c.charAt(0) === ' ') {
// 		c = c.substring(1);
// 	  }
// 	  if (c.indexOf(name) === 0) {
// 		return c.substring(name.length, c.length);
// 	  }
// 	}
// 	return "";
// }
  
// function CheckCookie() {
// 	var user = GetCookie("username");
// 	if (user !== "") {
// 	  alert("Welcome again " + user);
// 	} else {
// 	  user = prompt("Please enter your name:", "");
// 	  if (user !== "" && user !== null) {
// 		SetCookie("username", user, 365);
// 	  }
// 	}
// }



export function ProcessCookies()
{
    var c=1, t=4;
	if (cookies.get("confSelected") === undefined)
	{
        console.log ("No cookie set - default ConfSelected ="+ c + " teamSelected=" + t)
		defaultConfSelected = 1;
		defaultTeamSelected = 4;
	}
	else
	{
		c = cookies.get("confSelected");	
		t = cookies.get("teamSelected");	
		defaultConfSelected = 1; // parseInt(c);
		defaultTeamSelected = 4; //parseInt(t);
        console.log ("cookies found ConfSelected ="+ c + " teamSelected=" + t)
	}
}


export function FindLastWeeksGame (tid)
{
    for (let g=0; g<LastWeeksGames.length; g++)
    {
        if (LastWeeksGames[g].IsThisTeamPlaying(tid)) 
            return LastWeeksGames[g];
    }

    console.log ("ERROR: FindLastWeeks Game did NOT find a game for TID="+tid)

    var ByeGame = Object.create(GameType)

    ByeGame.Basics (0, 229, tid, 0,0,0,0,false,false,0)

    ByeGame.AwayTeam = "BYE"
    ByeGame.HomeTeam = Teams[tid].Name

    return ByeGame;
}



export function FindThisWeeksGame (tid)
{
    for (let g=0; g<ThisWeeksGames.length; g++)
    {
        if (ThisWeeksGames[g].IsThisTeamPlaying(tid)) return ThisWeeksGames[g];
    }

    console.log ("ERROR: FindNextWeeks Game did NOT find a game for TID="+tid)
    var ByeGame = Object.create(GameType)

    ByeGame.Basics (0, 229, tid, 0,0,0,0,false,false,0)
    ByeGame.AwayTeam = "BYE"
    ByeGame.HomeTeam = Teams[tid].Name

    return ByeGame;
}


export function CountGames()
{
    tueLen = CountGamesByDay(ThisWeeksGames, 2)
    wedLen = CountGamesByDay(ThisWeeksGames, 3)
    thuLen = CountGamesByDay(ThisWeeksGames, 4)
    friLen = CountGamesByDay(ThisWeeksGames, 5)
    satLen = CountGamesByDay(ThisWeeksGames, 6)
    sunLen = CountGamesByDay(ThisWeeksGames, 0)
    monLen = CountGamesByDay(ThisWeeksGames, 1)
}


export function CountGamesByDay(tmpgames, day)
{
    var cnt=0;
    for (let g=0; g<tmpgames.length; g++)
    {
        if (tmpgames[g].Day === day || day === -1) cnt++
    }
    return cnt;
}


export function FilterPassingStatsByConf(conf)
{
    ConfPassingStats=[];

    var cnt1=0;
    for (let stat of PassingStats)
        if (conf === parseInt(stat.Conf) && cnt1 < 5) 
        {
            ConfPassingStats.push(stat);
            cnt1++;
        }
}

export function FilterRushingStatsByConf(conf)
{
    ConfRushingStats=[];

    var cnt2=0;
    for (let stat of RushingStats)
        if (conf === parseInt(stat.Conf) && cnt2 < 5) 
        {
            ConfRushingStats.push(stat);
            cnt2++;
        }
}    
export function FilterReceivingStatsByConf(conf)
{
    ConfReceivingStats=[];

    var cnt3=0;
    for (let stat of ReceivingStats)
        if (conf === parseInt(stat.Conf) && cnt3 < 5) 
        {
            ConfReceivingStats.push(stat);
            cnt3++;
        }
}
    
export function FilterPassingStatsByFbs()
{
        FbsPassingStats=[];
    
        var cnt1=0;
        for (let stat of PassingStats)
            if (cnt1 < 5) 
            {
                FbsPassingStats.push(stat);
                cnt1++;
            }
}

export function FilterRushingStatsByFbs()
{
        FbsRushingStats=[];
    
        var cnt2=0;
        for (let stat of RushingStats)
            if (cnt2 < 5) 
            {
                FbsRushingStats.push(stat);
                cnt2++;
            }        
}

export function FilterReceivingStatsByFbs()
{
    FbsReceivingStats=[];
    
        var cnt3=0;
        for (let stat of ReceivingStats)
            if (cnt3 < 5) 
            {
                FbsReceivingStats.push(stat);
                cnt3++;
            }
}

export function FilterPassingStatsByTeam(tid)
{
    TeamPassingStats=[];

    var cnt1=0;
    for (let stat of PassingStats)
        if (tid === parseInt(stat.TID) && cnt1 < 5) 
        {
            TeamPassingStats.push(stat);
            cnt1++;
        }
}

export function FilterRushingStatsByTeam(tid)
{
    TeamRushingStats=[];

    var cnt2=0;
    for (let stat of RushingStats)
        if (tid === parseInt(stat.TID) && cnt2 < 5) 
        {
            TeamRushingStats.push(stat);
            cnt2++;
        }
}

export function FilterReceivingStatsByTeam(tid)
{
    TeamReceivingStats=[];

    var cnt3=0;
    for (let stat of ReceivingStats)
        if (tid === parseInt(stat.TID) && cnt3 < 5) 
        {
            TeamReceivingStats.push(stat);
            cnt3++;
        }
}


export function ExtractStatsByTeam(nextTeam, stats)
{
    var extractedStats=[];

    var cnt=0;
    for (let stat of stats)
        if (nextTeam === stat.Team && cnt < 3) 
            {
                extractedStats.push(stat);
                cnt++;
            }
    return extractedStats;
}



export function ExtractSpecificRecord(nextTeam)
{
    for (let thisConf of AllStandings)
    {
        for (let teamrec of thisConf.Records)
        {
            if (nextTeam === teamrec.Name)
            {
                return  teamrec;
            }
        }
    }
}          



// export function FilterHeadlines()
// {
//     FilterHeadlinesByFbs()

//     FilterHeadlinesByConf(defaultConfSelected)

//     FilterHeadlinesByTeam(defaultTeamSelected)        
// }

// export function FilterHeadlinesByFbs()
// {
//     FbsHeadlines = []
//     for (let x of Headlines)
//         if (x.Conf === 0 && x.Team === 0)
//             FbsHeadlines.push(x)
// }


// export function FilterHeadlinesByConf(cnf)
// {
//     ConfHeadlines = []
//     for (let x of Headlines)
//         if (x.Conf === cnf && x.Team === 0)
//             ConfHeadlines.push(x)
// }


// export function FilterHeadlinesByTeam(tm)
// {
//     TeamHeadlines = []
//     for (let x of Headlines)
//         if (x.Team === tm)
//             TeamHeadlines.push(x)
// }




export function LoadGames(dbGames)
{
    for (var i=0; i<dbGames.length; i++)
    {
        let g = parseInt(dbGames[i].GID);
        
        Games[g].Basics (
                            parseInt (dbGames[i].GID),
                            parseInt (dbGames[i].AwayTID),
                            parseInt (dbGames[i].HomeTID),
                            parseInt (dbGames[i].AwayScore),
                            parseInt (dbGames[i].HomeScore),
                            parseInt (dbGames[i].Week),
                            parseInt (dbGames[i].Day),
                            dbGames[i].Active,
                            dbGames[i].Complete);
        
    }

    console.log ("...Games loaded cnt="+dbGames.length)

    RetrieveCurrentScoreboard (currYear, currWeek, LoadThisWeeksGames, Dummy)
    // if (currWeek > 0) 
    //     RetrieveCurrentScoreboard (currYear, currWeek-1, LoadLastWeeksGames, Dummy)

    // UpdateTvSchedule()
}


export function LoadThisWeeksGames(dbGames)
{
    ScoreboardTime = GetTime();

    ThisWeeksGames = []


    for (var i=0; i<dbGames.length; i++)
    {
         const Game = Object.create(GameType);	

         Game.Initialize   (parseInt (dbGames[i].GID),
                            parseInt (dbGames[i].AwayTID),
                            parseInt (dbGames[i].HomeTID),
                            dbGames[i].AwayTeam,
                            dbGames[i].HomeTeam,
                            dbGames[i].AwayCoach,
                            dbGames[i].HomeCoach,
                            dbGames[i].AwayStar,
                            dbGames[i].HomeStar,
                            parseInt (dbGames[i].AwayConf),
                            parseInt (dbGames[i].HomeConf),
                            parseInt (dbGames[i].AwayScore),
                            parseInt (dbGames[i].HomeScore),
                            dbGames[i].AwayImgName,
                            dbGames[i].HomeImgName,
                            parseFloat (dbGames[i].Spread),
                            parseInt (dbGames[i].Week),
                            parseInt (dbGames[i].Day),
                            dbGames[i].TV,
                            dbGames[i].Time,
                            dbGames[i].Status,
                            dbGames[i].Neutral,
                            dbGames[i].Active,
                            dbGames[i].Complete);
        // console.log (Game)
        ThisWeeksGames.push(Game)
    }

    tueLen = CountGamesByDay(ThisWeeksGames, 2)
    wedLen = CountGamesByDay(ThisWeeksGames, 3)
    thuLen = CountGamesByDay(ThisWeeksGames, 4)
    friLen = CountGamesByDay(ThisWeeksGames, 5)
    satLen = CountGamesByDay(ThisWeeksGames, 6)
    sunLen = CountGamesByDay(ThisWeeksGames, 0)
    monLen = CountGamesByDay(ThisWeeksGames, 1)

    // UpdateTvSchedule()
}


export function FilterGamesByConf (games, confID)
{
    var list = []
    for (var i=0; i<games.length; i++) 
        if (games[i].AwayConf === confID || games[i].HomeConf === confID)
            list.push (games[i]);

    // console.log ("Filtered "+games.length + " games by conf ID="+confID+" #confgames="+list.length)

    return list;
}

export function FilterGamesByTID (games, TID)
{
    for (var i=0; i<games.length; i++) 
        if (games[i].AwayTID === TID || games[i].HomeTID === TID)
        {
            console.log ("FILTER "+ games[i].AwayCoach + " " + games[i].HomeCoach)
            return (games[i]);
        }

    return -1;
}



export function LoadConfTeams(dbteams)
{
    ConfTeams=[]

    for (var i=0; i<dbteams.length; i++)
    {
        ConfTeams.push (dbteams[i])
    }

    console.log ("...Conf Teams Loaded  = "+ConfTeams.length)

}

export function LoadTeams(dbteams)
{
    var id;
    let clsList = ["TBD", "Freshman", "Sophomore", "Junior", "Senior"]


    for (var i=0; i<dbteams.length; i++)
    {
        id = parseInt(dbteams[i].TID);
        Teams[id].TID         = parseInt(dbteams[i].TID);
        Teams[id].Name        = dbteams[i].Name;
        Teams[id].Nickname    = dbteams[i].Nickname;
        Teams[id].Rank        = parseInt(dbteams[i].Rank);
        Teams[id].Conference  = dbteams[i].Conference;
        Teams[id].ConfID      = parseInt(dbteams[i].ConfID);
        Teams[id].Division    = parseInt(dbteams[i].Division);
        Teams[id].BgColor     = dbteams[i].BgColor;
        Teams[id].FgColor     = dbteams[i].FgColor;
        Teams[id].Coach       = dbteams[i].Coach;
        let cls = dbteams[i].StarClass;
        let pos = dbteams[i].StarPos
        let fnm = dbteams[i].StarName
        Teams[id].Star        = clsList[cls] + " " + pos + " " + fnm;
        
        if (Teams[id].ConfID === 12) // FCS
        {
            Teams[id].SafeName    = "ncaa";
            Teams[id].RosterName  = "ncaa";
        }
        else
        {
            Teams[id].SafeName    = dbteams[i].SafeName;
            Teams[id].RosterName  = dbteams[i].RosterName;
        }

        // TwitterLists[id] = twitterPath + Teams[id].SafeName;

        // Fill "power" data structure
        if (Teams[id].Rank <= 130)
        {
            Power[Teams[id].Rank] = id;
        }

        LoadConfMember (Teams[id].ConfID, id, dbteams[i].Name);
        ConfMemberIDs[Teams[id].ConfID].push (id)
    }
    console.log ("...Teams loaded cnt=" + dbteams.length)

    SetTeamsRetrieved(true)
}



// export function LoadRankings(data)
// {
//     Rankings = []
//     for (let x of data)
//         Rankings.push(x)
//     console.log ("...Rankings loaded cnt="+Rankings.length)
// }

export function LoadPassingStats(data)
{
    PassingStats = []
    for (let x of data)
        PassingStats.push(x)
    // console.log ("...PassingStats loaded cnt="+PassingStats.length)

    SetPassingStatsRetrieved(true)
}

export function LoadRushingStats(data)
{
    RushingStats = []
    for (let x of data)
        RushingStats.push(x)
    // console.log ("...RushingStats loaded cnt="+RushingStats.length)

    SetRushingStatsRetrieved(true)

}

export function LoadReceivingStats(data)
{
    ReceivingStats = []
    for (let x of data)
        ReceivingStats.push(x)
    // console.log ("...ReceivingStats loaded cnt="+ReceivingStats.length)
    SetReceivingStatsRetrieved(true)

}
export function LoadDefensiveStats(data)
{
    DefensiveStats = []
    for (let x of data)
        DefensiveStats.push(x)
    // console.log ("...DefensiveStats loaded cnt="+DefensiveStats.length)

    SetDefensiveStatsRetrieved(true)

}

// export function LoadGamePassingStats(data)
// {
//     GamePassingStats = []
//     for (let x of data)
//         GamePassingStats.push(x)
//     console.log ("...GamePassingStats loaded cnt="+GamePassingStats.length)

//     SetPassingStatsRetrieved(true)
// }

// export function LoadGameRushingStats(data)
// {
//     GameRushingStats = []
//     for (let x of data)
//         GameRushingStats.push(x)
//     console.log ("...GameRushingStats loaded cnt="+GameRushingStats.length)

//     SetRushingStatsRetrieved(true)

// }

// export function LoadGameReceivingStats(data)
// {
//     GameReceivingStats = []
//     for (let x of data)
//         GameReceivingStats.push(x)
//     console.log ("...GameReceivingStats loaded cnt="+GameReceivingStats.length)
//     SetReceivingStatsRetrieved(true)

// }
// export function LoadGameDefensiveStats(data)
// {
//     GameDefensiveStats = []
//     for (let x of data)
//         GameDefensiveStats.push(x)
//     console.log ("...GameDefensiveStats loaded cnt="+GameDefensiveStats.length)

//     SetDefensiveStatsRetrieved(true)

// }

// export function LoadPollHistory(data)
// {
//     PollHistory = [];
//     for (let x of data)
//         PollHistory.push(x)
//     console.log ("...PollHistory loaded cnt="+PollHistory.length)
// }

export function ClearDriveData()
{
    DriveData = {"Status"   : "-",
                    "Period"   : "TBD",
                    "Clock"    : "-", 
                    "Poss"     : null,
                    "Down"     : null,
                    "Dist"     : null,
                    "YardLine" : null,
                    "HomeID"   : null,
                    "HomeTeam" : null,
                    "HomeScore": null,
                    "AwayID"   : null,
                    "AwayTeam" : null,
                    "AwayScore": null, 
                    "Plays"    : TempPlays}

}
export function LoadDriveData(data)
{
    // SetDriveTime (GetTime())

    DriveData = {...data};

    if (DriveData.Status === 'Final') {
        DriveData.Quarter = 'FINAL'
    }
    else
        DriveData.Quarter = DriveData.Quarter + 'Q'
}


export function LoadPortal(data)
{
    Portal_In  = [];
    Portal_Out = [];
    for (let x of data)
        if (x.OrigTID === x.TID)
            Portal_Out.push (x)

        else if (x.DestTID === x.TID)
            Portal_In.push (x)

    console.log ("...Portal loaded in="+Portal_In.length+" out="+Portal_Out.length)

}



export function LoadDepthChart(data)
{
    DepthChart = data;
    console.log ("...depth chart loaded")
}

export function LoadFutureOpps(data)
{
    FutureOpps = []
    // for (let x of data)
    //     if (x.ConfGame === false)
    //         FutureOpps.push(x)
    console.log ("...FutureOpps loaded cnt="+FutureOpps.length)
}


export function LoadPlays(data)
{
    var temp = [];
    var q;

    Quarters = []
    Plays = []
    q = data[0].period;
    for (let x of data)
    {
        if (x.period === q)
            temp.push(x)
        else
        {
            Quarters.push (temp)
            temp = [];
            q = x.period;
            temp.push (x)
        }
    }
    Quarters.push (temp)

    console.log ("...Quarters loadad="+Quarters.length)
}

export function LoadRecruits(data)
{
    Recruits = []
    for (let x of data)
        Recruits.push(x)
}

export function LoadCarousel(data)
{
}

// export function LoadHeadlines(data)
// {
//     Headlines = []
//     for (let x of data)
//     {
//         x.Conf = parseInt (x.Conf);
//         x.Team = parseInt (x.Team);
//         Headlines.push(x)
//     }

//     FilterHeadlines()
// }





// export function LoadStandings(data)
// {
//     AllStandings = [];

//     for (let x of data)
//     {
//         console.log (x)
//         AllStandings.push(x)
//     }

//     console.log ("...Standings divs loaded = " + AllStandings.length);


//     // OurRecord = ExtractSpecificRecord(Teams[defaultTeamSelected].Name);
//     // LastGame = FindLastWeeksGame(defaultTeamSelected)
//     // NextGame = FindThisWeeksGame(defaultTeamSelected)

// }

// export function LoadConfStandings(data)
// {
//     ConfStandings = [];

//     for (let x of data)
//     {
//         ConfStandings.push(x)
//     }

//     console.log ("...Standings records loaded = " + ConfStandings.length);

//     DivRecords1 = []
//     DivRecords2 = []

//     for (let record of ConfStandings)
//     {
//         if (record.DivID === 1)
//         {
//             DivRecords1 = record.Records
//         }
//         else if (record.DivID === 2)
//         {
//             DivRecords2 = record.Records
//         }
//     }

//     console.log ("...Divisions 1 = " + DivRecords1.length)
//     console.log ("...Divisions 2 = " + DivRecords2.length)


// }

// export function LoadCCGames(dbGames)
// {
//     CCGames = []
//     for (var i=0; i<dbGames.length; i++)
//     {
//         CCGames.push(dbGames[i])
//     }

//     console.log ("Loaded CC Games="+CCGames.length)
// }


// export function LoadBowlGames(dbGames)
// {
// BowlGames = []
// for (var i=0; i<dbGames.length; i++)
// {
//     BowlGames.push(dbGames[i])
// }
// }

var gamesHook;
var depthHook;
var nextOpponentHook
var scheduleHook;
var resultsHook;
var teamsHook;
var rankingsHook;
var headlinesHook;
var passingStatsHook;
var rushingStatsHook;
var receivingStatsHook;
var defensiveStatsHook;
var pollHistoryHook;
var rosterHook;
var recruitsHook;
var futureHook;
var portalHook;
var driveHook;
var driveTimeHook;
var playsHook;
var highlightHook;

export function SetPlaysHook (setPlaysRetrieved){playsHook=setPlaysRetrieved;}
export function SetHighlightHook (setHighlightRetrieved){highlightHook=setHighlightRetrieved;}

export function LoadCommonHooks    (setRankingsRetrieved,
                                    setPassingStatsRetrieved,
                                    setRushingStatsRetrieved,
                                    setReceivingStatsRetrieved,
                                    setDefensiveStatsRetrieved,
                                    setGamesRetrieved,
                                    setResultsRetrieved)
{
    gamesHook = setGamesRetrieved;
    rankingsHook = setRankingsRetrieved;
    passingStatsHook = setPassingStatsRetrieved; 
    rushingStatsHook = setRushingStatsRetrieved; 
    receivingStatsHook = setReceivingStatsRetrieved;
    defensiveStatsHook = setDefensiveStatsRetrieved;
    resultsHook = setResultsRetrieved;
}

export function LoadFBSHooks   ()
{
}

export function LoadConfHooks ( setTeamsRetrieved  )
{
    teamsHook = setTeamsRetrieved; 
}

export function LoadTeamHooks ( setRosterRetrieved,
                                setScheduleRetrieved,
                                setNextOpponentRetrieved,
                                setDepthChartRetrieved)
{
    rosterHook = setRosterRetrieved; 
    scheduleHook = setScheduleRetrieved;
    nextOpponentHook = setNextOpponentRetrieved
    depthHook = setDepthChartRetrieved
}


export function Dummy() {}

export function SetGamesRetrieved(flag){gamesHook(flag)}
export function SetResultsRetrieved(flag){resultsHook(flag)}
export function SetTeamsRetrieved(flag){teamsHook(flag)}
export function SetRankingsRetrieved(flag){rankingsHook(flag)}
export function SetHeadlinesRetrieved(flag){headlinesHook(flag)}
export function SetPollHistoryRetrieved(flag){pollHistoryHook(flag)}
export function SetPassingStatsRetrieved(flag){passingStatsHook(flag)}
export function SetRushingStatsRetrieved(flag){rushingStatsHook(flag)}
export function SetReceivingStatsRetrieved(flag){receivingStatsHook(flag)}
export function SetDefensiveStatsRetrieved(flag){defensiveStatsHook(flag)}
export function SetFutureRetrieved(flag){futureHook(flag)}
export function SetPortalRetrieved(flag){portalHook(flag)}
export function SetRecruitsRetrieved(flag){recruitsHook(flag)}
export function SetDriveRetrieved(flag){driveHook(flag)}
export function SetDriveTime(flag){driveTimeHook(flag)}
export function SetPlaysRetrieved(flag){playsHook(flag)}
export function SetHighlightRetrieved(flag){highlightHook(flag)}
export function setDepthChartRetrieved(flag){depthHook(flag)}
export function setNextOpponentRetrieved(flag){nextOpponentHook(flag)}
export function setScheduleRetrieved(flag){scheduleHook(flag)}
export function setRosterRetrieved(flag){rosterHook(flag)}



