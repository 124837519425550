import StatTable from './StatTable';
import StatLineNoTeam from './StatLineNoTeam';
import StatLineDefNoTeam from './StatLineDefNoTeam';
import * as Images from '../images'
import React, { useState, useEffect } from "react"
import { SetActiveGame } from '../data';


export default function GameLog({PID, setPopup}) {
  const [gameLog, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(true);

  const closePopup = () => {
      setIsPopupOpen(false);
      setPopup(0)
  }

  useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {
      try {
        const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_GAMELOG&PID="+PID;
        console.log (url)
        const response = await fetch(url);
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
            setData(result);
      } 
      catch (error) {
        setError(error.message);
      } 
      finally {
        setLoading(false);
      }
    };

    if (PID > 0) fetchData();

}, [PID]);  

  if (loading) 
    return (<h2>Loading...</h2>);
  else if (error) 
    return (<h2>Error: {error}</h2>);
  else if (isPopupOpen)
  {
    var appearances = gameLog.appearances;
    var passing     = gameLog.passing;
    var rushing     = gameLog.rushing;
    var receiving   = gameLog.receiving;
    var defensive   = gameLog.defensive;
    var interceptions = gameLog.interceptions;
    var fumbles     = gameLog.fumbles;
    var kicking     = gameLog.kicking;
    var punting     = gameLog.punting;
    var kickreturns = gameLog.kickreturns;
    var puntreturns = gameLog.puntreturns;

    // var imghome=Images.TeamImagePaths[info.HomeTID];
    let fontclass = "ltblue";


    const passingHdr = ["Week", "Opp", "C/A", "Yds", "TDs", "INTs", "QBR"];
    const rushingHdr = ["Week", "Opp", "Rush", "Yds", "Long", "TDs", "Avg", "Fum"];
    const receivingHdr = ["Week", "Opp", "Rec", "Yds", "Long", "TDs", "Avg", "...."];
    const defensiveHdr = ["Week", "Opp", "Tack", "Solo", "TFL", "Sack", "Dfl", "Hur", "TDs"];
    const fumblesHdr = ["Week", "Opp", "Number", "Rec", "Lost"];
    const interceptionsHdr = ["Week", "Opp", "Number", "Yds", "TDs"];
    const kickingHdr = ["Week", "Opp", "Points", "XP", "FG", "Yds", "Long", "Pct"];
    const puntingHdr = ["Week", "Opp", "Number", "Touch", "In_20", "Yds", "Long", "Avg"];
    const kickreturnsHdr = ["Week", "Opp", "Number", "Yds", "Long", "TDs", "Avg"];
    const puntreturnsHdr = ["Week", "Opp", "Number", "Yds", "Long", "TDs", "Avg"];


    
    const passingHeader = passingHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})
    const rushingHeader = rushingHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})
    const receivingHeader = receivingHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})
    const defensiveHeader = defensiveHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})
    const fumblesHeader = fumblesHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})
    const interceptionsHeader = interceptionsHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})
    const kickingHeader = kickingHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})
    const puntingHeader = puntingHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})
    const kickreturnsHeader = kickreturnsHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})
    const puntreturnsHeader = puntreturnsHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})
    
            // var imgaway=Images.TeamImagePaths[info.AwayTID];
    var passingLog=passing.map((data,id)=>{
        let AC=data.Comp+"/"+data.Att;    
        return <Linev1 key={id} week={data.Week} opp={data.OppTID}  number={AC}  yds={data.Yds}  tds={data.TDs}  longest={data.INTs}  avg={data.QBR} other={999}/>})
    var rushingLog=rushing.map((data,id)=>{
        return <Linev1 key={id} week={data.Week} opp={data.OppTID}  number={data.Rushes}  yds={data.Yds}  tds={data.TDs}  longest={data.Longest}  avg={data.Avg} other={data.Fumbles} />})
    var receivingLog=receiving.map((data,id)=>{
        return <Linev1 key={id} week={data.Week} opp={data.OppTID}  number={data.Receptions}  yds={data.Yds}  tds={data.TDs}  longest={data.Longest}  avg={data.Avg} other={''} />})
    var defensiveLog=defensive.map((data,id)=>{
        return <Linev3 key={id} week={data.Week} opp={data.OppTID} tackles={data.Tackles}  solo={data.Solo}  TFL={data.TFL}  sacks={data.Sacks}  deflections={data.Deflections} hurries={data.Hurries} TDs={data.TDs} />})
    var interceptionsLog=interceptions.map((data,id)=>{
        return <Linev2 key={id} week={data.Week} opp={data.OppTID}  number={data.Number}  yds={data.Yds}  tds={data.TDs}   />})
    var fumblesLog=fumbles.map((data,id)=>{
        return <Linev2 key={id} week={data.Week} opp={data.OppTID}  number={data.Number}  yds={data.Recovered}  tds={data.Lost}   />})
    var kickingLog=kicking.map((data,id)=>{
        return <Linev1 key={id} week={data.Week} opp={data.OppTID}  number={data.Points}  yds={data.XP}  tds={data.FG}  longest={data.Longest}  avg={data.Pct} other={data.Yds} />})
    var puntingLog=punting.map((data,id)=>{
        return <Linev1 key={id} week={data.Week} opp={data.OppTID}  number={data.Number}  yds={data.Yds}  tds={data.Touchbacks}  longest={data.Longest} other={data.In_20}  avg={data.Avg} />})
    var kickreturnsLog=kickreturns.map((data,id)=>{
        return <Linev1 key={id} week={data.Week} opp={data.OppTID}  number={data.Number}  yds={data.Yds}  tds={data.TDs}  longest={data.Longest} other={''}  avg={data.Avg} />})
    var puntreturnsLog=puntreturns.map((data,id)=>{
        return <Linev1 key={id} week={data.Week} opp={data.OppTID}  number={data.Number}  yds={data.Yds}  tds={data.TDs}  longest={data.Longest} other={''}  avg={data.Avg} />})


    return (
        <div className="popup">
            <button className="close-icon" onClick={closePopup}>&times;</button>
                <br></br>
                {appearances > 0 ?
                <div className="">

                    <h2 className="popup-item">GAME LOGS</h2>
                    <div className="popup-container">
                        {passing.length > 0 ? 
                        <>
                        <LogTablev1 placement={"popup-item "} layout="table-navy three-quarter-width" fontclass={fontclass} label={"PASSING"} hdrs={passingHeader} rows={passingLog} />
                        </>
                        : <></>}
                    </div>
                    <div className="popup-container">
                        {rushing.length > 0 ? 
                        <>
                        <LogTablev1 placement={"popup-item "} layout="table-navy three-quarter-width" fontclass={fontclass} label={"RUSHING"} hdrs={rushingHeader} rows={rushingLog} />
                        </>
                        : <></>}
                    </div>
                    <div className="popup-container">
                        {receiving.length > 0 ? 
                        <>
                        <LogTablev1 placement={"popup-item "} layout="table-navy three-quarter-width" fontclass={fontclass} label={"RECEIVING"} hdrs={receivingHeader} rows={receivingLog} />
                        </>
                        : <></>}
                    </div>
                    <div className="popup-container">
                        {defensive.length > 0 ? 
                        <>
                        <LogTablev3 placement={"popup-item "} layout="table-navy three-quarter-width" fontclass={fontclass} label={"DEFENSIVE"} hdrs={defensiveHeader} rows={defensiveLog} />
                        </>
                        : <></>}
                    </div>
                    <div className="popup-container">
                        {interceptions.length > 0 ? 
                        <>
                        <LogTablev2 placement={"popup-item "} layout="table-navy three-quarter-width" fontclass={fontclass} label={"INTERCEPTIONS"} hdrs={interceptionsHeader} rows={interceptionsLog} />
                        </>
                        : <></>}
                    </div>
                    <div className="popup-container">
                        {fumbles.length > 0 ? 
                        <>
                        <LogTablev2 placement={"popup-item "} layout="table-navy three-quarter-width" fontclass={fontclass} label={"FUMBLES"} hdrs={fumblesHeader} rows={fumblesLog} />
                        </>
                        : <></>}
                    </div>
                    <div className="popup-container">
                        {kickreturns.length > 0 ? 
                        <>
                        <LogTablev1 placement={"popup-item "} layout="table-navy three-quarter-width" fontclass={fontclass} label={"KICK RETURNS"} hdrs={kickreturnsHeader} rows={kickreturnsLog} />
                        </>
                        : <></>}
                    </div>
                    <div className="popup-container">
                        {puntreturns.length > 0 ? 
                        <>
                        <LogTablev1 placement={"popup-item "} layout="table-navy three-quarter-width" fontclass={fontclass} label={"PUNT RETURNS"} hdrs={puntreturnsHeader} rows={puntreturnsLog} />
                        </>
                        : <></>}
                    </div>
                    <div className="popup-container">
                        {kicking.length > 0 ? 
                        <>
                        <LogTablev1 placement={"popup-item "} layout="table-navy three-quarter-width" fontclass={fontclass} label={"KICKING"} hdrs={kickingHeader} rows={kickingLog} />
                        </>
                        : <></>}
                    </div>
                    <div className="popup-container">
                        {punting.length > 0 ? 
                        <>
                        <LogTablev1 placement={"popup-item "} layout="table-navy three-quarter-width" fontclass={fontclass} label={"PUNTING"} hdrs={puntingHeader} rows={puntingLog} />
                        </>
                        : <></>}

                    </div>
                </div>
                :
                <h2 className="popup-item ">NO GAME APPEARANCES YET</h2>
                }

        </div>
        );
    }
} ;

function KhakiHeader({field})
{
    return (
        <th style={{textAlign: 'center', color: 'khaki'}}>{field}</th>         
    ) 
}

function Linev1({ week, opp, number, yds, tds, longest, avg, other })
{
    var imgopp=Images.TeamImagePaths[opp];
    return (<tr><td>{week}</td><td><img className='icon-xs' src={imgopp} alt=""></img></td><td>{number}</td><td>{yds}</td><td>{tds}</td><td>{longest}</td><td>{avg}</td><td>{other}</td></tr>)
}

function Linev2({ week, opp, number, yds, tds})
{
    var imgopp=Images.TeamImagePaths[opp];
    return (<tr><td>{week}</td><td><img className='icon-xs' src={imgopp} alt=""></img></td><td>{number}</td><td>{yds}</td><td>{tds}</td></tr>)
}

function Linev3({ week, opp, tackles, solo, TFL, sacks, deflections, hurries, TDs })
{
    var imgopp=Images.TeamImagePaths[opp];
    return (<tr><td>{week}</td><td><img className='icon-xs' src={imgopp} alt=""></img></td><td>{tackles}</td><td>{solo}</td><td>{TFL}</td><td>{sacks}</td><td>{deflections}</td><td>{hurries}</td><td>{TDs}</td></tr>)
}

function LogTablev1 ({placement, layout, fontclass, label, hdrs, rows})
{
    return (
        <div className={placement}>
            <h2 className={fontclass}>{label}</h2>
            <table className={layout}>
            <colgroup>
            <col style={{ width: '15%', textAlign: 'right' }} />
            <col style={{ width: '15%', textAlign: 'right' }} />
            <col style={{ width: '15%', textAlign: 'right' }} />
            <col style={{ width: '15%', textAlign: 'right' }} />
            <col style={{ width: '15%', textAlign: 'right' }} />
            <col style={{ width: '15%', textAlign: 'right' }} />
            <col style={{ width: '15%', textAlign: 'right' }} />
            <col style={{ width: '15%', textAlign: 'right' }} />
            </colgroup>
            <tbody style={{'height': '15vh'}}>
                <tr>{hdrs}</tr>
                {rows}
            </tbody>
            </table>
        </div>
    )
}


function LogTablev2 ({placement, layout, fontclass, label, hdrs, rows})
{
    return (
        <div className={placement}>
            <h2 className={fontclass}>{label}</h2>
            <table className={layout}>
            <colgroup>
            <col style={{ width: '15%', textAlign: 'right' }} />
            <col style={{ width: '15%', textAlign: 'right' }} />
            <col style={{ width: '15%', textAlign: 'right' }} />
            <col style={{ width: '15%', textAlign: 'right' }} />
            <col style={{ width: '15%', textAlign: 'right' }} />
            <col style={{ width: '15%', textAlign: 'right' }} />
            <col style={{ width: '15%', textAlign: 'right' }} />
            <col style={{ width: '15%', textAlign: 'right' }} />
            </colgroup>
            <tbody style={{'height': '15vh'}}>
                <tr>{hdrs}</tr>
                {rows}
            </tbody>
            </table>
        </div>
    )
}


function LogTablev3 ({placement, layout, fontclass, label, hdrs, rows})
{
    return (
        <div className={placement}>
            <h2 className={fontclass}>{label}</h2>
            <table className={layout}>
            <colgroup>
            <col style={{ width: '10%', textAlign: 'right' }} />
            <col style={{ width: '15%', textAlign: 'right' }} />
            <col style={{ width: '10%', textAlign: 'right' }} />
            <col style={{ width: '10%', textAlign: 'right' }} />
            <col style={{ width: '10%', textAlign: 'right' }} />
            <col style={{ width: '10%', textAlign: 'right' }} />
            <col style={{ width: '10%', textAlign: 'right' }} />
            <col style={{ width: '10%', textAlign: 'right' }} />
            <col style={{ width: '10%', textAlign: 'right' }} />
            </colgroup>
            <tbody style={{'height': '15vh'}}>
                <tr>{hdrs}</tr>
                {rows}
            </tbody>
            </table>
        </div>
    )
}

