import StatLine from "./StatLine";
import StatLineDef from "./StatLineDef";
import React, { useState, useEffect } from "react"

export default function Statistics2({ placement, TID, confID, confLabel, teamLabel})
{
    const [passingData, setPassingData] = useState(null);
    const [rushingData, setRushingData] = useState(null);
    const [receivingData, setReceivingData] = useState(null);
    const [defensiveData, setDefensiveData] = useState(null);
    const [loadingPassing, setLoadingPassing] = useState(true);
    const [loadingRushing, setLoadingRushing] = useState(true);
    const [loadingReceiving, setLoadingReceiving] = useState(true);
    const [loadingDefensive, setLoadingDefensive] = useState(true);
    const [error, setError] = useState(null);
    const [statLevel, setStatLevel] = useState(0)

    let fontclass = "ltblue";

    const ChangeStatLevel = (val) => {
        setStatLevel(val)
      }

      useEffect(() => {
        // Function to fetch data
        const fetchPassingData = async () => {
            try {
              var url;
              if (statLevel === 0)
                  url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_PASSING_LEADERS";
              else if (statLevel === 1)
                  url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_PASSING_LEADERS&Conf="+confID;
              else if (statLevel === 2)
                  url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_PASSING_LEADERS&TID="+TID;
               console.log (url)
              const response = await fetch(url);
          
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const result = await response.json();
              {
                setPassingData(result);
              }
            } 
            catch (error) {
              setError(error.message);
            } 
            finally {
              setLoadingPassing(false);
            }
          };
      
          const fetchRushingData = async () => {
            try {
              var url;
              if (statLevel === 0)
                  url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_RUSHING_LEADERS";
              else if (statLevel === 1)
                  url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_RUSHING_LEADERS&Conf="+confID;
              else if (statLevel === 2)
                  url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_RUSHING_LEADERS&TID="+TID;
              console.log (url)
              const response = await fetch(url);
          
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const result = await response.json();
              {
                setRushingData(result);
              }
            } 
            catch (error) {
              setError(error.message);
            } 
            finally {
              setLoadingRushing(false);
            }
          };
    
          const fetchReceivingData = async () => {
            try {
              var url;
              if (statLevel === 0)
                  url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_RECEIVING_LEADERS";
              else if (statLevel === 1)
                  url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_RECEIVING_LEADERS&Conf="+confID;
              else if (statLevel === 2)
                  url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_RECEIVING_LEADERS&TID="+TID;
              console.log (url)
              const response = await fetch(url);
          
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const result = await response.json();
              {
                setReceivingData(result);
              }
            } 
            catch (error) {
              setError(error.message);
            } 
            finally {
              setLoadingReceiving(false);
            }
          };
    
          const fetchDefensiveData = async () => {
            try {
              var url;
              if (statLevel === 0)
                  url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_DEFENSIVE_LEADERS";
              else if (statLevel === 1)
                  url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_DEFENSIVE_LEADERS&Conf="+confID;
              else if (statLevel === 2)
                  url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_DEFENSIVE_LEADERS&TID="+TID;
              console.log (url)
              const response = await fetch(url);
          
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const result = await response.json();
              {
                setDefensiveData(result);
              }
            } 
            catch (error) {
              setError(error.message);
            } 
            finally {
              setLoadingDefensive(false);
            }
          };

          if (statLevel === 1 && confID === 0) // CONF
          {
              alert("Conf stats require a Conf selection")
              setStatLevel(0);
          }
          else if (statLevel === 2 && TID === 0) // TEAM
          {
              alert("Team stats require a Team selection")
              if (confID > 0)
                  setStatLevel(1);
              else
                  setStatLevel(0);
          }
          else
          {
            fetchPassingData();
            fetchRushingData();
            fetchReceivingData();
            fetchDefensiveData();    
          }
            
    
    }, [TID,confID,statLevel]);        

    if (loadingPassing || loadingRushing || loadingReceiving || loadingDefensive) 
      return (<h2>Loading...</h2>);

    else if (error) 
      return (<h2>Error: {error}</h2>);

    else 
    {
        const statLevelLabel=["FBS","Conference","Team"]

        var passingStats=passingData.map((data,id)=>{
            return <StatLine key={id} statLine={data} />})
        
        var rushingStats=rushingData.map((data,id)=>{
            return <StatLine key={id} statLine={data} />})
        
        var receivingStats=receivingData.map((data,id)=>{
            return <StatLine key={id} statLine={data} />})
        
        var defensiveStats=defensiveData.map((data,id)=>{
            return <StatLineDef key={id} statLine={data} />})
    
        return (
              <>
                <h2 className='khaki'>STATISTICS - {statLevelLabel[statLevel]}</h2>
                <div className="box btn-container">
                    <button className={`${statLevel===0 ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  ChangeStatLevel(0)} >FBS</button>
                    <button className={`${statLevel===1 ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  ChangeStatLevel(1)} >{confLabel}</button>
                    <button className={`${statLevel===2 ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  ChangeStatLevel(2)} >{teamLabel}</button>
                </div>
                <div className={placement}>
                <h2 className={fontclass}>Passing Leaders</h2>
                <table className="table-sm blacktbl">
                <colgroup>
                    <col style={{ width: '40%', textAlign: 'left'}} />
                    <col style={{ width: '30%', textAlign: 'center' }} />
                    <col style={{ width: '10%', textAlign: 'center' }} />
                    <col style={{ width: '20%', textAlign: 'center' }} />
                </colgroup>
                <tbody style={{'height': '15vh'}}>
                    <tr>
                    <th style={{textAlign: 'left', color: 'khaki'}}>Player</th>
                    <th style={{textAlign: 'left', color: 'khaki'}}>Team</th>
                    <th style={{textAlign: 'left', color: 'khaki'}}>TDs</th>
                    <th style={{textAlign: 'left', color: 'khaki'}}>Yds</th>
                    </tr>
                    {passingStats}
                </tbody>
                </table>
                <h2 className={fontclass}>Rushing Leaders</h2>
    
                <table className="table-sm blacktbl">
                <colgroup>
                    <col style={{ width: '40%', textAlign: 'left'}} />
                    <col style={{ width: '30%', textAlign: 'center' }} />
                    <col style={{ width: '10%', textAlign: 'center' }} />
                    <col style={{ width: '20%', textAlign: 'center' }} />
                </colgroup>
                <tbody style={{'height': '15vh'}}>
                    <tr>
                    <th style={{textAlign: 'left', color: 'khaki'}}>Player</th>
                    <th style={{textAlign: 'left', color: 'khaki'}}>Team</th>
                    <th style={{textAlign: 'left', color: 'khaki'}}>TDs</th>
                    <th style={{textAlign: 'left', color: 'khaki'}}>Yds</th>
                    </tr>
                    {rushingStats}
                </tbody>
                </table>
                <h2 className={fontclass}>Receiving Leaders</h2>
                <table className="table-sm blacktbl">
                <colgroup>
                    <col style={{ width: '40%', textAlign: 'left'}} />
                    <col style={{ width: '30%', textAlign: 'center' }} />
                    <col style={{ width: '10%', textAlign: 'center' }} />
                    <col style={{ width: '20%', textAlign: 'center' }} />
                </colgroup>
                <tbody style={{'height': '15vh'}}>
                <tr>
                    <th style={{textAlign: 'left', color: 'khaki'}}>Player</th>
                    <th style={{textAlign: 'left', color: 'khaki'}}>Team</th>
                    <th style={{textAlign: 'left', color: 'khaki'}}>TDs</th>
                    <th style={{textAlign: 'left', color: 'khaki'}}>Yds</th>
                    </tr>
                   {receivingStats}
                </tbody>
                </table>
                <h2 className={fontclass}>Defensive Leaders</h2>
                <table className="table-sm blacktbl">
                <colgroup>
                    <col style={{ width: '40%', textAlign: 'left'}} />
                    <col style={{ width: '30%', textAlign: 'center' }} />
                    <col style={{ width: '10%', textAlign: 'center' }} />
                    <col style={{ width: '20%', textAlign: 'center' }} />
                    <col style={{ width: '10%', textAlign: 'center' }} />
                    <col style={{ width: '20%', textAlign: 'center' }} />
                </colgroup>
                <tbody style={{'height': '15vh'}}>
                <tr>
                    <th style={{textAlign: 'left', color: 'khaki'}}>Player</th>
                    <th style={{textAlign: 'left', color: 'khaki'}}>Team</th>
                    <th style={{textAlign: 'center', color: 'khaki'}}>Tackles</th>
                    <th style={{textAlign: 'center', color: 'khaki'}}>Solo</th>
                    <th style={{textAlign: 'center', color: 'khaki'}}>TFL</th>
                    <th style={{textAlign: 'center', color: 'khaki'}}>Sacks</th>
                    </tr>
                   {defensiveStats}
                </tbody>
                </table>
            </div>
            </>
        )     
    }
}