import Rankings from "./RankingsView";
import Scores from './ScoresView';
import StandingsParent from "./StandingsParent";
import StandingsNew from "./StandingsNew";
import TeamRecord from "./TeamRecord";
import Roster from "./RosterView";
import DepthChartView from "./DepthChartView";
import Updater from "./Updater"

import ScoresComplete from "./ScoresComplete";
import TvSchedule from "./TvScheduleView";
import TeamSchedule from './ScheduleView';
import LiveGameView from "./LiveGameView";
import PlayGameView2 from "./PlayGameView2";
//import GameStatistics2 from "./GameStatistics2";
import GameStatistics3 from "./GameStatistics3";
import Preview2 from "./Preview2";
// import Statistics from "./StatisticsView";
import Statistics2 from "./StatisticsView2";
import StatLine from './StatLine';
import StatLineDef from './StatLineDef';
import GameLog from './GameLog'

import * as GlobalData from '../data'

import {RetrieveStandings,
    RetrieveRankings,
    RetrievePassingStats,
    RetrieveRushingStats,
    RetrieveReceivingStats,
    RetrieveDefensiveStats,
    RetrieveConfPassingStats,
    RetrieveConfRushingStats,
    RetrieveConfReceivingStats,
    RetrieveConfDefensiveStats,
    RetrieveTeamPassingStats,
    RetrieveTeamRushingStats,
    RetrieveTeamReceivingStats,
    RetrieveTeamDefensiveStats,
    RetrieveGamePassingStats,
    RetrieveGameRushingStats,
    RetrieveGameReceivingStats,
    RetrieveGameDefensiveStats,
    RetrieveCurrentScoreboard} from "../dungeonapi"

import {LoadCommonHooks,
        LoadRankings,
        LoadStandings,
        LoadPassingStats,
        LoadRushingStats,
        LoadReceivingStats,
        LoadDefensiveStats,
        LoadGamePassingStats,
        LoadGameRushingStats,
        LoadGameReceivingStats,
        LoadGameDefensiveStats,
        LoadThisWeeksGames,
              } from "../data"

import { MenuChoices } from '../Menu';


import React, { useState, useEffect } from "react"

export default function HandleCommon({parent, yr, wk, activeMenu, setActive, setGID, confID, confLabel, TID, teamLabel, GID})
{
    const [teamName, setTeamName] = useState(false);

    const [popupState, setPopup] = useState(0);
    
    const [passingStatsRetrieved, setPassingStatsRetrieved] = useState(false);
    const [rushingStatsRetrieved, setRushingStatsRetrieved] = useState(false);
    const [receivingStatsRetrieved, setReceivingStatsRetrieved] = useState(false);
    const [defensiveStatsRetrieved, setDefensiveStatsRetrieved] = useState(false);
    const [resultsRetrieved, setResultsRetrieved] = useState(false)  
    const [gamesRetrieved, setGamesRetrieved] = useState(false)  
    const [scoresWeek, setScoresWeek] = useState(1);
    const [scoresLevel, setScoresLevel] = useState(0);
    // const [statLevel, setStatLevel] = useState((TID>0 ? 2 : (confID>0? 1 : 0)));
    const [gameInfo, setGameInfo] = useState(null)

    const [prevConfID, setPrevConfIO] = useState(0);
    const [prevTID, setPrevTID] = useState(0);
    const [PID, setPID] = useState(0);

    
    console.log (parent+" HandleCommon: ActiveMenu="+activeMenu + " ConfID="+confID + " TID="+TID  + " GID="+GID)

    var passingStats=GlobalData.PassingStats.map((data,id)=>{
        return <StatLine key={id} statLine={data} />})
    
    var rushingStats=GlobalData.RushingStats.map((data,id)=>{
        return <StatLine key={id} statLine={data} />})
    
    var receivingStats=GlobalData.ReceivingStats.map((data,id)=>{
        return <StatLine key={id} statLine={data} />})
    
    var defensiveStats=GlobalData.DefensiveStats.map((data,id)=>{
        return <StatLineDef key={id} statLine={data} />})
    
    useEffect(() => { 
        console.log ("HandleCommon initial useEffect ")

        LoadCommonHooks(0,
                        0,
                        0,
                        0,
                        0,
                        setGamesRetrieved,
                        setResultsRetrieved); 
    
                      
        // RetrievePassingStats (LoadPassingStats, setPassingStatsRetrieved)
        // RetrieveRushingStats (LoadRushingStats, setRushingStatsRetrieved)
        // RetrieveReceivingStats (LoadReceivingStats, setReceivingStatsRetrieved)
        // RetrieveDefensiveStats (LoadDefensiveStats, setDefensiveStatsRetrieved)
    
        RetrieveCurrentScoreboard (yr, wk, LoadThisWeeksGames, setGamesRetrieved)
        // if (wk > 0) 
        //       RetrieveCurrentScoreboard (yr, wk-1, LoadLastWeeksGames, setResultsRetrieved)
    
    }, []);    

    // useEffect(() => { 

    //     console.log ("HandleCommon useEffect confID changed "+confID)
  
    //     if (scoresLevel === 2 && TID != prevTID) 
    //     {
    //             RetrieveConfPassingStats(confID, LoadPassingStats, setPassingStatsRetrieved)
    //             RetrieveConfRushingStats (confID, LoadRushingStats, setRushingStatsRetrieved)
    //             RetrieveConfReceivingStats (confID, LoadReceivingStats, setReceivingStatsRetrieved)
    //             RetrieveConfDefensiveStats (confID, LoadDefensiveStats, setDefensiveStatsRetrieved)    
    //             setPrevConfIO(confID)
    //     }
        
    // }, [confID]); 
  
    // useEffect(() => { 

    //     console.log ("HandleCommon useEffect TID changed "+TID)
  
    //     if (scoresLevel === 2 && TID != prevTID) 
    //     {
    //             RetrieveTeamPassingStats(TID, LoadPassingStats, setPassingStatsRetrieved)
    //             RetrieveTeamRushingStats (TID, LoadRushingStats, setRushingStatsRetrieved)
    //             RetrieveTeamReceivingStats (TID, LoadReceivingStats, setReceivingStatsRetrieved)
    //             RetrieveTeamDefensiveStats (TID, LoadDefensiveStats, setDefensiveStatsRetrieved)    
    //             setPrevTID(TID)
    //     }
        
    // }, [TID]); 


    // useEffect(() => { 

    //     console.log ("HandleCommon useEffect statLevel="+statLevel)

    //     if (statLevel === 0) // FBS
    //     {
    //         RetrievePassingStats( LoadPassingStats, setPassingStatsRetrieved)
    //         RetrieveRushingStats ( LoadRushingStats, setRushingStatsRetrieved)
    //         RetrieveReceivingStats ( LoadReceivingStats, setReceivingStatsRetrieved)
    //         RetrieveDefensiveStats ( LoadDefensiveStats, setDefensiveStatsRetrieved)                
    //     }
    //     else if (statLevel === 1 && confID > 0) // CONF
    //     {
    //         RetrieveConfPassingStats(confID, LoadPassingStats, setPassingStatsRetrieved)
    //         RetrieveConfRushingStats (confID, LoadRushingStats, setRushingStatsRetrieved)
    //         RetrieveConfReceivingStats (confID, LoadReceivingStats, setReceivingStatsRetrieved)
    //         RetrieveConfDefensiveStats (confID, LoadDefensiveStats, setDefensiveStatsRetrieved)    
    //         setStatLevel(1);
    //         setPrevConfIO(confID);
    //     }
    //     else if (statLevel === 2 && TID > 0) // TEAM
    //     {
    //         RetrieveTeamPassingStats(TID, LoadPassingStats, setPassingStatsRetrieved)
    //         RetrieveTeamRushingStats (TID, LoadRushingStats, setRushingStatsRetrieved)
    //         RetrieveTeamReceivingStats (TID, LoadReceivingStats, setReceivingStatsRetrieved)
    //         RetrieveTeamDefensiveStats (TID, LoadDefensiveStats, setDefensiveStatsRetrieved)    
    //         setStatLevel(2);
    //         setPrevTID(TID)
    //     }
    //     else if (statLevel === 1 && confID === 0) // CONF
    //     {
    //         alert("Conf stats require a Conf selection")
    //         setStatLevel(0);
    //     }
    //     else if (statLevel === 2 && TID === 0) // TEAM
    //     {
    //         alert("Team stats require a Team selection")
    //         if (confID > 0)
    //             setStatLevel(1);
    //         else
    //             setStatLevel(0);
    //     }
        
    // }, [statLevel]);    


    
    useEffect(() => { 

      console.log ("HandleCommon useEffect "+scoresLevel)

      if (scoresLevel === 1 && confID > 0) // CONF
      {

      }
      else if (scoresLevel === 1 && confID === 0) // CONF
      {
        alert("Conf scores require a Conf selection")
        setScoresLevel(0)
      }
      
  }, [scoresLevel]); 

  useEffect(() => { 
        console.log ("HandleCommon useEffect GID changed "+GID)
    
}, [GID]); 


  return (
        <>
            <Updater week       = {GlobalData.currWeek}
            />

            {activeMenu===MenuChoices.RANKINGS ? 
                <Rankings 
                             placement={"three-quarter-width scroll"} 
                             label="AP POLL - WEEK "
                             year={GlobalData.currYear}
                             week={GlobalData.currWeek} 
                             />
                         : <></>}

            {activeMenu===MenuChoices.SCORES  ?
                <Scores  
                             placement={"three-quarter-width scroll"}
                             label="FBS" 
                             confID={confID} 
                             confLabel={confLabel}
                             scoresWeek={scoresWeek}
                             setScoresWeek={setScoresWeek}
                             scoresLevel={scoresLevel}
                             setScoresLevel={setScoresLevel}
                             gamesRetrieved={gamesRetrieved} 
                             activeMenu={activeMenu}
                             setActive={setActive}
                             setPopup={setPopup}
                             setGID={setGID}
                             setGameInfo={setGameInfo}
                             setTeamName={setTeamName}
                             setPassingStatsRetrieved = {setPassingStatsRetrieved}
                             setRushingStatsRetrieved = {setRushingStatsRetrieved}
                             setReceivingStatsRetrieved = {setReceivingStatsRetrieved}
                             setDefensiveStatsRetrieved = {setDefensiveStatsRetrieved}
                              />
                        : <></> }


            {activeMenu===MenuChoices.LAST_WEEK  ? 
                <ScoresComplete  
                            label={"FBS"}
                            confID={confID} 
                            lastWeek={GlobalData.currWeek - 1}
                            confLabel={confLabel}
                            scoresLevel={scoresLevel}
                            setScoresLevel={setScoresLevel}
                            activeMenu={activeMenu}
                            setActive={setActive}
                            setPopup={setPopup}
                            setGID={setGID}
                            setGameInfo={setGameInfo}
                            gamesRetrieved={gamesRetrieved} 
                            />
                    : <></> }
            {activeMenu===MenuChoices.STATS ?
                <Statistics2 
                    placement={"three-quarter-width scroll"}
                    TID    ={TID}
                    confID = {confID}
                    confLabel = {confLabel} 
                    teamLabel={teamLabel}
                    />
                : <></> } 

            {activeMenu===MenuChoices.TV ? 
                <TvSchedule 
                             placement={"box  three-quarter-width scroll"}  
                             gamesRetrieved={gamesRetrieved} 
                             />
                : <></> }        
                         
            {activeMenu===MenuChoices.SCHEDULE ? 
                <div className={"three-quarter-width full-height scroll"}>
                    <TeamSchedule 
                                placement={" "} 
                                setActive={setActive}
                                setPopup={setPopup}
                                setGID={setGID}
                                TID={TID}
                                />
                </div>
                : <></> }

            {activeMenu===MenuChoices.STANDINGS ? 
                <>
                        {confID === 0 ?
                        <StandingsParent 
                                placement={"three-quarter-width scroll"}
                            />
                        :
                        <div className={"three-quarter-width full-height scroll"}>
                        <StandingsNew placement={" "} 
                                    confID={confID} 
                                    confName={GlobalData.ConfNames[confID]}
                            />
                        </div>
                        }
                </>

                : <></> }

            {activeMenu===MenuChoices.ROSTER  ? 
                <Roster 
                                    placement={"three-quarter-width scroll"}  
                                    label="ROSTER"
                                    TID={TID}
                                    />
                        : <></> }
            {activeMenu===MenuChoices.DEPTH  ? 
                <DepthChartView 
                                    placement={"three-quarter-width scroll"} 
                                    TID={TID} 
                                    setPID={setPID}
                                    setPopup={setPopup}

                                    />
                        : <></> }
            {popupState===MenuChoices.LIVE  ? 
                <LiveGameView 
                            placement="box-container"
                            setPopup={setPopup}
                            GID={GID}
                            />
                    : <></> }

            {popupState===MenuChoices.PLAYS  ? 
                <PlayGameView2 
                            placement="box-container"
                            setPopup={setPopup}
                            GID={GID}
                            />
                    : <></> }

            {popupState===MenuChoices.GAME_STATS  ? 
                <GameStatistics3
                            placement={""}
                            label={"GAME STATISTICS"}
                            setPopup={setPopup}
                            GID={GID}
                            gameInfo={gameInfo}
                            />
                    : <></> }

            {popupState===MenuChoices.PREVIEW  ? 
                <Preview2    placement="box-container" 
                        setPopup={setPopup}
                        GID={GID}
                        />
                    : <></> }
            {popupState===MenuChoices.GAMELOG  ? 
                <GameLog
                            placement={""}
                            label={"GAME LOG"}
                            setPopup={setPopup}
                            PID={PID}
                            />
                    : <></> }

        </>
)
}


