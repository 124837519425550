import * as Images from '../images';
import { MenuChoices } from '../Menu';

export default function MenuCommon({justSet, setAndMoveUp})
{  
    return (
        <>
            <div className="btn-item"></div>
            <div className="btn-item">
                <img className='btn-xsq' onClick={() =>  setAndMoveUp("FBS",MenuChoices.HOME)} src={Images.fbs}></img> 
                <h3>FBS</h3>
            </div>
            <div className="btn-item">
                <img className='btn-xsq' onClick={() => justSet(MenuChoices.RANKINGS)} src={Images.AP}></img>
                <h3>AP POLL</h3>
            </div>
            <div className="btn-item">
                <img className='btn-xsq' onClick={() =>  justSet(MenuChoices.SCORES)} src={Images.Scores}></img>
                <h3>SCORES</h3>
            </div>
            <div className="btn-item">
                <img className='btn-xsq' onClick={() =>  justSet(MenuChoices.STATS)} src={Images.Stats}></img>
                <h3>STATS</h3>
            </div>
            <div className="btn-item">
                <img className='btn-xsq' onClick={() =>  justSet(MenuChoices.STANDINGS)} src={Images.Standings}></img>
                <h3>STANDINGS</h3>
            </div>
            <div className="btn-item">
                <img className='btn-xsq' onClick={() =>  justSet(MenuChoices.TV)}src={Images.TV} ></img>
                <h3>TV</h3>
            </div>
        </>
    )
}