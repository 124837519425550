import StandingsNew from './StandingsNew'

export default function StandingsParent ({placement, standingsRetrieved})
{
    return (
        <div className={placement}>
            <StandingsNew placement={" "} confID={1} confName={"SEC"} />
            <StandingsNew placement={" "} confID={2} confName={"Big Ten"} />
            <StandingsNew placement={" "} confID={5} confName={"Big 12"} />
            <StandingsNew placement={" "} confID={3} confName={"ACC"} />
            <StandingsNew placement={" "} confID={6} confName={"American"} />
            <StandingsNew placement={" "} confID={7} confName={"CUSA"} />
            <StandingsNew placement={" "} confID={8} confName={"Mountain West"} />
            <StandingsNew placement={" "} confID={9} confName={"SUNBELT"} />
            <StandingsNew placement={" "} confID={10} confName={"MAC"} />
            <StandingsNew placement={" "} confID={11} confName={"Independents"} />
        </div>
       
    )
}