import { MenuChoices } from '../Menu';
import * as Images from '../images';
import * as GlobalData from '../data';

import React, { useState, useEffect } from "react"

export default function TeamSchedule({ placement, setActive, setPopup, setGID, TID })
{
    const [scheduleData, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


        useEffect(() => {
            // Function to fetch data
            const fetchData = async () => {
              try {
                const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_SCHEDULE&TID="+TID
                console.log (url)
                const response = await fetch(url);
        
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData( LoadSchedule(result));
              } 
              catch (error) {
                setError(error.message);
              } 
              finally {
                setLoading(false);
              }
            };
        
            if (TID > 0) fetchData();
        
        }, [TID]);  
      
    if (loading) 
        return (<h2>Loading...</h2>);
    else if (error) 
        return (<h2>Error: {error}</h2>);
    else
    {
        const schedule=scheduleData.map((data,id)=>{
            let imgpath=(data.Opp==229?Images.blank:Images.TeamImagePaths[data.Opp]);
                return <ScheduleItem key={id} imgpath={imgpath} scheduleItem={data} setPopup={setPopup} setGID={setGID} />
            })
    
        return (
            <div className={placement}>
                <h2>TEAM SCHEDULE</h2>
                <table className="table table-dark table-striped tight-table three-quarter-width">
                <colgroup>
                    <col style={{ width: '10%', textAlign: 'left'}} />
                    <col style={{ width: '20%', textAlign: 'center' }} />
                    <col style={{ width: '10%', textAlign: 'center' }} />
                    <col style={{ width: '20%', textAlign: 'center' }} />
                    <col style={{ width: '20%', textAlign: 'center' }} />
                    <col style={{ width: '20%', textAlign: 'center' }} />
                </colgroup>
                <tbody>
                {schedule}
                </tbody>
                </table>
    
            </div>
        )
        }

}

function ScheduleItem({ imgpath, scheduleItem, setPopup, setGID})
{
    const getPreview = (val, GID) => {
        console.log ("GID="+GID)
        setGID(GID)
        setPopup(val)
        }
        
    const getGameStats = (val, GID) => {
        console.log ("GID="+GID)
        setGID(GID)
        setPopup(val)
        }
          
    return (
            <tr>
            <td>{scheduleItem.Week}</td>
            <td>{scheduleItem.Date}</td>
            <td>{scheduleItem.Vs}</td>
            <td><img className='icon-xs' src={imgpath} alt=""></img></td>
            {scheduleItem.Complete ?
                <td>{scheduleItem.Score} </td>
                :<td>{scheduleItem.Spread}</td>
            }
            {scheduleItem.Complete ?
                <td><button className="ltblueButtonInv" onClick={() =>  getGameStats(MenuChoices.GAME_STATS, scheduleItem.GID)} >STATS</button> </td>
                :<td>{scheduleItem.Bye?"BYE":<button className="ltblueButtonInv" onClick={() =>  getPreview(MenuChoices.PREVIEW, scheduleItem.GID)} >PREVIEW</button>}</td>
            }
            </tr>
    )
}


export function LoadSchedule(dbGames)
{
    const  SchedItemType = {
        GID: 0,
        Week  : 0,
        Date  : "",
        Vs    : "",
        Opp   : 229,
        Score : " "
    }
    
    var Schedule = []
    for (let w=0; w<16; w++)
    {
        Schedule.push(Object.create(SchedItemType));
        Schedule[w].GID = 0;	
        Schedule[w].Date = CalculateDateStr(GlobalData.currYear, w, 6) 
        Schedule[w].Opp = 229;
        Schedule[w].OppConf = 12;
        Schedule[w].Week = w;
        Schedule[w].Bye = true;
    }

    for (var i=0; i<dbGames.length; i++)
    {
        let w = dbGames[i].Week;
        Schedule[w].GID = dbGames[i].GID;	
        Schedule[w].Week = dbGames[i].Week;
        Schedule[w].Date = CalculateDateStr(GlobalData.currYear, dbGames[i].Week, parseInt(dbGames[i].Day)) 
        Schedule[w].Complete = (dbGames[i].Complete === 1)
        if (dbGames[i].AwayTID === dbGames[i].TID)
        {
            Schedule[w].Opp = dbGames[i].HomeTID
            Schedule[w].Bye = (Schedule[w].Opp === 229)
            Schedule[w].OppName = dbGames[i].HomeTeam
            Schedule[w].OppConf = dbGames[i].HomeConf

            Schedule[w].Vs  = "at"
            
            if (dbGames[i].Complete)
            {
                if (dbGames[i].Complete)
                {
                    if (dbGames[i].AwayScore > dbGames[i].HomeScore)
                        Schedule[w].Score = "W " + dbGames[i].AwayScore + "-" + dbGames[i].HomeScore
                    else
                        Schedule[w].Score = "L " + dbGames[i].HomeScore + "-" + dbGames[i].AwayScore
                }
            }
        }
        else if (dbGames[i].HomeTID === dbGames[i].TID)
        {
            Schedule[w].Opp = dbGames[i].AwayTID
            Schedule[w].Bye = (Schedule[w].Opp === 229)
            Schedule[w].OppName = dbGames[i].AwayTeam
            Schedule[w].OppConf = dbGames[i].AwayConf
            Schedule[w].Complete = (dbGames[i].Complete === 1)
            Schedule[w].Vs  = " "
            if (dbGames[i].Complete)
            {
                if (dbGames[i].HomeScore > dbGames[i].AwayScore)
                    Schedule[w].Score = "W " + dbGames[i].HomeScore + "-" + dbGames[i].AwayScore
                else
                    Schedule[w].Score = "L " + dbGames[i].AwayScore + "-" + dbGames[i].HomeScore
            }
        }        
    }
    
    console.log ("...Loaded schedule for team "+ dbGames[0].TID)

    return Schedule;

}    

function CalculateDateStr (yr, wk, dy)
{
    //            2021 2022 2023 2024 2025
    let julianWeeks = [235,234,233,232,231,236];

    let firstMonday = julianWeeks[yr-2021];
     
    let thisDay = firstMonday + wk*7 + dy - 1;

    let obj = JulianToDate (yr, thisDay) // Mon Aug 23  to Sat Aug 28

    let m = obj['mon'];
    let d = obj['day'];
    let str1 = m + " / " + d;
    return str1;
}

function JulianToDate (yr, jdy)
{
    var mon, day;        
    let daysInMonth=[0,31,28,31,30,31,30,31,31,30,31,30,31];

    if (yr % 4 === 0)  daysInMonth[2] = 29; // leap year

    var mjday=0;
    var prev=0;

    for (let i=1; i<=12; i++)
    {
        prev = mjday;
        mjday = mjday + daysInMonth[i];
        if (jdy <= mjday)
        {
            mon = i;
            day = jdy - prev;
            break;
        }
    }

    return {'mon': mon, 'day': day}
}
