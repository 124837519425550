import React, { useState, useEffect } from "react"
import * as GlobalData from '../data';
import * as Images from '../images';

export default function Highlight ({placement, GID, TID, game})
{
    const [highlight, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
      useEffect(() => {
      // Function to fetch data
      const fetchData = async () => {
        try {
          const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_HIGHLIGHT&GID="+GID;
          console.log (url)
          const response = await fetch(url);
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          {
            setData(result);
          }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading(false);
        }
      };
  
      if (GID > 0) fetchData();
  
  }, [GID]);  
  
    if (loading) 
      return (<h2>Loading...</h2>);
    else if (error) 
      return (<h2>Error: {error}</h2>);
    else 
    {
      console.log (highlight)
        var str = ProcessHighlight(game, TID, highlight);

        return (<p className="lefty">{str}</p>)
    }
}


function ProcessHighlight(game, TID, highlight)
{
    var str;

    console.log ("HIGHLIGHT...")

    // return ("TEMP HIGHLIGHT")

    if (game.HomeTID === TID && game.HomeScore > game.AwayScore) // WINNING A HOME GAME
    {
        console.log ("...WINNING A HOME GAME")
        str = game.HomeTeam;

        let p = FindBestPlayer (highlight.Passing, TID);
        if (highlight.Passing[p].Yds > 100)
            str = str + " QB " + highlight.Passing[p].CommonName + " threw for " + highlight.Passing[p].Yds + " yards,"

        let r = FindBestPlayer (highlight.Rushing, TID);
        if (highlight.Rushing[r].Yds > 100)
            str = str + " RB " + highlight.Rushing[r].CommonName + " rushed for " + highlight.Rushing[r].Yds + " yards,"

        let v = FindBestPlayer (highlight.Receiving, TID);
        if (highlight.Receiving[v].Yds > 100 )
           str = str + " WR " + highlight.Receiving[v].CommonName + " gained " + highlight.Receiving[v].Yds + " yards in the air,"

        str = str + " in their home victory against " + game.AwayTeam + " " + game.HomeScore + "-" + game.AwayScore;       
    }
    else if (game.AwayTID === TID && game.AwayScore > game.HomeScore) // WINNING A ROAD GAME
    {
        console.log ("...WINNING A ROAD GAME")
        str = game.AwayTeam;

        let p = FindBestPlayer (highlight.Passing, TID);
        if (highlight.Passing[p].Yds > 100)
            str = str + " QB " + highlight.Passing[p].CommonName + " threw for " + highlight.Passing[p].Yds + " yards,"

        let r = FindBestPlayer (highlight.Rushing, TID);
        if (highlight.Rushing[r].Yds > 100)
            str = str + " RB " + highlight.Rushing[r].CommonName + " rushed for " + highlight.Rushing[r].Yds + " yards,"

        let v = FindBestPlayer (highlight.Receiving, TID);
        if (highlight.Receiving[v].Yds > 100 )
           str = str + " WR " + highlight.Receiving[v].CommonName + " gained " + highlight.Receiving[v].Yds + " yards in the air,"

        str = str + " in their road victory against " + game.HomeTeam + " " + game.AwayScore  + "-" + game.HomeScore;       
    }

    else if (game.HomeTID === TID && game.HomeScore < game.AwayScore) // LOSING A HOME GAME
    {
      console.log ("...LOSING A HOME GAME")
        str = "Despite";

        console.log ("TID="+TID+" ")

        let p = FindBestPlayer (highlight.Passing, TID);
        if (highlight.Passing[p].Yds > 100)
            str = str + " QB " + highlight.Passing[p].CommonName + " throwing for " + highlight.Passing[p].Yds + " yards,"
    
        let r = FindBestPlayer (highlight.Rushing, TID);
        if (highlight.Rushing[r].Yds > 100)
            str = str + " RB " + highlight.Rushing[r].CommonName + " rushing for " + highlight.Rushing[r].Yds + " yards,"
    
        let v = FindBestPlayer (highlight.Receiving, TID);
        if (highlight.Receiving[v].Yds > 100 )
           str = str + " WR " + highlight.Receiving[v].CommonName + " gaining " + highlight.Receiving[v].Yds + " yards in the air,"
    
        if (str === "Despite")
           str = game.HomeTeam + " lost their home game against " + game.AwayTeam + " " + game.AwayScore + "-" + game.HomeScore;       
        else
           str = str + " " + game.HomeTeam + " lost their home game against " + game.AwayTeam + " " + game.AwayScore+ "-" + game.HomeScore;      
        }

    else if (game.AwayTID === TID && game.AwayScore < game.HomeScore) // LOSING A ROAD GAME
    {
      console.log ("...LOSING A ROAD GAME")
        str = "Despite";

        let p = FindBestPlayer (highlight.Passing, TID);
        if (highlight.Passing[p].Yds > 100)
            str = str + " QB " + highlight.Passing[p].CommonName + " throwing for " + highlight.Passing[p].Yds + " yards,"
        
        let r = FindBestPlayer (highlight.Rushing, TID);
        if (highlight.Rushing[r].Yds > 100)
            str = str + " RB " + highlight.Rushing[r].CommonName + " rushing for " + highlight.Rushing[r].Yds + " yards,"
        
        let v = FindBestPlayer (highlight.Receiving, TID);
        if (highlight.Receiving[v].Yds > 100 )
           str = str + " WR " + highlight.Receiving[v].CommonName + " gaining " + highlight.Receiving[v].Yds + " yards in the air,"
        
        if (str === "Despite")
           str = game.AwayTean + " lost their road game against " + game.HomeTeam + " " + game.HomeScore + "-" + game.AwayScore;       
        else
           str = str + " " + game.AwayTean + " lost their road game against " + game.HomeTeam + " " + game.HomeScore + "-" + game.AwayScore;              
    }
    
    return str;

}

function FindBestPlayer (leaders, TID)
{
  var best=0, idx=0;

  for (let i=0; i<leaders.length; i++)
    if (leaders[i].TID === TID && leaders[i].Yds > best)
    {
       idx = i;
       best = leaders[i].Yds;
    }

    return idx;
}