export default function StatLine({ statLine, statLevel, conf, team })
{
    return (<>

            <tr>
            <td>{statLine.FullName}</td> 
            <td>{statLine.Team}</td> 
            <td>{statLine.TDs}</td> 
            <td>{statLine.Yds}</td> 
            </tr>
            </>)
}
