import StatTable from './StatTable';
import StatLineNoTeam from './StatLineNoTeam';
import StatLineDefNoTeam from './StatLineDefNoTeam';
import * as Images from '../images'
import React, { useState, useEffect } from "react"
import { SetActiveGame } from '../data';


function Preview2({GID, setPopup}) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(true);

  const closePopup = () => {
      setIsPopupOpen(false);
      setPopup(0)
  }

  useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {
      try {
        const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_PREVIEW&GID="+GID;
        console.log (url)
        const response = await fetch(url);
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
            setData(result);
      } 
      catch (error) {
        setError(error.message);
      } 
      finally {
        setLoading(false);
      }
    };

    if (GID > 0) fetchData();

}, [GID]);  

  if (loading) 
    return (<h2>Loading...</h2>);
  else if (error) 
    return (<h2>Error: {error}</h2>);
  else if (isPopupOpen)
  {
    var info = data.Info;
    var away = data.Away;
    var home = data.Home;
    var imgaway=Images.TeamImagePaths[info.AwayTID];
    var imghome=Images.TeamImagePaths[info.HomeTID];
    let fontclass = "ltblue";

    var awayPassingStats=away.PassingStats.map((data,id)=>{
        return <StatLineNoTeam key={id} statLine={data} />})
    var awayRushingStats=away.RushingStats.map((data,id)=>{
        return <StatLineNoTeam key={id} statLine={data} />})
    var awayReceivingStats=away.ReceivingStats.map((data,id)=>{
        return <StatLineNoTeam key={id} statLine={data} />})
    var awayDefensiveStats=away.DefensiveStats.map((data,id)=>{
        return <StatLineDefNoTeam key={id} statLine={data} />})

    var homePassingStats=home.PassingStats.map((data,id)=>{
        return <StatLineNoTeam key={id} statLine={data} />})
    var homeRushingStats=home.RushingStats.map((data,id)=>{
        return <StatLineNoTeam key={id} statLine={data} />})
    var homeReceivingStats=home.ReceivingStats.map((data,id)=>{
        return <StatLineNoTeam key={id} statLine={data} />})
    var homeDefensiveStats=home.DefensiveStats.map((data,id)=>{
        return <StatLineDefNoTeam key={id} statLine={data} />})

    const offhdrs = ["Name", "Yds"]
    const defhdrs = ["Name", "Tackles"]
    const offheaders = offhdrs.map((data,id) => { return <KhakiHeader key={id} field={data} />})
    const defheaders = defhdrs.map((data,id) => { return <KhakiHeader key={id} field={data} />})
    
    return (
        <div className="popup-noscroll">
            <button className="close-icon" onClick={closePopup}>&times;</button>
                <br></br>
                <h2 className="popup-item full-width">PREVIEW</h2>
                <div className="popup-container">
                    <div className="popup-item half-width">
                        <img className='icon-sm' src={imgaway} alt=""></img> 
                        <h2>{away.TeamName} {away.SeasonRec}</h2>
                    </div>
                    <div className="popup-item half-width">
                        <img className='icon-sm' src={imghome} alt=""></img>
                        <h2>{home.TeamName} {home.SeasonRec}</h2>
                    </div>
                </div>
                <p className="popup-item full-width">{away.Coach} brings the #{away.Rank} {away.TeamName} to face {home.Coach}'s #{home.Rank} {home.TeamName} .   {away.Star} leads the {away.Nickname} while {home.Star} leads the {home.Nickname}.</p>

                <div className="popup-item-scroll">
                    <div className="popup-container">
                        <StatTable placement={"popup-item half-width"} layout="table-navy" fontclass={fontclass} label={"PASSING"} hdrs={offheaders} rows={awayPassingStats} />
                        <StatTable placement={"popup-item half-width"} layout="table-navy" fontclass={fontclass} label={"PASSING"} hdrs={offheaders} rows={homePassingStats} />
                    </div>

                    <div className="popup-container">
                        <StatTable placement={"popup-item half-width"} layout="table-navy" fontclass={fontclass} label={"RUSHING"} hdrs={offheaders} rows={awayRushingStats} />
                        <StatTable placement={"popup-item half-width"} layout="table-navy" fontclass={fontclass} label={"RUSHING"} hdrs={offheaders} rows={homeRushingStats} />
                    </div>

                    <div className="popup-container">
                        <StatTable placement={"popup-item half-width"} layout="table-navy" fontclass={fontclass} label={"RECEIVING"} hdrs={offheaders} rows={awayReceivingStats} />
                        <StatTable placement={"popup-item half-width"} layout="table-navy" fontclass={fontclass} label={"RECEIVING"} hdrs={offheaders} rows={homeReceivingStats} />
                    </div>

                    <div className="popup-container">
                        <StatTable placement={"popup-item half-width"} layout="table-navy" fontclass={fontclass} label={"DEFENSIVE"} hdrs={defheaders} rows={awayDefensiveStats} />
                        <StatTable placement={"popup-item half-width"} layout="table-navy" fontclass={fontclass} label={"DEFENSIVE"} hdrs={defheaders} rows={homeDefensiveStats} />
                    </div>                
                </div>
        </div>
        );
    }
} ;

function KhakiHeader({field})
{
    return (
        <th style={{textAlign: 'left', color: 'khaki'}}>{field}</th>         
    ) 
}

export default Preview2;