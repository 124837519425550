import ScoresWeekSelector from './ScoresWeekSelector';
import GameInfo from './GameInfo';

import {CountGamesByDay,
        FilterGamesByConf} from '../data';
import * as GlobalData from '../data';
import * as Images from '../images';

import React from "react"

export default function Scores ({label, confID, confLabel, gamesRetrieved, scoresLevel, setScoresLevel, activeMenu, setActive, setPopup, setGID, setGameInfo})
{

const ChangeScoresLevel = (val) => {
  console.log ("ChangeScoresLevel Val="+val)
  setScoresLevel(val)
}

const getPreview = (val, GID) => {

  console.log ("getPreview Val="+val+" GID="+GID)
  setGID(GID)
  setPopup(val)
}
  
const getLive = (val, GID) => {
  console.log ("getLive Val="+val+" GID="+GID)
  setGID(GID)
  setPopup(val)
}

const getPlays = (val, GID)  => {
  console.log ("getPlays Val="+val+" GID="+GID)
  setGID (GID)
  setPopup(val)
}


const getGameStats = (val, GID, gameInfo) => {
  console.log ("getGameStats Val="+val+" GID="+GID)
  setGID(GID)
  setGameInfo (gameInfo)
  setPopup(val)
}


var tueGames = GlobalData.ThisWeeksGames.map((data,id)=>{
    let GID=data.GID;
    let wk=data.Week;
    let awayPath=Images.TeamImagePaths[data.AwayTID];;
    let awayRank=data.AwayRank;
    let awayTeam=data.AwayTeam;
    let homePath=Images.TeamImagePaths[data.HomeTID];;
    let homeTeam=data.HomeTeam;
    let homeRank=data.HomeRank;

    let networkIdx = GlobalData.networkNames.indexOf(data.TV)
    if (networkIdx === -1) networkIdx=GlobalData.networkNames.indexOf("None");
    let network=Images.networkImagePaths[networkIdx];

    return <GameInfo key={id} GID={GID} wk={wk} day={2} awayRank={awayRank} awayPath={awayPath} awayTeam={awayTeam} homeRank={homeRank} homePath={homePath} homeTeam={homeTeam} 
    gameInfo={data} gamesRetrieved={gamesRetrieved} network={network}  
    getPreview={getPreview} getLive={getLive} getPlays={getPlays} getGameStats={getGameStats}/>
  })

var wedGames = GlobalData.ThisWeeksGames.map((data,id)=>{
      let GID=data.GID;
      let wk=data.Week;
      let awayPath=Images.TeamImagePaths[data.AwayTID];;
      let awayRank=data.AwayRank;
      let awayTeam=data.AwayTeam;
      let homePath=Images.TeamImagePaths[data.HomeTID];;
      let homeTeam=data.HomeTeam;
      let homeRank=data.HomeRank;
      let networkIdx = GlobalData.networkNames.indexOf(data.TV)
      if (networkIdx === -1) networkIdx=GlobalData.networkNames.indexOf("None");
      let network=Images.networkImagePaths[networkIdx];

    return <GameInfo key={id} GID={GID} wk={wk} day={3} awayRank={awayRank} awayPath={awayPath} awayTeam={awayTeam} homeRank={homeRank} homePath={homePath} homeTeam={homeTeam} 
    gameInfo={data} gamesRetrieved={gamesRetrieved} network={network}  
    getPreview={getPreview} getLive={getLive} getPlays={getPlays} getGameStats={getGameStats}/>
  })

var thuGames = GlobalData.ThisWeeksGames.map((data,id)=>{
      let GID=data.GID;
      let wk=data.Week;
      let awayPath=Images.TeamImagePaths[data.AwayTID];
      let awayRank=data.AwayRank;
      let awayTeam=data.AwayTeam;
      let homePath=Images.TeamImagePaths[data.HomeTID];;
      let homeTeam=data.HomeTeam;
      let homeRank=data.HomeRank;
      let networkIdx = GlobalData.networkNames.indexOf(data.TV)
      if (networkIdx === -1) networkIdx=GlobalData.networkNames.indexOf("None");
      let network=Images.networkImagePaths[networkIdx];

    return <GameInfo key={id} GID={GID} wk={wk} day={4} awayRank={awayRank} awayPath={awayPath} awayTeam={awayTeam} homeRank={homeRank} homePath={homePath} homeTeam={homeTeam} 
    gameInfo={data} gamesRetrieved={gamesRetrieved} network={network}  
    getPreview={getPreview} getLive={getLive} getPlays={getPlays} getGameStats={getGameStats}/>
  })

var friGames = GlobalData.ThisWeeksGames.map((data,id)=>{
      let GID=data.GID;
      let wk=data.Week;
      let awayPath=Images.TeamImagePaths[data.AwayTID];
      let awayRank=data.AwayRank;
      let awayTeam=data.AwayTeam;
      let homePath=Images.TeamImagePaths[data.HomeTID];;
      let homeTeam=data.HomeTeam;
      let homeRank=data.HomeRank;
      let networkIdx = GlobalData.networkNames.indexOf(data.TV)
      if (networkIdx === -1) networkIdx=GlobalData.networkNames.indexOf("None");
      let network=Images.networkImagePaths[networkIdx];

    return <GameInfo key={id} GID={GID} wk={wk} day={5} awayRank={awayRank} awayPath={awayPath} awayTeam={awayTeam} homeRank={homeRank} homePath={homePath} homeTeam={homeTeam} 
    gameInfo={data} gamesRetrieved={gamesRetrieved} network={network}  
    getPreview={getPreview} getLive={getLive} getPlays={getPlays} getGameStats={getGameStats}/>
  })

var satGames = GlobalData.ThisWeeksGames.map((data,id)=>{
      let GID=data.GID;
      let wk=data.Week;
      let awayPath=Images.TeamImagePaths[data.AwayTID];
      let awayRank=data.AwayRank;
      let awayTeam=data.AwayTeam;
      let homePath=Images.TeamImagePaths[data.HomeTID];;
      let homeTeam=data.HomeTeam;
      let homeRank=data.HomeRank;
      let networkIdx = GlobalData.networkNames.indexOf(data.TV)
      if (networkIdx === -1) networkIdx=GlobalData.networkNames.indexOf("None");
      let network=Images.networkImagePaths[networkIdx];

    return <GameInfo key={id} GID={GID} wk={wk} day={6} awayRank={awayRank} awayPath={awayPath} awayTeam={awayTeam} homeRank={homeRank} homePath={homePath} homeTeam={homeTeam} 
    gameInfo={data} gamesRetrieved={gamesRetrieved} network={network}  
    getPreview={getPreview} getLive={getLive} getPlays={getPlays} getGameStats={getGameStats}/>
  })

var sunGames = GlobalData.ThisWeeksGames.map((data,id)=>{
      let GID=data.GID;
      let wk=data.Week;
      let awayPath=Images.TeamImagePaths[data.AwayTID];
      let awayRank=data.AwayRank;
      let awayTeam=data.AwayTeam;
      let homePath=Images.TeamImagePaths[data.HomeTID];;
      let homeTeam=data.HomeTeam;
      let homeRank=data.HomeRank;
      let networkIdx = GlobalData.networkNames.indexOf(data.TV)
      if (networkIdx === -1) networkIdx=GlobalData.networkNames.indexOf("None");
      let network=Images.networkImagePaths[networkIdx];

    return <GameInfo key={id} GID={GID} wk={wk} day={0} awayRank={awayRank} awayPath={awayPath} awayTeam={awayTeam} homeRank={homeRank} homePath={homePath} homeTeam={homeTeam} 
    gameInfo={data} gamesRetrieved={gamesRetrieved} network={network}  
    getPreview={getPreview} getLive={getLive} getPlays={getPlays} getGameStats={getGameStats}/>
  })

var monGames = GlobalData.ThisWeeksGames.map((data,id)=>{
  let GID=data.GID;
  let wk=data.Week;
  let awayPath=Images.TeamImagePaths[data.AwayTID];
  let awayRank=data.AwayRank;
  let awayTeam=data.AwayTeam;
  let homePath=Images.TeamImagePaths[data.HomeTID];;
  let homeTeam=data.HomeTeam;
  let homeRank=data.HomeRank;
  let networkIdx = GlobalData.networkNames.indexOf(data.TV)
  if (networkIdx === -1) networkIdx=GlobalData.networkNames.indexOf("None");
  let network=Images.networkImagePaths[networkIdx];

    return <GameInfo key={id} GID={GID} wk={wk} day={1} awayRank={awayRank} awayPath={awayPath} awayTeam={awayTeam} homeRank={homeRank} homePath={homePath} homeTeam={homeTeam} 
    gameInfo={data} gamesRetrieved={gamesRetrieved} network={network}  
    getPreview={getPreview} getLive={getLive} getPlays={getPlays} getGameStats={getGameStats}/>
})

var ThisWeeksConfGames = FilterGamesByConf (GlobalData.ThisWeeksGames, confID);

var tueGamesConf = ThisWeeksConfGames.map((data,id)=>{
  let GID=data.GID;
  let wk=data.Week;
  let awayPath=Images.TeamImagePaths[data.AwayTID];;
  let awayRank=data.AwayRank;
  let awayTeam=data.AwayTeam;
  let homePath=Images.TeamImagePaths[data.HomeTID];
  let homeTeam=data.HomeTeam;
  let homeRank=data.HomeRank;

  let networkIdx = GlobalData.networkNames.indexOf(data.TV)
  if (networkIdx === -1) networkIdx=GlobalData.networkNames.indexOf("None");
  let network=Images.networkImagePaths[networkIdx];

    return <GameInfo key={id} GID={GID} wk={wk} day={2} awayRank={awayRank} awayPath={awayPath} awayTeam={awayTeam} homeRank={homeRank} homePath={homePath} homeTeam={homeTeam} 
    gameInfo={data} gamesRetrieved={gamesRetrieved} network={network}  
    getPreview={getPreview} getLive={getLive} getPlays={getPlays} getGameStats={getGameStats}/>
  })
var wedGamesConf = ThisWeeksConfGames.map((data,id)=>{
      let GID=data.GID;
      let wk=data.Week;
      let awayPath=Images.TeamImagePaths[data.AwayTID];
      let awayRank=data.AwayRank;
      let awayTeam=data.AwayTeam;
      let homePath=Images.TeamImagePaths[data.HomeTID];
      let homeTeam=data.HomeTeam;
      let homeRank=data.HomeRank;
      let networkIdx = GlobalData.networkNames.indexOf(data.TV)
      if (networkIdx === -1) networkIdx=GlobalData.networkNames.indexOf("None");
      let network=Images.networkImagePaths[networkIdx];

    return <GameInfo key={id} GID={GID} wk={wk} day={3} awayRank={awayRank} awayPath={awayPath} awayTeam={awayTeam} homeRank={homeRank} homePath={homePath} homeTeam={homeTeam} 
    gameInfo={data} gamesRetrieved={gamesRetrieved} network={network}  
    getPreview={getPreview} getLive={getLive} getPlays={getPlays} getGameStats={getGameStats}/>
  })
var thuGamesConf = ThisWeeksConfGames.map((data,id)=>{
      let GID=data.GID;
      let wk=data.Week;
      let awayPath=Images.TeamImagePaths[data.AwayTID];
      let awayRank=data.AwayRank;
      let awayTeam=data.AwayTeam;
      let homePath=Images.TeamImagePaths[data.HomeTID];
      let homeTeam=data.HomeTeam;
      let homeRank=data.HomeRank;
      let networkIdx = GlobalData.networkNames.indexOf(data.TV)
      if (networkIdx === -1) networkIdx=GlobalData.networkNames.indexOf("None");
      let network=Images.networkImagePaths[networkIdx];

    return <GameInfo key={id} GID={GID} wk={wk} day={4} awayRank={awayRank} awayPath={awayPath} awayTeam={awayTeam} homeRank={homeRank} homePath={homePath} homeTeam={homeTeam} 
    gameInfo={data} gamesRetrieved={gamesRetrieved} network={network}  
    getPreview={getPreview} getLive={getLive} getPlays={getPlays} getGameStats={getGameStats}/>
  })
var friGamesConf = ThisWeeksConfGames.map((data,id)=>{
      let GID=data.GID;
      let wk=data.Week;
      let awayPath=Images.TeamImagePaths[data.AwayTID];
      let awayRank=data.AwayRank;
      let awayTeam=data.AwayTeam;
      let homePath=Images.TeamImagePaths[data.HomeTID];
      let homeTeam=data.HomeTeam;
      let homeRank=data.HomeRank;
      let networkIdx = GlobalData.networkNames.indexOf(data.TV)
      if (networkIdx === -1) networkIdx=GlobalData.networkNames.indexOf("None");
      let network=Images.networkImagePaths[networkIdx];

    return <GameInfo key={id} GID={GID} wk={wk} day={5} awayRank={awayRank} awayPath={awayPath} awayTeam={awayTeam} homeRank={homeRank} homePath={homePath} homeTeam={homeTeam} 
    gameInfo={data} gamesRetrieved={gamesRetrieved} network={network}  
    getPreview={getPreview} getLive={getLive} getPlays={getPlays} getGameStats={getGameStats}/>
  })
var satGamesConf = ThisWeeksConfGames.map((data,id)=>{
      let GID=data.GID;
      let wk=data.Week;
      let awayPath=Images.TeamImagePaths[data.AwayTID];
      let awayRank=data.AwayRank;
      let awayTeam=data.AwayTeam;
      let homePath=Images.TeamImagePaths[data.HomeTID];
      let homeTeam=data.HomeTeam;
      let homeRank=data.HomeRank;
      let networkIdx = GlobalData.networkNames.indexOf(data.TV)
      if (networkIdx === -1) networkIdx=GlobalData.networkNames.indexOf("None");
      let network=Images.networkImagePaths[networkIdx];

    return <GameInfo key={id} GID={GID} wk={wk} day={6} awayRank={awayRank} awayPath={awayPath} awayTeam={awayTeam} homeRank={homeRank} homePath={homePath} homeTeam={homeTeam} 
    gameInfo={data} gamesRetrieved={gamesRetrieved} network={network}  
    getPreview={getPreview} getLive={getLive} getPlays={getPlays} getGameStats={getGameStats}/>
  })
var sunGamesConf = ThisWeeksConfGames.map((data,id)=>{
      let GID=data.GID;
      let wk=data.Week;
      let awayPath=Images.TeamImagePaths[data.AwayTID];
      let awayRank=data.AwayRank;
      let awayTeam=data.AwayTeam;
      let homePath=Images.TeamImagePaths[data.HomeTID];
      let homeTeam=data.HomeTeam;
      let homeRank=data.HomeRank;
      let networkIdx = GlobalData.networkNames.indexOf(data.TV)
      if (networkIdx === -1) networkIdx=GlobalData.networkNames.indexOf("None");
      let network=Images.networkImagePaths[networkIdx];

    return <GameInfo key={id} GID={GID} wk={wk} day={0} awayRank={awayRank} awayPath={awayPath} awayTeam={awayTeam} homeRank={homeRank} homePath={homePath} homeTeam={homeTeam} 
    gameInfo={data} gamesRetrieved={gamesRetrieved} network={network}  
    getPreview={getPreview} getLive={getLive} getPlays={getPlays} getGameStats={getGameStats}/>
  })
var monGamesConf = ThisWeeksConfGames.map((data,id)=>{
  let GID=data.GID;
  let wk=data.Week;
  let awayPath=Images.TeamImagePaths[data.AwayTID];
  let awayRank=data.AwayRank;
  let awayTeam=data.AwayTeam;
  let homePath=Images.TeamImagePaths[data.HomeTID];
  let homeTeam=data.HomeTeam;
  let homeRank=data.HomeRank;
  let networkIdx = GlobalData.networkNames.indexOf(data.TV)
  if (networkIdx === -1) networkIdx=GlobalData.networkNames.indexOf("None");
  let network=Images.networkImagePaths[networkIdx];

    return <GameInfo key={id} GID={GID} wk={wk} day={1} awayRank={awayRank} awayPath={awayPath} awayTeam={awayTeam} homeRank={homeRank} homePath={homePath} homeTeam={homeTeam} 
                     gameInfo={data} gamesRetrieved={gamesRetrieved} network={network}   
                      getPreview={getPreview} getLive={getLive} getPlays={getPlays} getGameStats={getGameStats}/>
})


var tueLen=0, wedLen=0, thuLen=0, friLen=0, satLen=0, sunLen=0, monLen=0;
var tueLenConf=0, wedLenConf=0, thuLenConf=0, friLenConf=0, satLenConf=0, sunLenConf=0, monLenConf=0;

// console.log ("scoresLevel="+scoresLevel)

if (scoresLevel===0)
{
  label = "FBS"
  tueLen = CountGamesByDay(GlobalData.ThisWeeksGames, 2);
  wedLen = CountGamesByDay(GlobalData.ThisWeeksGames, 3);
  thuLen = CountGamesByDay(GlobalData.ThisWeeksGames, 4);
  friLen = CountGamesByDay(GlobalData.ThisWeeksGames, 5);
  satLen = CountGamesByDay(GlobalData.ThisWeeksGames, 6);
  sunLen = CountGamesByDay(GlobalData.ThisWeeksGames, 0);
  monLen = CountGamesByDay(GlobalData.ThisWeeksGames, 1);
}
else if (scoresLevel===1)
{
  tueLenConf = CountGamesByDay(ThisWeeksConfGames, 2);
  wedLenConf = CountGamesByDay(ThisWeeksConfGames, 3);
  thuLenConf = CountGamesByDay(ThisWeeksConfGames, 4);
  friLenConf = CountGamesByDay(ThisWeeksConfGames, 5);
  satLenConf = CountGamesByDay(ThisWeeksConfGames, 6);
  sunLenConf = CountGamesByDay(ThisWeeksConfGames, 0);
  monLenConf = CountGamesByDay(ThisWeeksConfGames, 1);
}

// console.log ("satLen="+satLen+ " satLenConf="+satLenConf)




return (
        <>
          <ScoresWeekSelector  activeMenu={activeMenu} setActive={setActive}  scoresLevel={scoresLevel} ChangeScoresLevel={ChangeScoresLevel} confLabel={confLabel}/>
          <h2>THIS WEEKS GAMES - {label}</h2>
          <div className={"three-quarter-width scroll"}>
          {tueLen>0?<br></br>:<></>}
          {tueLen>0?<h2>{"Tuesday"}</h2>:<></>}
          {tueLen>0?<>{tueGames}</>:<></>}
          {wedLen>0?<br></br>:<></>}
          {wedLen>0?<h2>{"Wednesday"}</h2>:<></>}
          {wedLen>0?<>{wedGames}</>:<></>}
          {thuLen>0?<br></br>:<></>}
          {thuLen>0?<h2>{"Thursday"}</h2>:<></>}
          {thuLen>0?<>{thuGames}</>:<></>}
          {friLen>0?<br></br>:<></>}
          {friLen>0?<h2>{"Friday"}</h2>:<></>}
          {friLen>0?<>{friGames}</>:<></>}
          {satLen>0?<br></br>:<></>}
          {satLen>0?<h2>{"Saturday"}</h2>:<></>}
          {satLen>0?<>{satGames}</>:<></>}
          {sunLen>0?<br></br>:<></>}
          {sunLen>0?<h2>{"Sunday"}</h2>:<></>}
          {sunLen>0?<>{sunGames}</>:<></>}
          {monLen>0?<br></br>:<></>}
          {monLen>0?<h2>{"Monday"}</h2>:<></>}
          {monLen>0?<>{monGames}</>:<></>}

          {tueLenConf>0?<br></br>:<></>}
          {tueLenConf>0?<h2>{"Tuesday"}</h2>:<></>}
          {tueLenConf>0?<>{tueGamesConf}</>:<></>}
          {wedLenConf>0?<br></br>:<></>}
          {wedLenConf>0?<h2>{"Wednesday"}</h2>:<></>}
          {wedLenConf>0?<>{wedGamesConf}</>:<></>}
          {thuLenConf>0?<br></br>:<></>}
          {thuLenConf>0?<h2>{"Thursday"}</h2>:<></>}
          {thuLenConf>0?<>{thuGamesConf}</>:<></>}
          {friLenConf>0?<br></br>:<></>}
          {friLenConf>0?<h2>{"Friday"}</h2>:<></>}
          {friLenConf>0?<>{friGamesConf}</>:<></>}
          {satLenConf>0?<br></br>:<></>}
          {satLenConf>0?<h2>{"Saturday"}</h2>:<></>}
          {satLenConf>0?<>{satGamesConf}</>:<></>}
          {sunLenConf>0?<br></br>:<></>}
          {sunLenConf>0?<h2>{"Sunday"}</h2>:<></>}
          {sunLenConf>0?<>{sunGamesConf}</>:<></>}
          {monLenConf>0?<br></br>:<></>}
          {monLenConf>0?<h2>{"Monday"}</h2>:<></>}
          {monLenConf>0?<>{monGamesConf}</>:<></>}
          
        </div>
      </>
  )
}
