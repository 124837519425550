import * as Images from '../images';

export default function TeamRecord({ teamRecord })
{

    const teamImg = Images.TeamImagePaths[teamRecord.TID];

    return (
    <tr>
    {/* <td><img className='btn-xsq' onClick={() =>  setAndMoveUp(teamRecord.Name)} src={teamImg}></img></td>  */}
    {teamRecord.Rank<=25?<td className='gray righty px10'>{teamRecord.Rank}</td>:<td className='gray righty px10'></td>}        
    <td  style={{textAlign: 'left'}}>{teamRecord.Name}</td> 
    <td>{teamRecord.Conference}</td> 
    <td>{teamRecord.Season}</td>
    </tr>
    )
}//
