import { MdExitToApp } from 'react-icons/md';
import GameInfo from './GameInfo';
import GameInfoComplete from './GameInfoComplete';
import Highlights from './Highlights';

import { FilterGamesByTID } from '../data'
import LastOpponent from './LastOpponentView';
import NextOpponent from './NextOpponentView';
import MiniStat from './MiniStat';
import * as GlobalData from '../data'
import * as Images from '../images'

export default function HomeTeam({placement, TID, teamName, confName, nextOpponent, setActive, setPopup, setTeamName, setGID, setGameInfo, gamesRetrieved, scheduleRetrieved,
    setPassingStatsRetrieved, setRushingStatsRetrieved, setReceivingStatsRetrieved, setDefensiveStatsRetrieved, setDriveRetrieved})
{
    const getPlays = (val, GID)  => {
        setGID(GID)
        setPopup(val)
    }
    
    const getPreview = (val, GID) => {
        setGID(GID)
        setPopup(val)
    }

    const getGameStats = (val, GID, gameInfo) => {
        setGID(GID)
        setGameInfo (gameInfo)
        setPopup(val)
    }
  
    const getLive = (val, GID) => {
        setGID(GID)
        setPopup(val)
      }
            
    var LastWeeksGame = FilterGamesByTID (GlobalData.LastWeeksGames, TID);
    var ThisWeeksGame = FilterGamesByTID (GlobalData.ThisWeeksGames, TID);

    let networkIdx = GlobalData.networkNames.indexOf(ThisWeeksGame.TV)
    if (networkIdx === -1) networkIdx=GlobalData.networkNames.indexOf("None");
    let network=Images.networkImagePaths[networkIdx];

    console.log (ThisWeeksGame)

    return (
        <div className={placement}>
            <h2>{teamName}</h2>
            <h2>.</h2>
            <h2>Last Weeks Game</h2>
            {(LastWeeksGame === -1) ? 
             <h2 className='white'>BYE WEEK</h2>
            :
            <>
            <GameInfoComplete   GID={LastWeeksGame.GID} 
                                wk ={LastWeeksGame.Week} 
                                day={-1} 
                                awayRank={LastWeeksGame.AwayRank} 
                                awayPath={Images.TeamImagePaths[LastWeeksGame.AwayTID]} 
                                awayTeam={LastWeeksGame.AwayTeam} 
                                homeRank={LastWeeksGame.HomeRank} 
                                homePath={Images.TeamImagePaths[LastWeeksGame.HomeTID]} 
                                homeTeam={LastWeeksGame.HomeTeam} 
                                gameInfo={LastWeeksGame} 
                                gamesRetrieved={gamesRetrieved}  
                                network={network} 
                                setActive={LastWeeksGame.setActive} 
                                getPlays={getPlays} 
                                getGameStats={getGameStats}/>

            <h2>Summary</h2>
            <Highlights         game={LastWeeksGame}
                                GID={LastWeeksGame.GID}
                                TID={TID}
                                />

            </>
            }
            <h2>.</h2>

            <h2>This Weeks Game</h2>
             {(ThisWeeksGame === -1)? 
             <h2 className='white'>BYE WEEK</h2>
            :
            <>
            <GameInfo           GID={ThisWeeksGame.GID} 
                                wk ={ThisWeeksGame.Week} 
                                day={-1} 
                                awayRank={ThisWeeksGame.AwayRank} 
                                awayPath={Images.TeamImagePaths[ThisWeeksGame.AwayTID]} 
                                awayTeam={ThisWeeksGame.AwayTeam} 
                                homeRank={ThisWeeksGame.HomeRank} 
                                homePath={Images.TeamImagePaths[ThisWeeksGame.HomeTID]} 
                                homeTeam={ThisWeeksGame.HomeTeam} 
                                gameInfo={ThisWeeksGame} 
                                gamesRetrieved={gamesRetrieved}  
                                network={network} 
                                setActive={ThisWeeksGame.setActive} 
                                getPreview={getPreview} 
                                getLive={getLive} 
                                getPlays={getPlays} 
                                getGameStats={getGameStats}/>

            
            <h2>Synopsis</h2>
            <p className="lefty full-width">{ThisWeeksGame.AwayCoach} brings {ThisWeeksGame.AwayTeam} in to face {ThisWeeksGame.HomeCoach}'s {ThisWeeksGame.HomeTeam} team.   {ThisWeeksGame.AwayStar} leads {ThisWeeksGame.AwayTeam} while {ThisWeeksGame.HomeStar} leads {ThisWeeksGame.HomeTeam}.</p>
            </>
                    
            }                                                                  

        </div>

    )
}