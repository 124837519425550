import * as Images from '../images';
import { MenuChoices } from '../Menu';
export default function GameInfoComplete({GID, wk, day, awayPath, awayRank, awayTeam, homePath, homeRank, homeTeam, gameInfo, network, getPlays, getGameStats })
{

    
    return (
        <table className="blacktbl">
            <colgroup>
            <col style={{ width: '20%', textAlign: 'left'}} />
            <col style={{ width: '3%',  textAlign: 'right'}} />
            <col style={{ width: '30%', textAlign: 'left' }} />
            <col style={{ width: '10%', textAlign: 'right' }} />
            <col style={{ width: '20%', textAlign: 'right' }} />
            <col style={{ width: '20%', textAlign: 'right' }} />
            </colgroup>
            <tbody>
            <tr>
            <td><img className='icon-xs' src={awayPath} alt={""}></img></td>
            {awayRank <= 25?<td className='gray px10'>{awayRank}</td>:<td></td>}
            <td>{awayTeam}</td>
            <td>{(gameInfo.Active || gameInfo.Complete)?gameInfo.AwayScore:"0"}</td> 
            <td style={{color: 'khaki'}}>{(gameInfo.Active || gameInfo.Complete)?gameInfo.Status:''}</td> 
            <td>{(gameInfo.Complete)?<button className='ltblueButtonInv' onClick={() => getPlays(MenuChoices.PLAYS, GID)}>PLAYS</button>:<img className='icon-xs' align='center' src={network} alt=""></img>}</td> 
            </tr>
            <tr>
            <td><img className='icon-xs' src={homePath} alt={""}></img></td> 
            {homeRank <= 25?<td className='gray px10'>{homeRank}</td>:<td></td>}
            <td>{homeTeam}</td>
            <td>{gameInfo.HomeScore}</td> 
            <td></td> 
            <td><button className='khakiButtonInv' onClick={() => getGameStats(MenuChoices.GAME_STATS, GID, gameInfo)}>STATS</button></td> 
            </tr>
            </tbody>
        </table> 
    )
}

