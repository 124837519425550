import MenuCommon from './MenuCommon';
import HandleCommon from './HandleCommon';

import MenuConf from "./MenuConf";
import TeamBar from "./TeamBar"
import TeamRecord from "./TeamRecord";


import {RetrieveConfTeams} from "../dungeonapi"


import {LoadConfHooks,
        LoadConfTeams} from "../data"

import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import * as GlobalData from '../data';
import * as Images from '../images';
import { MenuChoices } from '../Menu';

    

export default function MainConf({ confID, confName, activeMenu, setActive, GID, setGID})
{
    const [teamName, setTeamName] = useState("TEAM");

    const [standingsRetrieved, setStandingsRetrieved] = useState(false);
    const [teamsRetrieved, setTeamsRetrieved] = useState(false);
    const [resultsRetrieved, setResultsRetrieved] = useState(false)  
    const [gamesRetrieved, setGamesRetrieved] = useState(false)  
    const [driveRetrieved, setDriveRetrieved] = useState(false);
    
    const [scoresLevel, setScoresLevel] = useState(1);



    const standings=GlobalData.DivRecords1.map((data,id)=>{
        return <TeamRecord key={id} teamRecord={data}  standingsRetrieved={standingsRetrieved} />
      })
    const standings2=GlobalData.DivRecords2.map((data,id)=>{
        return <TeamRecord key={id} teamRecord={data}  standingsRetrieved={standingsRetrieved}/>
      })
                  
    const navigate = useNavigate();

    const justSet = (val) => {
        setActive(val)
    }
    
    const setAndMoveUp = (nm, val) => {
          navigate("/"+nm)
          setActive(val)
    }


useEffect(() => { 

    LoadConfHooks (setTeamsRetrieved); 

    RetrieveConfTeams (confID, LoadConfTeams, setTeamsRetrieved);	

}, [confID]);    
     

return (
    <div className="App grid-container-10-90">

        <TeamBar            placement ={"left-column"}
                            layout={"box"} 
                            confID={confID} 
                            TID={0}
                            teamsRetrieved={teamsRetrieved}/>


        <div className="right-column">

            <div className="box btn-container">
                <MenuCommon justSet={justSet}  setAndMoveUp={setAndMoveUp} />
            </div>

            <MenuConf />            

            <HandleCommon 
                parent         = {"MainConf"}
                yr             = {GlobalData.currYear}
                wk             = {GlobalData.currWeek}
                activeMenu     = {activeMenu}
                setActive      = {setActive}
                GID            = {GID}
                setGID         = {setGID}
                initStatLevel  = {1} 
                preview        = {GlobalData.Preview}
                confID         = {confID}
                TID            = {0}
                confLabel      = {confName}
                teamLabel      = {"TEAM"}

            />

            {activeMenu===MenuChoices.HOME ? 
                    <>
                    <div className="content-row2">
                        <img className='btn-xsq'  src={Images.hint1}></img>
                        <h2>CHOOSE FROM CONF MENU</h2>
                        <br></br>
                        <h2>OR</h2>
                        <br></br>
                        <div className='box btn-container'>
                        <img className='btn-xsq' src={Images.hint2}></img>
                        <h2>SELECT A TEAM</h2>
                        <div className="btn-item"></div>
                        <div className="btn-item"></div>
                        </div>
                    </div>
                    </>
                    : <></> }
        </div>
    </div>
    )
}