import * as Images from '../images';

export default function HomeFBS({placement, week, day})
{
    return (
        <>
        <div className={placement}>
            <img className='btn-xsq'  src={Images.hint1}></img>
            <h2>CHOOSE FROM FBS MENU</h2>
            <br></br>
            <h2>OR</h2>
            <br></br>
            <div className='box btn-container'>
            <img className='btn-xsq' src={Images.hint2}></img>
            <h2>SELECT A CONFERENCE</h2>
            <div className="btn-item"></div>
            <div className="btn-item"></div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <h2>WEEK {week} </h2>
            <h2>DAY {day}</h2>
        </div>
        </>

    )
}