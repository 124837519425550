// dungeonapi.js

function Post(str)
{
    var xmlhttp = new XMLHttpRequest();

    console.log (str)
    xmlhttp.open("GET", str, true);
    xmlhttp.send();    
}


function PostAndReceive(str, ProcessData, setRetrieved)
{
    var xmlhttp = new XMLHttpRequest();

    xmlhttp.onreadystatechange = function() 
	{
		if (this.readyState === 4 && this.status === 200) 
		{
			let dbdata = JSON.parse(this.responseText);
            //  console.log ("recvd Transactions = ", dbdata.length)
            ProcessData(dbdata)
            setRetrieved(true)
		}
	};

    setRetrieved(false)

    console.log (str)
    xmlhttp.open("GET", str, true);
    xmlhttp.send();    
}

// RETRIEVE ALL GAMES FROM DATABASE
export function RetrieveGames (year, Loadfunc, setRetrievedFunc)
{
	let path = "https://www.cfbdungeon.com/php/" 
	let str = path+ "cfbx.php?Xaction=GET_ALL_GAMES";
	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
}
	
//  RETRIEVE ALL TEAMS 
export function RetrieveTeams (year, Loadfunc, setRetrievedFunc)
{
	let path = "https://www.cfbdungeon.com/php/" 
	let str = path+ "cfbx.php?Xaction=GET_TEAMS";
	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
}
//  RETRIEVE ALL TEAMS 
export function RetrieveConfTeams (confid, Loadfunc, setRetrievedFunc)
{
	let path = "https://www.cfbdungeon.com/php/" 
	let str = path+ "cfbx.php?Xaction=GET_TEAMS&Conf="+confid;
	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
}

export function RetrieveCurrentScoreboard (year, week, Loadfunc, setRetrievedFunc)
{
	let path = "https://www.cfbdungeon.com/php/" 
	let str = path+ "cfbx.php?Xaction=GET_SCOREBOARD&Week="+week;
	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
}

// export function RetrieveSchedule (tid, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_SCHEDULE&TID="+tid;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }

// export function RetrievePreview (gid, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_PREVIEW&GID="+gid;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }

// export function RetrieveNextOpponent (tid, wk, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_NEXT_OPPONENT&TID="+tid+"&Week="+wk;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


//  RETRIEVE ALL SPREADS FOR THIS WEEKS GAMES
// export function RetrieveSpreads (year, week, Loadfunc, setRetrievedFunc)
// {	
// 	let path = "https://www.cfbdungeon.com/rooks/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_SPREADS&Week=" + week+"";
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// //  RETRIEVE ALL TEAMS 
// export function RetrieveSetup (year, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_SETUP";
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }



// //  RETRIEVE STANDINGS 
// export function RetrieveStandings (Loadfunc, setRetrievedFunc )
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_STANDINGS";
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }

// export function RetrieveConfStandings (confID, Loadfunc, setRetrievedFunc )
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_STANDINGS&Conf=" + confID;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }

// // Get the latest power table / poll
// export function RetrieveRankings (year, selectedWeek, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_RANKINGS&Week=" + selectedWeek + "&Year=" + year;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// export function RetrievePassingStats (Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_PASSING_LEADERS";
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }

// export function RetrieveRushingStats (Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_RUSHING_LEADERS";
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// export function RetrieveReceivingStats (Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_RECEIVING_LEADERS";
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// export function RetrieveDefensiveStats (Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_DEFENSIVE_LEADERS";
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// export function RetrieveConfPassingStats (confID, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_PASSING_LEADERS&Conf="+confID;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }

// export function RetrieveConfRushingStats (confID, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_RUSHING_LEADERS&Conf="+confID;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// export function RetrieveConfReceivingStats (confID, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_RECEIVING_LEADERS&Conf="+confID;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// export function RetrieveConfDefensiveStats (confID, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_DEFENSIVE_LEADERS&Conf="+confID;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// export function RetrieveTeamPassingStats (TID, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_PASSING_LEADERS&TID="+TID;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }

// export function RetrieveTeamRushingStats (TID, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_RUSHING_LEADERS&TID="+TID;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// export function RetrieveTeamReceivingStats (TID, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_RECEIVING_LEADERS&TID="+TID;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// export function RetrieveTeamDefensiveStats (TID, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_DEFENSIVE_LEADERS&TID="+TID;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// export function RetrieveGamePassingStats (year, gid, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_PASSING_LEADERS_BY_GAME&GID="+gid;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }

// export function RetrieveGameRushingStats (year, gid, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_RUSHING_LEADERS_BY_GAME&GID="+gid;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// export function RetrieveGameReceivingStats (year, gid, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_RECEIVING_LEADERS_BY_GAME&GID="+gid;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// export function RetrieveGameDefensiveStats (year, gid, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_DEFENSIVE_LEADERS_BY_GAME&GID="+gid;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// export function RetrieveFutureOpponents (year, team, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_FUTURE_OPPONENTS&TID="+team;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// export function RetrieveHeadlines (year, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_HEADLINES";
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }




// export function RetrieveCoachingCarousel (year, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_COACHING_CHANGES";
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// export function RetrieveRecruits (year, team, Loadfunc, setRetrievedFunc)
// {
// 	// let path = "https://www.cfbdungeon.com/php/" 
// 	// let str = path+ "cfbx.php?Xaction=GET_TEAM_BOARD&TID="+team;
// 	// PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// export function RetrievePollHistory (year, week,  Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_POLL_HISTORY&Week="+week;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// export function RetrieveRoster (year, team,  Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_ROSTER&TID="+team;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// export function RetrieveDepthChart (TID, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_DEPTHCHART&TID="+TID;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }

	
// export function RetrieveActiveDrive (gid,  Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_DRIVE&GID="+gid;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }

// export function RetrievePlays (year, week, team,  Loadfunc, setRetrievedFunc)
// {
// 	console.log ("...RetrievePlays")
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_PLAYS&Year="+year+"&Week="+week+"&Team="+team;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }

// export function RetrieveHighlight (gid,  Loadfunc, setRetrievedFunc)
// {
// 	console.log ("...RetrievePlays")
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_HIGHLIGHT&GID="+gid;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }

// export function RetrieveTransferPortal (year, tid, Loadfunc, setRetrievedFunc)
// {
// 	let path = "https://www.cfbdungeon.com/php/" 
// 	let str = path+ "cfbx.php?Xaction=GET_PORTAL&TID="+tid;
// 	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
// }


// export function SendComment(year,conf,tname,comment)
// {
// 	var str = "php/sendComment.php?Conf="+conf+"&Team="+tname+"&Comment="+comment;
//     Post (str)    
// }

// export function Sign(tid,pid)
// {
//     var str = "https://www.cfbdungeon.com/php/cfbr.php?Xaction=ADD_SIGNING&Date=2021-12-15&PID=" + pid + "&TID=" + tid;
//     Post (str)    
// }

// export function Commit(tid,pid)
// {
//     var str = "https://www.cfbdungeon.com/php/cfbr.php?Xaction=ADD_COMMITMENT&Date=2021-12-15&PID=" + pid + "&TID=" + tid;
//     Post (str)    
// }

// export function UpdateAwayTID(gid, tid)
// {
//     var str = "https://www.cfbdungeon.com/php/cfbr.php?Xaction=UPDATE_GAME_AWAY_TID&GID=" + gid+"&TID=" + tid;
//     Post (str)    
// }
// export function UpdateHomeTID(gid, tid)
// {
//     var str = "https://www.cfbdungeon.com/php/cfbr.php?Xaction=UPDATE_GAME_HOME_TID&GID=" + gid+"&TID=" + tid;
//     Post (str)    
// }
// export function UpdateWeek(gid, week)
// {
//     var str = "https://www.cfbdungeon.com/php/cfby.php?Xaction=UPDATE_GAME_WEEK&GID=" + gid+"&Week=" + week;
//     Post (str)    
// }
// export function UpdateDay(gid, day)
// {
//     var str = "https://www.cfbdungeon.com/php/cfby.php?Xaction=UPDATE_GAME_DAY&GID=" + gid+"&Day=" + day;
//     Post (str)    
// }
// export function UpdateTime(gid, time)
// {
//     var str = "https://www.cfbdungeon.com/php/cfby.php?Xaction=UPDATE_GAME_TIME&GID=" + gid+"&Time=" + time;
//     Post (str)    
// }
// export function UpdateTV(gid, tv)
// {
//     var str = "https://www.cfbdungeon.com/php/cfby.php?Xaction=UPDATE_GAME_TV&GID=" + gid+"&TV=" + tv;
//     Post (str)    
// }

// export function UpdateAwayConf(gid, conf)
// {
//     var str = "https://www.cfbdungeon.com/php/cfby.php?Xaction=UPDATE_GAME_AWAY_CONF&GID=" + gid+"&Conf=" + conf;
//     Post (str)    
// }
// export function UpdateHomeConf(gid, conf)
// {
//     var str = "https://www.cfbdungeon.com/php/cfby.php?Xaction=UPDATE_GAME_HOME_CONF&GID=" + gid+"&Conf=" + conf;
//     Post (str)    
// }
// export function UpdateAwayScore(gid, score)
// {
//     var str = "https://www.cfbdungeon.com/php/cfby.php?Xaction=UPDATE_GAME_AWAY_SCORE&GID=" + gid+"&Score=" + score;
//     Post (str)    
// }
// export function UpdateHomeScore(gid, score)
// {
//     var str = "https://www.cfbdungeon.com/php/cfby.php?Xaction=UPDATE_GAME_HOME_SCORE&GID=" + gid+"&Score=" + score;
//     Post (str)    
// }

// export function UpdateSpread(gid, spread)
// {
//     var str = "https://www.cfbdungeon.com/php/cfby.php?Xaction=UPDATE_GAME_SPREAD&GID=" + gid+"&Spread=" + spread;
//     Post (str)    
// }

// export function UpdateNeutral(gid, flag)
// {
//     var str = "https://www.cfbdungeon.com/php/cfby.php?Xaction=UPDATE_GAME_NEUTRAL&GID=" + gid+"&Flag=" + flag;
//     Post (str)    
// }

// export function UpdateComplete(gid, flag)
// {
//     var str = "https://www.cfbdungeon.com/php/cfby.php?Xaction=UPDATE_GAME_COMPLETE&GID=" + gid+"&Flag=" + flag;
//     Post (str)    
// }

// export function UpdateStatus(gid, status)
// {
//     var str = "https://www.cfbdungeon.com/php/cfby.php?Xaction=UPDATE_GAME_STATUS&GID=" + gid+"&Status=" + status;
//     Post (str)    
// }


