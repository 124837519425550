export default function StatLineDef({ statLine })
{
    return (<>
            <tr>
                <td>{statLine.FullName}</td> 
                <td>{statLine.Team}</td> 
                <td style={{textAlign: 'center', color: 'white'}}>{statLine.Tackles}</td> 
                <td style={{textAlign: 'center', color: 'white'}}>{statLine.Solo}</td> 
                <td style={{textAlign: 'center', color: 'white'}}>{statLine.TFLs}</td> 
                <td style={{textAlign: 'center', color: 'white'}}>{statLine.Sacks}</td> 
            </tr>
            </>)
}
