import Teams from "./teams"

const  GameType = {
	GID          : 0,
	AwayTID      : 0,
	HomeTID      : 0,
	AwayConf     : 0,
	HomeConf     : 0,
	AwayTeam     : " ",
	HomeTeam     : " ",
	AwayCoach     : " ",
	HomeCoach     : " ",
	AwayStar     : " ",
	HomeStar     : " ",
	AwayImgName  : " ",
	HomeImgName  : " ",
	Week         : -1,
	Day          : 6,
	Date         : "xxxxxxx", 
	Spread       : -99, 
	AwayScore    : 0, 
	HomeScore    : 0  , 
	Neutral      : false,
	Confgame     : false,
	Cancelled    : false,
	Complete     : false, 
	Active       : false, 
	Status       : " ",
	TVtime       : " ",
	TV           : 0,
	PassingLdr   : " ",
	PassingYds   : 0,
	PassingTDs   : 0,
	RushingLdr   : " ",
	RushingYds   : 0,
	RushingTDs   : 0,
	ReceivingLdr : " ",
	ReceivingYds : 0,
	ReceivingTDs : 0,

	Initialize : function (GID,AwayTID,HomeTID,AwayTeam,HomeTeam,AwayCoach,HomeCoach,AwayStar,HomeStar,AwayConf,HomeConf,AwayScore,HomeScore, AwayPath,HomePath,Spread,Week,Day,TV,Time,Status, Neutral,Active, Complete)
	{
		this.GID = GID;
		this.AwayTID = AwayTID;
		this.HomeTID = HomeTID;
		this.AwayTeam = AwayTeam;
		this.HomeTeam = HomeTeam;
		this.AwayCoach = AwayCoach;
		this.HomeCoach = HomeCoach;
		this.AwayStar = AwayStar;
		this.HomeStar = HomeStar;
		this.AwayConf = AwayConf;
		this.HomeConf = HomeConf;
		this.AwayScore = AwayScore;
		this.HomeScore = HomeScore;
		this.AwayPath = AwayPath;
		this.HomePath = HomePath;
		this.Spread = Spread;
		this.Week = Week;
		this.Day = Day;
		this.TV = TV;
		this.Time = Time;
		this.Status = Status;
		this.Neutral = Neutral;
		this.Active = Active;
		this.Complete = Complete;
	},
	Basics : function (GID,AwayTID,HomeTID,AwayScore,HomeScore,Week,Day,Active,Complete,Spread)
	{
		this.GID = GID;
		this.AwayTID = AwayTID;
		this.HomeTID = HomeTID;
		this.AwayScore = AwayScore;
		this.HomeScore = HomeScore;
		this.Week = Week;
		this.Day = Day;
		this.Active = Active;
		this.Complete = Complete;
	},
	CompleteGame 			: function() { return this.Complete },
  	IsThisNeutral  			: function () { return this.Neutral },
	IsThisTeamPlaying 		: function(t) { return (t === this.AwayTID || t === this.HomeTID) },
  	IsThisTeamHome 			: function(t) { return (t === this.HomeTID) },
    IsThisTeamAway 			: function(t) { return (t === this.AwayTID) },
	GetTeamScore   			: function(t) {if (this.AwayTID === t) return this.AwayScore; else return this.HomeScore;},
	GetSite        			: function(t) {if (this.HomeTID === t) return ("at"); else return ("vs");},
	GetSpread      			: function(t) { if (this.AwayTID === t) return (this.Spread*-1); else return (this.Spread);}, 
    GetOpponent    			: function(t) { if (this.AwayTID === t) return this.HomeTID; else return this.AwayTID;},
    GetWinningTeam 			: function() { if (this.AwayScore > this.HomeScore) return this.AwayTID; if (this.HomeScore > this.AwayScore) return this.HomeTID; return 0;},
	IsActiveGame   			: function() {return ((this.AwayScore > 0 || this.HomeScore > 0) && this.Complete === false)},
	IsNonFCS       			: function() {return (Teams[this.HomeTID].FBSteam() && Teams[this.AwayTID].FBSteam())},
	// GetSynopsis           	: function() {return ("<p>"+SmartSynopsis(this.GID, this.AwayTID, this.HomeTID, this.Complete)+"</p>")},
	GetPassingLeader      	: function() {return (this.PassingLdr + " threw for " + this.PassingYds + " yds and " + this.PassingTDs + " TDs; ")},
	GetRushingLeader      	: function() {if (this.RushingYds === 0) return " "; else return (this.RushingLdr + " rushed for " + this.RushingYds + " yds and " + this.RushingTDs+ " TDs; ")},
	GetReceivingLeader    	: function() {if (this.ReceivingYds === 0) return " "; else return (this.ReceivingLdr  + " had " + + this.ReceivingYds + " yds receiving and "+ this.ReceivingTDs+ " TDs")},
	LoadGamePassingLeader 	: function(name, t, yds, tds) {if (t === this.GetWinningTeam())
									if (yds > this.PassingYds)
									{
										this.PassingLdr = name;
										this.PassingYds = yds;
										this.PassingTDs = tds;      
								//                  console.log ("Passing GID="+gid+" Name=" + name + " Team=" + t + " Yds=" + yds + " TDs=" + tds);      
									}},
	LoadGameRushingLeader : function(name, t, yds, tds) {if (t === this.GetWinningTeam())
									if (yds > this.RushingYds)
									{
										this.RushingLdr = name;
										this.RushingYds = yds;
										this.RushingTDs = tds;      
								//                  console.log ("Passing GID="+gid+" Name=" + name + " Team=" + t + " Yds=" + yds + " TDs=" + tds);      
									}},
	LoadGameReceivingLeader : function(name, t, yds, tds) {if (t === this.GetWinningTeam())
									if (yds > this.ReceivingYds)
									{
										this.ReceivingLdr = name;
										this.ReceivingYds = yds;
										this.ReceivingTDs = tds;      
							    //              console.log ("Receiving GID="+this.GID+" Name=" + name + " Team=" + t + " Yds=" + yds + " TDs=" + tds);      
									}},
	GetScoreString : function() {if (this.AwayScore > this.HomeScore) return (this.AwayScore + "-" + this.HomeScore);
								   else if (this.HomeScore > this.AwayScore) return (this.HomeScore + "-" + this.AwayScore);
								   else if (this.Cancelled) return ("CANC");
								   else return (" ");}
	};
	
			
export default GameType;