import React, { useState, useEffect } from "react"
import * as GlobalData from '../data';
import * as Images from '../images';


export default function LiveGameView({placement, GID, setPopup})
{
    const [drive, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(true);

    const closePopup = () => {
        setIsPopupOpen(false);
        setPopup(0)
    }
    
    let awayimg = Images.TeamImagePaths[drive.AwayTID];
    let homeimg = Images.TeamImagePaths[drive.HomeTID];

    var plays = drive.Plays.map ((data, id) => {
        let side = (data.YardLine < 0 ? "on opp " : "on own ");
        let ydln = (data.YardLine < 0 ? -1*data.YardLine : data.YardLine);
        let downdist = data.Down + " & " + data.Distance;
        return (
            <PlayViewLOS key={id} time={data.Clock} img={awayimg} downdist={downdist} ptxt={data.PlayText}  ydln={ydln} side={side}/> )


    }) 
    var quarters = GlobalData.Quarters.map((data,id)=>{
    })
  useEffect(() => {
      // Function to fetch data
      const fetchData = async () => {
        try {
          const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_DRIVE&GID="+GID;
          console.log (url)
          const response = await fetch(url);
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          {
            setData(result);
          }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading(false);
        }
      };
  
      if (GID > 0) fetchData();
  
  }, [GID]);  
  
    if (loading) 
      return (<h2>Loading...</h2>);

    else if (error) 
      return (<h2>Error: {error}</h2>);
      
    else 
        return (
            <div className="popup">
                <button className="close-icon" onClick={closePopup}>&times;</button>
                <br></br>
                <h2>{drive.Status}</h2>
                <h2>{drive.Period}Q {drive.Clock}</h2>
                <h2>{drive.AwayTeam} {drive.AwayScore}</h2>
                <h2>{drive.HomeTeam} {drive.HomeScore}</h2>
                <h2>Poss: {drive.Poss}</h2>
                <table>
                <colgroup>
                    <col style={{ width: '6%', textAlign: 'left'}} />
                    <col style={{ width: '6%', textAlign: 'center'}} />
                    <col style={{ width: '10%', textAlign: 'center' }} />
                    <col style={{ width: '20%', textAlign: 'center' }} />
                    <col style={{ width: '40%', textAlign: 'left' }} />
                </colgroup>        
                <tbody>
                    {plays}
                </tbody>
                </table>
            </div>
    
        )
}

function PlayViewLOS({time, img, downdist, ptxt, ydln, side, scoring})
{
    return (
        <tr>
            <td style={{  padding: '2px 0', fontSize: '0.7em' }}>{time}</td>
            <td><img className='icon-tiny' src={img} alt={""}></img></td>
            <td style={{  padding: '2px 0', fontSize: '0.7em' }}>{downdist}</td>
            <td style={{  padding: '2px 0', fontSize: '0.7em' }}>{side}{ydln}</td>
            <td style={{ textAlign: 'left', padding: '2px 0', fontSize: '0.7em' }}>{ptxt}</td>
        </tr>
    )
}
