export const MenuChoices = {
    HOME:       0,
    RANKINGS:   1,
    STANDINGS:  2,
    SCORES:     3,
    STATS:      4,
    SCHEDULE:   5,
    ROSTER:     6,
    TV:         7,
    LAST_WEEK:  8,
    DEPTH:      9,
    PLAYS:      10,
    GAME_STATS: 11,
    LIVE:       12,
    PREVIEW:    13,
    GAMELOG:    14,
    CAROUSEL:   28,
    ADMIN:      29,
    CCG:        30,
    BOWLS:      31,
    HEADLINES:  32,
    FUTURE:     33,
    RECRUITS:   34,
    PORTAL:     35,
    HEAD2HEAD:  36,
    HISTORY:    37,
    FINAL_RANK: 38,
    TEST5:      95
};

