import MenuCommon from './MenuCommon';
import HandleCommon from './HandleCommon';
import MenuFBS from "./MenuFBS";
import HomeFBS from "./HomeFBS";
import ConfBar from "./ConfBar";


import { MenuChoices } from '../Menu';

import React from "react"
import { useNavigate } from "react-router-dom"

import * as GlobalData from '../data';


export default function MainFBS({activeMenu, setActive, GID, setGID})
{
  const justSet = (val) => {
    setActive(val)
  }

  const navigate = useNavigate();

  const setAndMoveUp = (nm, val) => {
    navigate("/"+nm)
    setActive(val)
  }
  

return (
    <>
    <div className="App grid-container-10-90">
      <ConfBar placement="left-column" 
               layout="box" 
               setGID={setGID}/>

      <div className="right-column">

        <div className="box btn-container">
            <MenuCommon justSet={justSet}  setAndMoveUp={setAndMoveUp} />
        </div>

        <MenuFBS />

        <HandleCommon 
                parent         = {"MainFBS"}
                yr             = {GlobalData.currYear}
                wk             = {GlobalData.currWeek}
                activeMenu     = {activeMenu}
                setActive      = {setActive}
                setGID         = {setGID}
                GID            = {GID}
                initStatLevel  = {0} 
                confID         = {0}
                TID            = {0}
                confLabel      = {"CONF"}
                teamLabel      = {"TEAM"}

          />

        {activeMenu===MenuChoices.HOME ? 
            <HomeFBS 
                 placement={"content-row2"} 
                 week={GlobalData.currWeek}
                 day={GlobalData.currDay}
                 />
                    : <></> 
                  }

    </div>
</div>


    </>
  )
}



