import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {InitDates,
        Refresh} from './data';

  InitDates();

  // setInterval(Refresh, 10000)

  const root = ReactDOM.createRoot(document.getElementById('root'));
  // Initial render. Container is implicitly accessed.
  root.render(<App name="CFB Dungeon" />);

  // Subsequent renders. Container is implicitly accessed.
  root.render(<App name="CFB Dungeon" />);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
