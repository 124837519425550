import GameType from "../games"
import ScoresWeekSelector from './ScoresWeekSelector';
import GameInfoComplete from './GameInfoComplete';
import {FilterGamesByConf} from '../data';
import * as GlobalData from '../data';
import * as Images from '../images';

import React, { useState, useEffect } from "react"


//export default function ScoresComplete({label, confID, confLabel, gamesRetrieved, scoresLevel, setScoresLevel, activeMenu, setActive, setPopup, setGID, setGameInfo})
export default function ScoresComplete({label, lastWeek, confID, confLabel, activeMenu, setActive, setPopup, setGID, setGameInfo, scoresLevel, setScoresLevel})
{
    const [lastWeeksGames, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
      // Function to fetch data
      const fetchData = async () => {
        try {
          const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_SCOREBOARD&Week="+lastWeek;
          console.log (url)
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          {
            setData( LoadLastWeeksGames(result));
          }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading(false);
        }
      };
  
      fetchData();
  
    }, []);  


    if (loading) 
        return (<h2>Loading...</h2>);
    else if (error) 
        return (<h2>Error: {error}</h2>);
    else 
    {
        var lastWeeksConfGames = FilterGamesByConf (lastWeeksGames, confID);

        const ChangeScoresLevel = (val) => {
            setScoresLevel(val)
            lastWeeksConfGames = FilterGamesByConf (lastWeeksGames, confID);
        }

        const getPlays = (val, GID)  => {
            setGID (GID)
            setPopup(val)
        }
        
        const getGameStats = (val, GID, gameInfo) => {
            setGID (GID)
            setGameInfo (gameInfo)
            setPopup(val)
        }
        
    
        const lastWeeksScores = lastWeeksGames.map((data,id)=>{
            let GID=data.GID;
            let wk=data.Week;
            let awayPath=Images.TeamImagePaths[data.AwayTID];;
            let awayTeam=data.AwayTeam;
            let awayRank=data.AwayRank;
            let homePath=Images.TeamImagePaths[data.HomeTID];;
            let homeTeam=data.HomeTeam;;
            let homeRank=data.HomeRank;
            let networkIdx=GlobalData.networkNames.indexOf("None");
            let network=Images.networkImagePaths[networkIdx];
            return <GameInfoComplete key={id} GID={GID} wk={wk} day={-1} awayRank={awayRank} awayPath={awayPath} awayTeam={awayTeam} homeRank={homeRank} homePath={homePath} homeTeam={homeTeam} 
                gameInfo={data}  network={network}  getPlays={getPlays} getGameStats={getGameStats}/>
        })

        const lastWeeksConfScores = lastWeeksConfGames.map((data,id)=>{
            let GID=data.GID;
            let wk=data.Week;
            let awayPath=Images.TeamImagePaths[data.AwayTID];;
            let awayTeam=data.AwayTeam;
            let awayRank=data.AwayRank;
            let homePath=Images.TeamImagePaths[data.HomeTID];;
            let homeTeam=data.HomeTeam;;
            let homeRank=data.HomeRank;
            let networkIdx=GlobalData.networkNames.indexOf("None");
            let network=Images.networkImagePaths[networkIdx];
            return <GameInfoComplete key={id} GID={GID} wk={wk} day={-1} awayRank={awayRank} awayPath={awayPath} awayTeam={awayTeam} homeRank={homeRank} homePath={homePath} homeTeam={homeTeam} 
                gameInfo={data}  network={network} getPlays={getPlays} getGameStats={getGameStats}/>
        })



        var allLen=0, allLenConf=0;

        if (scoresLevel===0)
        {
            label = "FBS"
            allLen = lastWeeksGames.length;
            allLenConf=0
        }
        else if (scoresLevel===1)
        {
            allLenConf = lastWeeksConfGames.length;
            allLen=0
        }


        return (
            <>
                <ScoresWeekSelector  activeMenu={activeMenu} setActive={setActive}  scoresLevel={scoresLevel} ChangeScoresLevel={ChangeScoresLevel} confLabel={confLabel}/>

                <h2>LAST WEEKS GAMES - {label}</h2>
                <div className={"three-quarter-width scroll"}>
                    {allLen>0?<>{lastWeeksScores}</>:<></>}
                    {allLenConf>0?<>{lastWeeksConfScores}</>:<></>}            
                </div>
            </>
        )
    }
}

function LoadLastWeeksGames(dbGames)
{
    var list = []
    
    for (var i=0; i<dbGames.length; i++)
    {        
         const Game = Object.create(GameType);	
    
         Game.Initialize   (parseInt (dbGames[i].GID),
                            parseInt (dbGames[i].AwayTID),
                            parseInt (dbGames[i].HomeTID),
                            dbGames[i].AwayTeam,
                            dbGames[i].HomeTeam,
                            dbGames[i].AwayCoach,
                            dbGames[i].HomeCoach,
                            dbGames[i].AwayStar,
                            dbGames[i].HomeStar,
                            parseInt (dbGames[i].AwayConf),
                            parseInt (dbGames[i].HomeConf),
                            parseInt (dbGames[i].AwayScore),
                            parseInt (dbGames[i].HomeScore),
                            dbGames[i].AwayImgName,
                            dbGames[i].HomeImgName,
                            parseFloat (dbGames[i].Spread),
                            parseInt (dbGames[i].Week),
                            parseInt (dbGames[i].Day),
                            dbGames[i].TV,
                            dbGames[i].Time,
                            dbGames[i].Status,
                            dbGames[i].Neutral,
                            dbGames[i].Active,
                            dbGames[i].Complete);
    
        list.push(Game)
    }

    return list;
}
