import MenuCommon from './MenuCommon';
import * as Images from '../images';
import { MenuChoices } from '../Menu';
import {AiOutlineHome}  from 'react-icons/ai';
import * as GlobalData from '../data';

import { useNavigate } from "react-router-dom"

export default function MenuTeam({placement, wk, confID, confName, setActive, justSet})
{

    // console.log ("MenuTeam: confID="+confID+" confName="+confName)

    return (
        <>
        <div className="btn-container-gray bottom-menu">

            <div className="btn-item"></div>
            <div className="btn-item">
                <img className='btn-xsq' onClick={() =>  justSet(MenuChoices.HOME)} src={Images.Home}></img>
                <h3>HOME</h3>
            </div>
            <div className="btn-item">
                <img className='btn-xsq' onClick={() =>  justSet(MenuChoices.SCHEDULE)} src={Images.Schedule}></img>
                <h3>SCHED</h3>
            </div>
            <div className="btn-item">
                <img className='btn-xsq' onClick={() =>  justSet(MenuChoices.ROSTER)} src={Images.Roster}></img>
                <h3>ROSTER</h3>
            </div>
            <div className="btn-item">
                <img className='btn-xsq' onClick={() =>  justSet(MenuChoices.DEPTH)} src={Images.depth}></img>
                <h3>DEPTH</h3>
            </div>
            <div className="btn-item"></div>
        </div>
        </>
    )
}