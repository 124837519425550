import DepthChartRow from "./DepthChartRow"

export default function DepthChartTable({label, obj, setPID, setPopup})
{
    const getGameLog = (val, PID) => {

        console.log ("getGameLog Val="+val+" PID="+PID)
        setPID(PID)
        setPopup(val)
    }

    var rows=obj.map((data,id)=>{
        return <DepthChartRow key={id} pos={data.Position} jersey={data.Jersey} name={data.Name} pid={data.PID} getGameLog={getGameLog}/>
      })

      
    return (
        <>
        <h2>{label} Depth Chart</h2>
        <table className="table table-dark table-striped tight-table full-width" >
            <colgroup>
                <col style={{ width: '10%', textAlign: 'center'}} />
                <col style={{ width: '10%', textAlign: 'right'}} />
                <col style={{ width: '60%', textAlign: 'left'}} />
                <col style={{ width: '10%', textAlign: 'center'}} />
                </colgroup>
           <tbody>
           <tr>
            <th style={{textAlign: 'center', color: 'khaki'}}>POS</th>
            <th style={{textAlign: 'center', color: 'khaki'}}>#</th>
            <th style={{textAlign: 'left', color: 'khaki'}}>NAME</th>
            <th style={{textAlign: 'left', color: 'khaki'}}>LOG</th>
           </tr> 
           {rows}
            </tbody>
            </table>
        </>
    )
}