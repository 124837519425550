export const flat_path=require("./img/trending_flat.webp");
export const up_path=require("./img/trending_up.webp");
export const down_path=require("./img/trending_down.webp");
export const go_back=require("./img/left.webp");
export const depth=require("./img/depth.webp");
export const hint1=require("./img/hint1.png");
export const hint2=require("./img/hint2.png");
export const blank=require("./img/blank.webp");


export const 	fbs = require("./img/NCAA.webp");
export const 	sec = require("./img/sec.webp");
export const 	acc = require("./img/acc.webp");
export const 	b1g = require("./img/b1g.webp");
export const 	xii = require("./img/b12.webp");
export const 	pac = require("./img/pac.webp");
export const 	aac = require("./img/aac.webp");
export const 	mwc = require("./img/mwc.webp");
export const 	cusa = require("./img/cusa.webp");
export const 	sun = require("./img/sun.webp");
export const 	mac = require("./img/mac.webp");
export const 	indy = require("./img/IND.webp");
export const 	fcs = require("./img/fcs.webp");
export const 	g5 = require("./img/g5.webp");

export const abc = require("./img/abc.webp"); 
export const cbs = require("./img/cbs.webp"); 
export const fox = require("./img/fox.webp"); 
export const nbc = require("./img/nbc.webp"); 
export const espn = require("./img/espn.webp"); 
export const espn2 = require("./img/espn2.webp"); 
export const espnu = require("./img/espnu.webp"); 
export const espnplus = require("./img/espnplus.webp"); 
export const stadium = require("./img/stadium.webp"); 
export const none = require("./img/none.webp"); 
export const fs1  = require("./img/fs1.webp"); 
export const fs2  = require("./img/fs2.webp"); 
export const secn = require("./img/secn.webp"); 
export const accn = require("./img/accn.webp"); 
export const pacn = require("./img/pacn.webp"); 
export const cbssn = require("./img/cbssn.webp"); 
export const btn  = require("./img/btn.webp"); 
export const lhn  = require("./img/lhn.webp"); 

export const 	airforce = require("./img/airforce.webp");
export const 	akron = require("./img/akron.webp");
export const 	alabama = require("./img/alabama.webp");
export const 	appstate = require("./img/appstate.webp");
export const 	arizona = require("./img/arizona.webp");
export const 	arizonast = require("./img/arizonast.webp");
export const 	arkansas = require("./img/arkansas.webp");
export const 	arkstate = require("./img/arkstate.webp");
export const 	army = require("./img/army.webp");
export const 	auburn = require("./img/auburn.webp");
export const 	ballst = require("./img/ballst.webp");
export const 	baylor = require("./img/baylor.webp");
export const 	boise = require("./img/boise.webp");
export const 	bc = require("./img/bc.webp");
export const 	bowling = require("./img/bowling.webp");
export const 	buffalo = require("./img/buffalo.webp");
export const 	bye = require("./img/blank.webp");
export const 	byu = require("./img/byu.webp");
export const 	cal = require("./img/cal.webp");
export const 	cmich = require("./img/cmich.webp");
export const 	charlotte = require("./img/charlotte.webp");
export const 	cinci = require("./img/cinci.webp");
export const 	clemson = require("./img/clemson.webp");
export const 	coastalcar = require("./img/coastalcar.webp");
export const 	colorado = require("./img/colorado.webp");
export const 	colost = require("./img/colost.webp");
export const 	duke = require("./img/duke.webp");
export const 	ecu = require("./img/ecu.webp");
export const 	emich = require("./img/emich.webp");
export const 	fau = require("./img/fau.webp");
export const 	fiu = require("./img/fiu.webp");
export const 	florida = require("./img/florida.webp");
export const 	floridast = require("./img/floridast.webp");
export const 	fresno = require("./img/fresno.webp");
export const 	georgia = require("./img/georgia.webp");
export const 	gasouthern = require("./img/gasouthern.webp");
export const 	gastate = require("./img/gastate.webp");
export const 	georgiatech = require("./img/georgiatech.webp");
export const 	hawaii = require("./img/hawaii.webp");
export const 	houston = require("./img/houston.webp");
export const 	illinois = require("./img/illinois.webp");
export const 	indiana = require("./img/indiana.webp");
export const 	iowa = require("./img/iowa.webp");
export const 	iowast = require("./img/iowast.webp");
export const 	jmu = require("./img/jmu.webp");
export const 	kansas = require("./img/kansas.webp");
export const 	kansasst = require("./img/kansasst.webp");
export const 	kentst = require("./img/kentst.webp");
export const 	kentucky = require("./img/kentucky.webp");
export const 	kennst = require("./img/kennesawst.webp");
export const 	liberty = require("./img/liberty.webp");
export const 	ulmonroe = require("./img/ulmonroe.webp");
export const 	latech = require("./img/latech.webp");
export const 	louisville = require("./img/louisville.webp");
export const 	lsu = require("./img/lsu.webp");
export const 	marshall = require("./img/marshall.webp");
export const 	maryland = require("./img/maryland.webp");
export const 	umass = require("./img/umass.webp");
export const 	memphis = require("./img/memphis.webp");
export const 	miami = require("./img/miami.webp");
export const 	miamioh = require("./img/miamioh.webp");
export const 	michigan = require("./img/michigan.webp");
export const 	michiganst = require("./img/michiganst.webp");
export const 	mtsu = require("./img/mtsu.webp");
export const 	minnesota = require("./img/minnesota.webp");
export const 	missst = require("./img/missst.webp");
export const 	missouri = require("./img/missouri.webp");
export const 	navy = require("./img/navy.webp");
export const 	nebraska = require("./img/nebraska.webp");
export const 	nevada = require("./img/nevada.webp");
export const 	newmexico = require("./img/newmexico.webp");
export const 	newmexst = require("./img/newmexst.webp");
export const 	unc = require("./img/unc.webp");
export const 	ncstate = require("./img/ncstate.webp");
export const 	unt = require("./img/unt.webp");
export const 	noillinois = require("./img/noillinois.webp");
export const 	northwestern = require("./img/northwestern.webp");
export const 	notredame = require("./img/notredame.webp");
export const 	ohio = require("./img/ohio.webp");
export const 	ohiost = require("./img/ohiost.webp");
export const 	oklahoma = require("./img/oklahoma.webp");
export const 	oklahomast = require("./img/oklahomast.webp");
export const 	odu = require("./img/odu.webp");
export const 	olemiss = require("./img/olemiss.webp");
export const 	oregon = require("./img/oregon.webp");
export const 	oregonst = require("./img/oregonst.webp");
export const 	pennst = require("./img/pennst.webp");
export const 	pitt = require("./img/pitt.webp");
export const 	purdue = require("./img/purdue.webp");
export const 	rice = require("./img/rice.webp");
export const 	rutgers = require("./img/rutgers.webp");
export const 	sdsu = require("./img/sdsu.webp");
export const 	sanjose = require("./img/sanjose.webp");
export const 	smu = require("./img/smu.webp");
export const 	soalab = require("./img/soalab.webp");
export const 	scarolina = require("./img/scarolina.webp");
export const 	usf = require("./img/usf.webp");
export const 	usc = require("./img/usc.webp");
export const 	somiss = require("./img/somiss.webp");
export const 	stanford = require("./img/stanford.webp");
export const 	syracuse = require("./img/syracuse.webp");
export const 	tcu = require("./img/tcu.webp");
export const 	temple = require("./img/temple.webp");
export const 	tennessee = require("./img/tennessee.webp");
export const 	texas = require("./img/texas.webp");
export const 	texasam = require("./img/texasam.webp");
export const 	texstate = require("./img/texstate.webp");
export const 	texastech = require("./img/texastech.webp");
export const 	toledo = require("./img/toledo.webp");
export const 	troy = require("./img/troy.webp");
export const 	tulane = require("./img/tulane.webp");
export const 	tulsa = require("./img/tulsa.webp");
export const 	uab = require("./img/uab.webp");
export const 	ucf = require("./img/ucf.webp");
export const 	ucla = require("./img/ucla.webp");
export const 	uconn = require("./img/uconn.webp");
export const 	louisiana = require("./img/louisiana.webp");
export const 	unlv = require("./img/unlv.webp");
export const 	utah = require("./img/utah.webp");
export const 	utahst = require("./img/utahst.webp");
export const 	utep = require("./img/utep.webp");
export const 	utsa = require("./img/utsa.webp");
export const 	vandy = require("./img/vandy.webp");
export const 	virginia = require("./img/virginia.webp");
export const 	vatech = require("./img/vatech.webp");
export const 	wakeforest = require("./img/wakeforest.webp");
export const 	washington = require("./img/washington.webp");
export const 	washst = require("./img/washst.webp");
export const 	westva = require("./img/westva.webp");
export const 	wku = require("./img/wku.webp");
export const 	wmich = require("./img/wmich.webp");
export const 	wisconsin = require("./img/wisconsin.webp");
export const 	wyoming = require("./img/wyoming.webp");
export const 	jaxst = require("./img/jaxstate.webp");
export const 	samhoust = require("./img/samhouston.webp");

export const 	Blank = require("./img/blank.webp");
export const 	NCAA = require("./img/NCAA.webp");
export const 	Home = require("./img/Home.webp");
export const 	Standings = require("./img/Standings.png");
export const 	Schedule = require("./img/Schedule.webp");
export const 	AP = require("./img/ap.webp");
export const 	TV = require("./img/TV.webp");
export const 	Twitter = require("./img/Twitter.webp");
export const 	Stats = require("./img/Stats.webp");
export const 	Roster = require("./img/Roster.webp");
export const 	Future = require("./img/wyoming.webp");
export const 	Scores = require("./img/Scores.png");

// export const zero = fcs; // zero.webp");
// export const abchris = fcs; // abchris.webp");
// export const alabam = fcs; // alabam.webp");
// export const alabst = fcs; // alabst.webp");
// export const albany = fcs; // albany.webp");
// export const alcrornst = fcs; // alcrornst.webp");
// export const arkpb = fcs; // arkpb.webp");
// export const auspeay = fcs; // auspeay.webp");
// export const bcu = fcs; // bcu.webp");
// export const campbell = fcs; // campbell.webp");
// export const cenark = fcs; // cenark.webp");
// export const cenconn = fcs; // cenconn.webp");
// export const charso = fcs; // charso.webp");
// export const chatt = fcs; // chatt.webp");
// export const colgate = fcs; // colgate.webp");
// export const delst = fcs; // delst.webp");
// export const duq = fcs; // duq.webp");
// export const etennst = fcs; // etennst.webp");
// export const eill = fcs; // eill.webp");
// export const ekent = fcs; // ekent.webp");
// export const ewash = fcs; // ewash.webp");
// export const elon = fcs; // elon.webp");
// export const famu = fcs; // famu.webp");
// export const forham = fcs; // forham.webp");
// export const furman = fcs; // furman.webp");
// export const grambling = fcs; // grambling.webp");
// export const holycross = fcs; // holycross.webp");
// export const houbap = fcs; // houbap.webp");
// export const howard = fcs; // howard.webp");
// export const idaho = fcs; // idaho.webp");
// export const idahost = fcs; // idahost.webp");
// export const illst = fcs; // illst.webp");
// export const incword = fcs; // incword.webp");
// export const indst = fcs; // indst.webp");
// export const lamar = fcs; // lamar.webp");
// export const lehigh = fcs; // lehigh.webp");
// export const maine = fcs; // maine.webp");
// export const mcneese = fcs; // mcneese.webp");
// export const mercer = fcs; // mercer.webp");
// export const mizzst = fcs; // mizzst.webp");
// export const monmouth = fcs; // monmouth.webp");
// export const morganst = fcs; // morganst.webp");
// export const murrayst = fcs; // murrayst.webp");
// export const newhampshire = fcs; // newhampshire.webp");
// export const nicholls = fcs; // nicholls.webp");
// export const ncat = fcs; // ncat.webp");
// export const nccen = fcs; // nccen.webp");
// export const ndak = fcs; // ndak.webp");
// export const noraz = fcs; // noraz.webp");
// export const noriowa = fcs; // noriowa.webp");
// export const nwst = fcs; // nwst.webp");
// export const portland = fcs; // portland.webp");
// export const prairieview = fcs; // prairieview.webp");
// export const rhode = fcs; // rhode.webp");
// export const richmond = fcs; // richmond.webp");
// export const sacst = fcs; // sacst.webp");
// export const samford = fcs; // samford.webp");
// export const savst = fcs; // savst.webp");
// export const scarst = fcs; // scarst.webp");
// export const sdak = fcs; // sdak.webp");
// export const sdakst = fcs; // sdakst.webp");
// export const semizzst = fcs; // semizzst.webp");
// export const selast = fcs; // selast.webp");
// export const southern = fcs; // southern.webp");
// export const sill = fcs; // sill.webp");
// export const sutah = fcs; // sutah.webp");
// export const sfa = fcs; // sfa.webp");
// export const stonybrook = fcs; // stonybrook.webp");
// export const tennst = fcs; // tennst.webp");
// export const tenntech = fcs; // tenntech.webp");
// export const texsou = fcs; // texsou.webp");
// export const citadel = fcs; // citadel.webp");
// export const towson = fcs; // towson.webp");
// export const ucdavis = fcs; // ucdavis.webp");
// export const utmartin = fcs; // utmartin.webp");
// export const villanova = fcs; // villanova.webp");
// export const vmi = fcs; // vmi.webp");
// export const wagner = fcs; // wagner.webp");
// export const weberst = fcs; // weberst.webp");
// export const wcar = fcs; // wcar.webp");
// export const will = fcs; // will.webp");
// export const willmary = fcs; // willmary.webp");
// export const wofford = fcs; // wofford.webp");
// export const youngstown = fcs; // youngstown.webp");
// export const norfolk = fcs; // norfolk.webp");
// export const bucknell = fcs; // bucknell.webp");
// export const montanast = fcs; // montanast.webp");
// export const gardnerwebb = fcs; // gardnerwebb.webp");
// export const northerncol = fcs; // northerncol.webp");
// export const calpoly = fcs; // calpoly.webp");
// export const hampton = fcs; // hampton.webp");
// export const north_alabama = fcs; // north_alabama.webp");
// export const lafayette = fcs; // lafayette.webp");
// export const liu = fcs; // liu.webp");
// export const FCS_spare_5 = fcs; // FCS_spare_5.webp");
// export const stfrancispa = fcs; // stfrancispa.webp");
// export const montana = fcs; // montana.webp");
// export const Bryant = fcs; // Bryant.webp");
// export const FCS_spare_9 = fcs; // FCS_spare_9.webp");
// export const FCS_spare_10 = fcs; // FCS_spare_10.webp");
// export const FCS_spare_11 = fcs; // FCS_spare_11.webp");
// export const FCS_spare_12 = fcs; // FCS_spare_12.webp");
// export const FCS_spare_13 = fcs; // FCS_spare_13.webp");
// export const FCS_spare_14 = fcs; // FCS_spare_14.webp");
// export const FCS_spare_15 = fcs; // FCS_spare_15.webp");
// export const FCS_spare_16 = fcs; // FCS_spare_16.webp");
// export const FCS_spare_17 = fcs; // FCS_spare_17.webp");
// export const FCS_spare_18 = fcs; // FCS_spare_18.webp");
// export const FCS_spare_19 = fcs; // FCS_spare_19.webp");
// export const FCS_spare_20 = fcs; // FCS_spare_20.webp");
// export const FCS_spare_21 = fcs; // FCS_spare_21.webp");
// export const FCS_spare_22 = fcs; // FCS_spare_22.webp");
// export const FCS_spare_23 = fcs; // FCS_spare_23.webp");
// export const FCS_spare_24 = fcs; // FCS_spare_24.webp");
// export const FCS_spare_25 = fcs; // FCS_spare_25.webp");
// export const FCS_spare_26 = fcs; // FCS_spare_26.webp");
// export const FCS_spare_27 = fcs; // FCS_spare_27.webp");
// export const FCS_spare_28 = fcs; // FCS_spare_28.webp");
export const robert_morris = fcs; // robert_morris.webp");
// export const FCS_spare_30 = fcs; // FCS_spare_30.webp");
export const Last = fcs; 
// export const networkNames=["ABC","CBS","FOX","NBC","ESPN","ESPN2","ESPNU","FS1","FS2","SECN","ACCN","PACN","CBSSN","BTN","LHN","ESPN+","ESPN3","Stadium","None"]

export const networkImagePaths=[abc, cbs, fox, nbc, espn, espn2, espnu, fs1, fs2, secn, accn,  pacn,  cbssn,  btn,  lhn, espnplus,espnplus,espnplus,stadium,none]; 
// const networkNames=[         "ABC","CBS","FOX","NBC","ESPN","ESPN2","ESPNU","FS1","FS2","SECN","ACC NETWOR","PACN","CBSSN","BIG10","LHN","ESPN+","ESPN3","Peacock","Stadium","None"]


export const ConfImagePaths = [0,sec,b1g,acc,pac,xii,aac,cusa,mwc,sun,mac,indy,fcs];

export const TeamImagePaths = [
    fcs,
    fcs,
    airforce,
    akron,
    alabama,
    fcs,
    fcs,
    fcs,
    fcs,
    appstate,
    arizona,
    arizonast,
    arkansas,
    fcs,
    arkstate,
    army,
    auburn,
    fcs,
    ballst,
    baylor,
    fcs,
    boise,
    bc,
    bowling,
    buffalo,
    byu,
    cal,
    fcs,
    fcs,
    fcs,
    cmich,
    fcs,
    charlotte,
    fcs,
    cinci,
    clemson,
    coastalcar,
    fcs,
    colorado,
    colost,
    fcs,
    duke,
    fcs,
    ecu,
    fcs,
    fcs,
    fcs,
    emich,
    fcs,
    fcs,
    fcs,
    fau,
    fiu,
    florida,
    floridast,
    fcs,
    fresno,
    fcs,
    georgia,
    gasouthern,
    gastate,
    georgiatech,
    fcs,
    hawaii,
    fcs,
    houston,
    fcs,
    fcs,
    fcs,
    fcs,
    illinois,
    fcs,
    fcs,
    indiana,
    fcs,
    iowa,
    iowast,
    jaxst,
    jmu,
    kansas,
    kansasst,
    kennst,
    kentst,
    kentucky,
    fcs,
    fcs,
    fcs,
    liberty,
    ulmonroe,
    latech,
    louisville,
    lsu,
    fcs,
    marshall,
    maryland,
    umass,
    fcs,
    memphis,
    fcs,
    miami,
    miamioh,
    michigan,
    michiganst,
    mtsu,
    minnesota,
    missst,
    missouri,
    fcs,
    fcs,
    fcs,
    fcs,
    navy,
    nebraska,
    nevada,
    fcs,
    newmexico,
    newmexst,
    fcs,
    unc,
    fcs,
    fcs,
    ncstate,
    fcs,
    fcs,
    fcs,
    noillinois,
    fcs,
    northwestern,
    fcs,
    notredame,
    ohio,
    ohiost,
    oklahoma,
    oklahomast,
    odu,
    olemiss,
    oregon,
    oregonst,
    pennst,
    pitt,
    fcs,
    fcs,
    purdue,
    fcs,
    rice,
    fcs,
    rutgers,
    fcs,
    fcs,
    sdsu,
    sanjose,
    fcs,
    smu,
    soalab,
    scarolina,
    fcs,
    fcs,
    fcs,
    usf,
    fcs,
    fcs,
    fcs,
    usc,
    fcs,
    somiss,
    fcs,
    stanford,
    fcs,
    fcs,
    syracuse,
    tcu,
    temple,
    tennessee,
    fcs,
    fcs,
    texas,
    texasam,
    fcs,
    texstate,
    texastech,
    fcs,
    toledo,
    fcs,
    troy,
    tulane,
    tulsa,
    uab,
    fcs,
    ucf,
    ucla,
    uconn,
    louisiana,
    unlv,
    fcs,
    utah,
    utahst,
    utep,
    utsa,
    vandy,
    fcs,
    virginia,
    vatech,
    fcs,
    fcs,
    wakeforest,
    washington,
    washst,
    fcs,
    westva,
    fcs,
    fcs,
    wku,
    wmich,
    fcs,
    wisconsin,
    wyoming,
    fcs,
    samhoust,
    fcs,
    fcs,
    fcs,
    fcs,
    fcs,
    fcs,
    fcs,
    fcs,
    fcs,
    fcs,
    fcs,
    bye,
    fcs,
    fcs,
    fcs,
    fcs, // FCS_spare_9,
    jaxst,
    fcs, // FCS_spare_11,
    fcs, // FCS_spare_12,
    fcs, // FCS_spare_13,
    fcs, // FCS_spare_14,
    fcs, // FCS_spare_15,
    fcs, // FCS_spare_16,
    fcs, // FCS_spare_17,
    fcs, // FCS_spare_18,
    fcs, // FCS_spare_19,
    fcs, // FCS_spare_20,
    fcs, // FCS_spare_21,
    fcs, // FCS_spare_22,
    fcs, // FCS_spare_23,
    fcs, // FCS_spare_24,
    fcs, // FCS_spare_25,
    fcs, // FCS_spare_26,
    fcs, // FCS_spare_27,
    fcs, // FCS_spare_28,
    fcs,
    fcs, // FCS_spare_30,
    Last]; 

    export const ConfNames = ["xxx","SEC","B1G","ACC","yyy","XII","AAC","CUSA","MWC","SUN","MAC","Independents"]
    export const ConfMembers=[[0],[],[],[],[],[],[],[],[],[],[],[],[]];

    export function LoadConfMember (confid, tid)
    {        
       ConfMembers[confid].push(TeamImagePaths[tid]);
    }

