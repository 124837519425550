import { useNavigate } from "react-router-dom"
import { SetGamesRetrieved } from "../data";
import * as Images from '../images';

export default function ConfBar({placement, layout, setGID})
{
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Go back in history
    }    

    const pickConf = (val) => {
        console.log ("pickConf" + val)
        navigate("/"+val)
        setGID(0)
    }
    let dummy = {layout}+" blackbk";

    return (
        <>
      <div className={placement}>
        <div className={dummy}> </div>
        <div className={layout}> <img className='btn-xsq' onClick={() =>  pickConf("SEC")} src={Images.sec}></img> </div>
        <div className={layout}> <img className='btn-xsq' onClick={() =>  pickConf("B1G")} src={Images.b1g}></img> </div>
        <div className={layout}> <img className='btn-xsq' onClick={() =>  pickConf("XII")} src={Images.xii}></img> </div>
        <div className={layout}> <img className='btn-xsq' onClick={() =>  pickConf("ACC")} src={Images.acc}></img> </div>
        <div className={layout}> <img className='btn-xsq' onClick={() => pickConf("Independents")} src={Images.indy}></img>  </div>
        <div className={layout}><img className='btn-xsq' onClick={() =>  pickConf("AAC")} src={Images.aac}></img> </div>
        <div className={layout}><img className='btn-xsq' onClick={() =>  pickConf("MWC")} src={Images.mwc}></img> </div>
        <div className={layout}><img className='btn-xsq' onClick={() =>  pickConf("SUN")} src={Images.sun}></img> </div>
        <div className={layout}><img className='btn-xsq' onClick={() =>  pickConf("CUSA")} src={Images.cusa}></img> </div>
        <div className={layout}><img className='btn-xsq' onClick={() => pickConf("MAC")} src={Images.mac}></img></div>
        <div className={dummy} ></div>
        <div className={dummy} ></div>
        <div className={dummy} ></div>
        <div className={dummy} ></div>
        <div className={dummy} > </div>
        <div className={dummy} > </div>
        <div className={dummy} > </div>
      </div>
    </>
    )   
}